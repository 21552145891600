import React, { useState } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, notification, Upload } from "antd";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import UserApiService from "services/UserService";
import useStore from "hooks/useStore";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";



const AvatarUpload: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { auth } = useStore();
  const { t } = useTranslation();


  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      notification.error({
        message: t("NotificationErrors.ImageFormat.Title"),
        description: t("NotificationErrors.ImageFormat.Description"),
        placement: "bottomRight",
      });
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      notification.error({
        message: t("CommonProfile.MyProfile.NotificationLessThan10MbTitle"),
        description: t(
          "CommonProfile.MyProfile.NotificationLessThan10MbDescription"
        ),
        placement: "bottomRight",
      });
    }
    return (isJpgOrPng && isLt10M) || Upload.LIST_IGNORE;
  };

  const handleClickDelete = async () => {
    try {
      await UserApiService.deleteUserAvatar();
      auth.currentUser!.setUrlAvatar(null);
    } catch (error) {
      notification.error({
        message: t("NotificationErrors.Error.Title"),
        description: t("NotificationErrors.Error.Description"),
        placement: "bottomRight",
      });
    }
  };

  const customRequest = async (options: UploadProps<UploadFile>) => {
    const { file } = options;
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      const response = await UserApiService.postUpdateUserAvatar(formData);
      auth.currentUser!.setUrlAvatar(response.data.url);
      setLoading(false);
    } catch (error) {
      notification.error({
        message: t("NotificationErrors.Error.Title"),
        description: t("NotificationErrors.Error.Description"),
        placement: "bottomRight",
      });
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>{t("Texts.Upload")}</div>
    </div>
  );

  return (
    <>
      <div style={{ display: "flex" }}>
        <div>
          <Upload
            name="avatar"
            listType="picture-circle"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            customRequest={customRequest}
          >
            {auth.currentUser?.urlAvatar ? (
              <img
                src={auth.currentUser!.urlAvatar}
                alt=""
                style={{ width: "100%", borderRadius: 50 }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingLeft: "12px",
            gap: "2px",
          }}
        >
          <div>
            {auth.currentUser?.firstName} {auth.currentUser?.lastName}
          </div>
          <Link to="#" onClick={handleClickDelete} style={{ fontSize: "12px" }}>
            {t("CommonProfile.MyProfile.DeleteAvatar")}
          </Link>
        </div>
      </div>
    </>
  );
};

export default observer(AvatarUpload);
