import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Button, Row, Form, Select, Divider } from "antd";
import firstImage from "assets/images/homePage/space1.jpg";
import styled from "styled-components";
import iconSearch from "assets/images/iconSearch.svg";
import CustomButton from "components/buttons/CustomButton";
import BestSpacesSection from "./components/BestSpacesSection";
import ItsSimpleSection from "./components/ItsSimpleSection";
import GetMoreSection from "./components/GetMoreSection";
import useStore from "hooks/useStore";
import { observer } from "mobx-react-lite";
import { sortedArraySpaceType } from "constants/SpaceType";
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import { useNavigate } from "react-router-dom";
import CustomFooter from "components/footer/CustomFooter";
import { Helmet } from "react-helmet";

/** Home page.
 * It contain the sections:
 * - "Search"
 * - "Best spaces"
 * - "it's simple"
 * - "Get more"
 * Responsive functionnalities:
 * Important breakpoint at 992px to change the style of search components in "Search" section.
 */
function HomePage() {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const { infoStore } = useStore();
  const navigate = useNavigate();
  const [selectedSpaceType, setSelectedSpaceType] = useState(null);
  const [selectedRegionCity, setSelectedRegionCity] = useState(null);
  const [isMoreThan992px, setIsMoreThan992px] = useState(
    window.innerWidth >= 992
  );

  useEffect(() => {
    const handleResize = () => setIsMoreThan992px(window.innerWidth > 992);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    infoStore.getRegionCityGeo(i18n.language);
  }, [i18n.language, infoStore]);

  const handleClickButtonSearch = () => {
    let redirectUrl = RouterPath.SPACES;

    if (selectedSpaceType || selectedRegionCity) {
      redirectUrl = redirectUrl + "?";
    }
    if (selectedSpaceType) {
      redirectUrl = redirectUrl + "&spaceType=" + selectedSpaceType;
    }
    if (selectedRegionCity) {
      const regionCityGeo = infoStore.regionCityGeo.get(selectedRegionCity);
      redirectUrl =
        redirectUrl +
        "&lat=" +
        regionCityGeo?.geo_lat +
        "&lng=" +
        regionCityGeo?.geo_long;
    }
    navigate(routerPathLocal(i18n.language, redirectUrl));
  };

  const SearchComponent = (
    <Form form={form}>
      <Input.Group compact={isMoreThan992px} style={{ width: "100%" }}>
        <StyledFirstBigSelector
          placeholder={t("MainPage.SpaceTypePlaceholder")}
          options={sortedArraySpaceType(t).map((spaceType) => ({
            value: spaceType.key,
            label: t("SpaceTypes." + spaceType.value),
          }))}
          onChange={(value: string) => setSelectedSpaceType(value)}
          bordered={isMoreThan992px}
        />
        <Divider
          style={{
            display: isMoreThan992px ? "none" : "",
            margin: 0,
            borderColor: "B4B4B4",
          }}
        />
        <StyledSecondBigSelector
          showSearch
          placeholder={t("MainPage.CityRegionPlaceholder")}
          onChange={(value: string) => setSelectedRegionCity(value)}
          bordered={isMoreThan992px}
          filterOption={(input, option) =>
            option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {infoStore.getRegionCityGeoAsArray().map((item) => (
            <Select.Option key={item.uuid} value={item.uuid}>
              {item.name}
            </Select.Option>
          ))}
        </StyledSecondBigSelector>
        <StyledButtonSearch
          type="succes"
          style={{
            borderBottomRightRadius: "40px",
            borderTopRightRadius: "40px",
          }}
          onClick={handleClickButtonSearch}
        >
          <img
            src={iconSearch}
            alt=""
            style={{ marginBottom: "-3px", marginRight: "10px" }}
          />
          {t("MainPage.SearchButton")}
        </StyledButtonSearch>
      </Input.Group>
    </Form>
  );

  return (
    <>
      <Helmet
        title={t("MainPage.PageHeadTitle")}
        htmlAttributes={{ lang: i18n.language }}
        meta={[
          {
            name: `description`,
            content: t("MainPage.PageHeadDescription"),
          },
        ]}
      />
      <div style={{ position: "relative", overflow: "hidden" }}>
        <HomePageContainer>
          <StyledEllipse />
          <GradientOverlay />
          <TextContainer>{t("MainPage.FirstText")}</TextContainer>
          <SearchContainer className="desktop">
            {SearchComponent}
          </SearchContainer>
        </HomePageContainer>
        <div
          style={{
            display: isMoreThan992px ? "none" : "",
            background: "#fff",
            position: "relative",
            borderRadius: "10px",
            boxShadow: "0px 9px 14px rgba(0, 0, 0, 0.06)",
            padding: "0px 20px 15px",
            marginBottom: "50px",
          }}
        >
          <SearchContainer className="mobile">
            {SearchComponent}
          </SearchContainer>
        </div>
        <div style={{ marginBottom: "80px" }}>
          <BestSpacesSection />
        </div>
        <div style={{ marginBottom: "100px" }}>
          <ItsSimpleSection />
        </div>
        <div style={{ marginBottom: "100px" }}>
          <GetMoreSection />
        </div>
        <div style={{ marginBottom: "42px" }}>
          <CustomFooter />
        </div>
      </div>
    </>
  );
}

export default observer(HomePage);

const StyledBigSelect = styled(Select)`
  width: calc(50% - 200px);
  height: 80px;

  .ant-select-selector {
    height: 80px !important;
    display: flex;
    align-items: center;
    font-size: 20px;
  }

  .ant-select-selector input {
    font-size: 20px;
  }

  .ant-select-selection-search {
    display: flex;
    align-items: center;
  }

  @media (max-width: 992px) {
    width: 100%;
    margin-bottom: 0px;
    height: 60px;

    .ant-select-selector {
      height: 60px !important;
    }
  }
`;

const StyledFirstBigSelector = styled(StyledBigSelect)`
  .ant-select-selector {
    border-start-start-radius: 40px !important;
    border-end-start-radius: 40px !important;
  }
  @media (max-width: 992px) {
    .ant-select-selector {
      border-radius: 10px !important;
      border-end-start-radius: 0px !important;
      border-end-end-radius: 0px !important;
    }
  }
`;

const StyledSecondBigSelector = styled(StyledBigSelect)`
  @media (max-width: 992px) {
    margin-bottom: 15px;

    .ant-select-selector {
      border-radius: 10px !important;
      border-start-end-radius: 0px !important;
      border-start-start-radius: 0px !important;
    }
  }
`;

const StyledButtonSearch = styled(CustomButton)`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
  text-align: center;
  letter-spacing: 0.015em;
  text-transform: uppercase;

  color: #ffffff;
  background-color: #1dcf64;
  height: 80px;
  padding-right: 40px;
  padding-left: 40px;

  @media (max-width: 992px) {
    width: 100%;
    border-bottom-right-radius: 40px;
    border-top-right-radius: 40px;
    border-bottom-left-radius: 40px;
    border-top-left-radius: 40px;
    // border-radius: 40px;
    // margin-bottom: 80px;
  }
`;

const HomePageContainer = styled.div`
  background-image: url(${firstImage});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 460px;
  position: relative;
  border-radius: 10px;
  margin-bottom: 120px;
  // background: linear-gradient(180deg, rgba(0, 0, 0, 0) 49.3%, rgba(0, 0, 0, 0.7) 100%);
  @media (max-width: 992px) {
    margin-bottom: 0px;
  }
`;

const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 49.3%,
    rgba(0, 0, 0, 0.7) 100%
  );
`;

const TextContainer = styled.div`
  position: absolute;
  text-align: center;
  left: 0px;
  bottom: 65px;
  width: 100%;
  font-family: "Ranade-Variable";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 55px;
  /* identical to box height */
  text-align: center;
  letter-spacing: -0.04em;
  color: #ffffff;
`;

// const SearchContainer = styled.div`
//   position: absolute;
//   width: 100%;
//   text-align: center;
//   left: 0px;
//   bottom: -40px;

//   @media (max-width: 992px) {
//     // bottom: -80px; /* Increase the distance from the bottom for smaller window widths */
//   }
// `;

const SearchContainer = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0px;
  bottom: -40px;

  @media (max-width: 992px) {
    position: static; // Reset position property for smaller window widths
    padding-top: 20px; // Add some padding on top
  }

  &.desktop {
    @media (max-width: 992px) {
      display: none;
    }
  }

  &.mobile {
    display: none;
    @media (max-width: 992px) {
      display: block;
      margin-top: -50px;
    }
  }
`;

const StyledEllipse = styled.div`
  position: absolute;
  width: 349.91px;
  height: 349.91px;
  left: 100px; // Adjusted according to your requirement
  top: 300px; // Adjusted according to your requirement
  background: #007aae;
  opacity: 0.2;
  filter: blur(100px);
  border-radius: 50%; // To make the div elliptical
`;
