import {  types } from 'mobx-state-tree'


export const Comment = types
  .model({
    id: types.number,
    rating: types.number,
    message: types.string,
    first_name_user: types.string,
    last_name_user: types.string,
    url_avatar: types.string,
  })