import { Button, Col, Result, Row } from "antd";
import CustomButton from "components/buttons/CustomButton";
import CustomResult from "components/customResult/CustomResult";
import { CustomH1 } from "components/typography/CustomH";
import { CustomP } from "components/typography/CustomP";
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import managerIcon from "assets/images/signUp/managerIcon.svg";
import userIcon from "assets/images/signUp/userIcon.svg";
import { enumUserRole, enumUserRoleForQueryParams } from "constants/User";
import FormSignup from "./components/FormSignup";

/** Page to create account of user or manager.
 */
export default function SignupPage(): JSX.Element {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [renderedComponent, setRenderedComponent] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const userParams = queryParams.get("user");
    if (userParams === enumUserRoleForQueryParams.CLIENT) {
      setRenderedComponent(enumUserRoleForQueryParams.CLIENT);
    } else if (userParams === enumUserRoleForQueryParams.MANAGER) {
      setRenderedComponent(enumUserRoleForQueryParams.MANAGER);
    } else {
      setRenderedComponent("chooseRoleComponent");
    }
  }, [location.search]);

  const hadleButtonClickCreateUser = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("user", enumUserRoleForQueryParams.CLIENT);
    navigate(`${location.pathname}?${queryParams}`);
  };

  const hadleButtonClickCreateManager = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("user", enumUserRoleForQueryParams.MANAGER);
    navigate(`${location.pathname}?${queryParams}`);
  };

  if (renderedComponent === undefined) {
    return <></>;
  } else if (renderedComponent === enumUserRoleForQueryParams.CLIENT) {
    return (
      <>
        <FormSignup userRole={enumUserRole.CLIENT} />
      </>
    );
  } else if (renderedComponent === enumUserRoleForQueryParams.MANAGER) {
    return <FormSignup userRole={enumUserRole.MANAGER} />;
  }
  return (
    <Col
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        marginBottom: "40px",
      }}
    >
      <Row>
        <CustomH1 style={{ fontSize: "40px" }}>
          {t("SignupPage.Title")}
        </CustomH1>
      </Row>
      <Row
        style={{
          width: "100%",
          justifyContent: "center",
          gap: "30px",
        }}
      >
        <StyledCard xs={24} sm={11} md={10} lg={8} xl={6}>
          <img
            src={userIcon}
            alt="User icon"
            // style={{ height: "38px", width: "auto", fill: "#000" }}
          />
          <StyledTitle>{t("SignupPage.UserSecondTitle")}</StyledTitle>
          <StyledCustomP>{t("SignupPage.UserCardDescription")}</StyledCustomP>
          <StyledDivForButton>
            <CustomButton
              size="large"
              type="succes"
              style={{ width: "100%" }}
              onClick={hadleButtonClickCreateUser}
            >
              {t("SignupPage.UserCardButton")}
            </CustomButton>
          </StyledDivForButton>
        </StyledCard>
        <StyledCard xs={24} sm={11} md={10} lg={8} xl={6}>
          <img
            src={managerIcon}
            alt="Manager icon"
            // style={{ height: "38px", width: "auto", fill: "#000" }}
          />
          <StyledTitle>{t("SignupPage.ManagerSecondTitle")}</StyledTitle>
          <StyledCustomP>
            {t("SignupPage.ManagerCardDescription")}
          </StyledCustomP>
          <StyledDivForButton>
            <CustomButton
              size="large"
              type="succes"
              style={{ width: "100%" }}
              onClick={hadleButtonClickCreateManager}
            >
              {t("SignupPage.ManagerCardButton")}
            </CustomButton>
          </StyledDivForButton>
        </StyledCard>
      </Row>
    </Col>
  );
}

const StyledCard = styled(Col)`
  display: flex;
  padding: 40px 30px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.12);
`;

const StyledTitle = styled.h2`
  color: #000;
  font-size: 24px;
  font-family: Ranade;
  font-style: normal;
  font-weight: 500;
  line-height: 113%;
  letter-spacing: -0.96px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const StyledCustomP = styled(CustomP)`
  margin: 0;
`;

const StyledDivForButton = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
