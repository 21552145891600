
import CustomButton from "components/buttons/CustomButton";
import CustomResult from "components/customResult/CustomResult";
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

/** Page "Link not valid" */
export default function LinkNotValid(): JSX.Element {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleGoToHome = () => {
    navigate(routerPathLocal(i18n.language, RouterPath.HOME));
  };
  return (
    <CustomResult
      status="error"
      title={t("LinkNotFound.Title")}
      subTitle={t("LinkNotFound.Text")}
      extra={[
        <CustomButton
          size="large"
          type="succes"
          key="back"
          onClick={handleGoToHome}
        >
          {t("LinkNotFound.Button")}
        </CustomButton>,
      ]}
    />
  );
}
