import { applySnapshot, flow, Instance, types } from 'mobx-state-tree'
import { User, IUser } from 'states/models/User/UserModel'
import SpaceService, { ISpacePageInfo } from "services/SpaceService";
import { requestStatus } from "constants/reqestStatus"
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import { ISpaceModel, SpaceModel } from 'states/models/Space/SpaceModel';
import { OpeningHours } from 'states/models/Space/OpeningHours';
import { Comment } from 'states/models/Space/Comment';
import { ImageFile } from 'states/models/Space/ImageFile';

export const SpaceStore = types
  .model({
    currentSpace: types.maybeNull(SpaceModel),
    comments: types.array(Comment),   
    getRequestStatus: types.optional(
      types.enumeration<requestStatus>(Object.values(requestStatus)), requestStatus.INITIAL)

  })
  .actions((self) => {
    return {
      setGetRequestStatus: (status: requestStatus) => {
        self.getRequestStatus = status;
      },
      setSpace: (spaceModel: ISpaceModel | null) => {
        self.currentSpace = spaceModel;
      },
      clearCurrentSpace: () => {
        self.currentSpace = null;
      },
    }
  })
  .actions((self) => {
    return {
      getAndSetCurrentSpace: flow(
        function* (spacePseudo: string, lang: string) {

        const response = yield SpaceService.getSpacePageInfo({space_pseudo: spacePseudo, lang: lang});
        const responseData: ISpacePageInfo = response.data;
        const space = SpaceModel.create({
          id_country: responseData.id_country,
          id_region: responseData.id_region,
          zip_code: responseData.zip_code,
          name: responseData.name,
          pseudo: responseData.pseudo,
          type: responseData.type,
          is_favorite: responseData.is_favorite,
          state: responseData.state,
          metro: responseData.metro,
          address: responseData.address,
          additional_info_address: responseData.additional_info_address,
          seat_number: responseData.seat_number,
          standing_number: responseData.standing_number,
          floor_area: responseData.floor_area,
          // height: responseData.height,
          city: responseData.city,
          description: responseData.description,
          terms: responseData.terms,
          images: responseData.images.map(image => {
            return ImageFile.create({
              url: image.url,
              id_image_file: image.id_image_file,
            })
          }),
          opening_hours: responseData.opening_hours.map(opening_hour => {
            return OpeningHours.create({
              day: opening_hour.day,
              time_from: opening_hour.time_from,
              time_to: opening_hour.time_to,
              min_price: opening_hour.min_price,
            })
          }),
          ids_facilities: responseData.ids_facilities,
          ids_events: responseData.ids_events,
          min_booking_time: responseData.min_booking_time,
          average_rating: responseData.average_rating,
          comment_number: responseData.comment_number,
          id_user_manager: responseData.id_user_manager,
          first_name_manager: responseData.first_name_manager,
          last_name_manager: responseData.last_name_manager,
          manager_avatar_url: responseData.manager_avatar_url,
          geo_lat: responseData.geo_lat,
          geo_long: responseData.geo_long,
        });
        self.setSpace(space);

      })
    
    }
  })
  .views((self) => ({
    getCommentsAsArray: () => self.comments.slice()
  }))
  .actions((self) => ({
    fetchComments: flow(function* fetchComments(space_pseudo: string) {
      applySnapshot(self.comments, [])
      try {
        const data = yield SpaceService.getSpaceComments({ space_pseudo: space_pseudo });
        self.comments = data.data
      } catch
      {
        // self.status = requestStatus.ERROR;
      }
    })
  }))




export type ISpaceStore = Instance<typeof SpaceStore>