const LANG_PREFIX = "/:lang";

export const RouterPath = {
  HOME_WITHOUT_PREFIX: "/",
  HOME: LANG_PREFIX + "/",
  SPACES: LANG_PREFIX + "/spaces",
  SPACE: LANG_PREFIX + "/spaces/",
  MESSENGER: LANG_PREFIX + "/messages",
  FAVORITES: LANG_PREFIX + "/favorites",
  FAQ: LANG_PREFIX + "/faq",
  LINK_NOT_VALID: LANG_PREFIX + "/link-not-valid",
  LINK_NOT_AUTHENTICATED: LANG_PREFIX + "/not-authenticated",
  // Info routes
  PARTNERSHIP: LANG_PREFIX + "/list-your-space",
  PRIVACY_POLICY: LANG_PREFIX + "/privacy-policy",
  TERMS_AND_CONDITIONS: LANG_PREFIX + "/terms-and-conditions",
  // Autentification routes
  SIGNUP: LANG_PREFIX + "/sign-up",
  SIGNUP_SUCCESS: LANG_PREFIX + "/signup-success",
  FORGOT_PASSWORD: LANG_PREFIX + "/forgot-password",
  FORGOT_PASSWORD_MAIL_SENT: LANG_PREFIX + "/password-forgot/mail-sent",
  RESET_PASSWORD: LANG_PREFIX + "/reset-password",
  PASSWORD_CHANGED: LANG_PREFIX + "/reset-password/password-changed",
  PASSWORD_NOT_CHANGED: LANG_PREFIX + "/reset-password/password-not-changed",
  SIGNUP_CONFIRM_EMAIL: LANG_PREFIX + "/confirm-email",

  // ADMINISTRATOR ROUTES
  ADMIN_MY_PROFILE: LANG_PREFIX + "/admin/my-profile",
  ADMIN_SPACES_ALL: LANG_PREFIX + "/admin/spaces/all",
  ADMIN_SPACES_PUBLISHED: LANG_PREFIX + "/admin/spaces/published",
  ADMIN_SPACES_NOT_PUBLISHED: LANG_PREFIX + "/admin/spaces/not-published",
  ADMIN_SPACES_WAITING_FOR_VALIDATION: LANG_PREFIX + "/admin/spaces/waiting-for-moderation",
  ADMIN_MODIFY_SPACE: LANG_PREFIX + "/admin/spaces/modify-space",
  ADMIN_BEST_SPACES: LANG_PREFIX + "/admin/best-spaces",
  ADMIN_USERS_LIST: LANG_PREFIX + "/admin/users-list",
  ADMIN_USER_PAGE: LANG_PREFIX + "/admin/users",

  // CUSTOMER ROUTES
  CUST_MY_PROFILE: LANG_PREFIX + "/user-profile/my-profile",
  CUST_ACCOUNT_SETTINGS: LANG_PREFIX + "/user-profile/account-settings",
  CUST_NOTIFICATIONS: LANG_PREFIX + "/user-profile/notifications",

  // OWNER ROUTES
  MANAGER_MY_SPACES: LANG_PREFIX + "/manager-profile/spaces",
  MANAGER_MY_PROFILE: LANG_PREFIX + "/manager-profile/my-profile",
  MANAGER_ACCOUNT_SETTINGS: LANG_PREFIX + "/manager-profile/account-settings",
  MANAGER_NOTIFICATIONS: LANG_PREFIX + "/manager-profile/notifications",
  MANAGER_ADD_SPACE: LANG_PREFIX + "/manager-profile/add-space",
  MANAGER_MODIFY_SPACE: LANG_PREFIX + "/manager-profile/modify-space",
  MANAGER_MY_SPACES_ACTIVE: LANG_PREFIX + "/manager-profile/spaces/active",
  MANAGER_MY_SPACES_INACTIVE: LANG_PREFIX + "/manager-profile/spaces/inactive",
  MANAGER_MY_SPACES_WAITING_FOR_VALIDATION: LANG_PREFIX + "/manager-profile/spaces/waiting-for-validation",
};
Object.freeze(RouterPath);

export function routerPathLocal(lang: string, route: string): string {
  return route.replace(LANG_PREFIX, `/${lang}`)
}
