import { ApiEndpointsV1 } from "assets/endpoints/v1";
import { axiosInstance } from "./axios/Axios";
import { AxiosResponse } from "axios";



export interface IGetSpacesParams {
  lang?: string;
  name?: string;
  id_event?: number;
  city_or_region?: string;
  date?: string;
  is_only_favorite?: boolean;
  is_only_my_spaces?: boolean;
  is_with_image?: boolean;
  page?: number;
  size?: number;
  northeast_lat?: number;
  northeast_long?: number;
  southwest_lat?: number;
  southwest_long?: number;
  space_type?: string;
  seat_number?: number;
  standing_number?: number;
}

export interface IGetSpacesItemResponse {
  id: number;
  name: string;
  metro: string;
  zip_code: string;
  city: string;
  min_price: number;
  geo_lat: number;
  geo_long: number;
  url_cover_image: string;
  pseudo: string;
  is_favorite: boolean;
  lang?: string;
}

export interface IGetSpacesResponse{
  items: IGetSpacesItemResponse[];
  total: number;
  page: number;
  size: number;
}


/**
 * API to get list of Spaces.
 * @param {Object} queryParams - The query parameters for the API request.
 */
const getListSpaces = (queryParams?: IGetSpacesParams): Promise<AxiosResponse<IGetSpacesResponse>> => {
  return axiosInstance
    .get(ApiEndpointsV1.GET_SPACES, {
      params: queryParams,
    })
    .then((response) => {
      return response;
    });
};


export interface IGetMySpacesParams {
  name?: string;
  id_event?: number;
  state?: string;
  is_visible?: boolean;
  is_only_invisible?: boolean;
}


/**
 * API to get list of my Spaces.
 * @param {Object} queryParams - The query parameters for the API request.
 */
const getListMySpaces = (queryParams?: IGetMySpacesParams): Promise<AxiosResponse<IGetSpacesResponse>> => {
  return axiosInstance
    .get(ApiEndpointsV1.GET_MY_SPACES, {
      params: queryParams,
    })
    .then((response) => {
      return response;
    });
};



export interface IGetSpacesListAsAdminParams {
  name?: string;
  state?: string;
  page?: number;
  size?: number;
  lang?: string;
}


/**
 * API to get list of Spaces as Admin. Used in Admin Profile.
 * @param {Object} queryParams - The query parameters for the API request.
 */
const getListSpacesAsAdmin = (queryParams?: IGetSpacesListAsAdminParams): Promise<AxiosResponse<IGetSpacesResponse>> => {
  return axiosInstance
    .get(ApiEndpointsV1.GET_SPACES_ADMIN, {
      params: queryParams,
    })
    .then((response) => {
      return response;
    });
};


export interface ISpaceCreate {
  name: string,
  type: string,
  min_booking_time: number,
  city: string,
  id_country: number,
  id_region: number,
  zip_code: string,
  metro: string,
  address: string,
  additional_info_address: string,
  seat_number: number,
  standing_number: number,
  floor_area: number,
  min_spend: number,
  description_translation: {
    en: string,
    fr: string 
  },
  terms_translation: {
    en: string,
    fr: string
  },
  opening_hours: [
    {
      day: string,
      time_from: string,
      time_to: string,
      min_price: number
    }
  ],
  ids_images: number[],
}


/**
 * API to create a space.
 */
function postCreateSpace(data: ISpaceCreate): Promise<AxiosResponse> {
  return axiosInstance
    .post(ApiEndpointsV1.CREATE_SPACE, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });;
};


interface ISpaceModify extends ISpaceCreate{};

/**
 * API to modify a space by User Manager or Admin.
 */
function putModifySpace(data: ISpaceModify, space_pseudo: string): Promise<AxiosResponse> {
  return axiosInstance
    .put(ApiEndpointsV1.MODIFY_SPACE + space_pseudo, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });;
};


export interface ISpaceModifyAsAdmin {
  state: string;
  is_visible: boolean;
  geo_lat: number;
  geo_long: number;
}

/**
 * API to modify a space critical info by User Admin.
 */
function putModifySpaceAsAdmin(data: ISpaceModifyAsAdmin, space_pseudo: string): Promise<AxiosResponse> {
  return axiosInstance
    .put(ApiEndpointsV1.MODIFY_SPACE_AS_ADMIN + space_pseudo, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });;
};


export interface ISpaceModifyAsManager {
  state: string;
}

/**
 * API to modify a space state by User Manager.
 */
function putModifySpaceAsManager(data: ISpaceModifyAsManager, space_pseudo: string): Promise<AxiosResponse> {
  return axiosInstance
    .put(ApiEndpointsV1.MODIFY_SPACE_AS_MANAGER + space_pseudo, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });;
};


export interface IUploadImageAPIResponse{
  url: string;
  id_image_file: number;
}


/**
 * API to upload an image to s3 server.
 * Returns url of 500px image and id_image_file, that can be used in other requests.
 */
const postUploadImage = (formData: FormData): Promise<AxiosResponse<IUploadImageAPIResponse>> => {
    return axiosInstance.post(
        ApiEndpointsV1.UPLOAD_IMAGE_SPACE,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ).then((response) => {
      return response;
    });
};



interface ITranslation {
  fr: string;
  en: string;
}

export interface IOpeningHours {
  day: string;
  time_from: string;
  time_to: string;
  min_price: number;
}

export interface IImages {
  url: string;
  id_image_file: number;
}

export interface ISpaceInfoProfile {
  id_country: number;
  id_region: number;
  zip_code: string;
  name: string;
  type: string;
  min_booking_time: number;
  state: string;
  is_visible: boolean;
  metro: string;
  address: string;
  additional_info_address: string;
  seat_number: number;
  standing_number: number;
  floor_area: number;
  min_spend: number;
  city: string;
  description_translation: ITranslation;
  terms_translation: ITranslation;
  opening_hours: IOpeningHours[];
  images: IImages[];
  ids_facilities: number[];
  ids_events: number[];
  geo_lat: number;
  geo_long: number;
}


export interface IGetSpaceInfoParams {
  space_pseudo: string;
}


/**
 * API to get information of Space for profiles Manager and Admin.
 * @param {Object} queryParams - The query parameters for the API request.
 */
const getSpaceInfoProfile = (queryParams?: IGetSpaceInfoParams): Promise<AxiosResponse<ISpaceInfoProfile>> => {
  return axiosInstance
    .get(ApiEndpointsV1.GET_SPACE_INFO, {
      params: queryParams,
    })
    .then((response) => {
      return response;
    });
};


export interface ISpacePageInfo {
  id_country: number;
  id_region: number;
  zip_code: string;
  name: string;
  pseudo: string;
  type: string;
  is_favorite: boolean;
  state: string;
  metro: string;
  address: string;
  additional_info_address: string;
  seat_number: number;
  standing_number: number;
  floor_area: number;
  min_spend: number;
  city: string;
  description: string;
  terms: string;
  opening_hours: IOpeningHours[];
  images: IImages[];
  ids_facilities: number[];
  ids_events: number[];
  min_booking_time: number;
  average_rating: number;
  comment_number: number;
  id_user_manager: number;
  first_name_manager: string;
  last_name_manager: string;
  manager_avatar_url: string;
  geo_lat: number;
  geo_long: number;
}


export interface ISpacePageInfoParams {
  space_pseudo: string;
  lang: string;
}


/**
 * API to get information of Space for profiles Manager and Admin.
 * @param {Object} queryParams - The query parameters for the API request.
 */
const getSpacePageInfo = (queryParams?: ISpacePageInfoParams): Promise<AxiosResponse<ISpacePageInfo>> => {
  return axiosInstance
    .get(ApiEndpointsV1.GET_SPACE_PAGE_INFO, {
      params: queryParams,
    })
    .then((response) => {
      return response;
    });
};



export interface ISpaceMiniInfoResponseAPI {
  id: number;
  name: string;
  pseudo: string;
  type: string;
  is_favorite: boolean;
  min_price: number;
  address: string;
  seat_number: number;
  standing_number: number;
  floor_area: number;
  comment_number: number;
  average_rating: number;
  images: IImages[]
  }


export interface ISpaceMiniInfoParams {
  space_pseudo: string;
  lang: string;
}


/**
 * API to get mini information about space for card on map.
 * @param {Object} queryParams - The query parameters for the API request.
 */
const getSpaceMiniInfo = (queryParams: ISpaceMiniInfoParams): Promise<AxiosResponse<ISpaceMiniInfoResponseAPI>> => {
  return axiosInstance
    .get(ApiEndpointsV1.GET_SPACE_MINI_INFO, {
      params: queryParams,
    })
    .then((response) => {
      return response;
    });
};


export interface IAddComment {
  space_pseudo: string;
  message: string;
  rating: number;
}


/**
 * API to leave a comment on the space.
 */
const postAddCommentSpace = (data: IAddComment) => {
  return axiosInstance
    .post(ApiEndpointsV1.ADD_COMMENT, data)
    .then((response) => {
      return response;
    });
};



export interface ISpaceCommentsParams {
  space_pseudo: string;
}


export interface ISpaceCommentResponse {
  id: number;
  message: string;
  rating: number;
  first_name_user: string;
  last_name_user: string;
  url_avatar: string;
}


/**
 * API to get moderated comments for Space.
 * @param {Object} queryParams - The query parameters for the API request.
 */
const getSpaceComments = (queryParams?: ISpaceCommentsParams): Promise<AxiosResponse<ISpacePageInfo>> => {
  return axiosInstance
    .get(ApiEndpointsV1.GET_SPACE_COMMENTS, {
      params: queryParams,
    })
    .then((response) => {
      return response;
    });
};


export interface IAddFavoriteSpace {
  pseudo_space: string,
}


/**
 * API to add a space to favorites.
 */
function postAddFavoriteSpace(data: IAddFavoriteSpace): Promise<AxiosResponse> {
  return axiosInstance
    .post(ApiEndpointsV1.ADD_SPACE_TO_FAVORITE, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });;
};


export interface IDeleteFavoriteSpaceParams {
  pseudo_space: string,
}


/**
 * API to remove space from Favorites.
 * @param {Object} queryParams - The query parameters for the API request.
 */
function deleteDeleteFavoriteSpace(queryParams: IDeleteFavoriteSpaceParams): Promise<AxiosResponse> {
  return axiosInstance
    .delete(ApiEndpointsV1.DELETE_SPACE_FROM_FAVORITE, {
      params: queryParams,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });;
};


export interface IDeleteSpaceParams {
  space_pseudo: string,
}


/**
 * API to delete a space.
 * @param {Object} queryParams - The query parameters for the API request.
 */
function deleteDeleteSpace(queryParams: IDeleteSpaceParams): Promise<AxiosResponse> {
  return axiosInstance
    .delete(ApiEndpointsV1.DELETE_SPACE, {
      params: queryParams,
    })
    .then((response) => {
      return response;
    })
};



const DataService = {
  getListSpaces,
  getListMySpaces,
  getListSpacesAsAdmin,
  putModifySpaceAsManager,
  postCreateSpace,
  deleteDeleteSpace,
  postUploadImage,
  getSpaceInfoProfile,
  putModifySpace,
  putModifySpaceAsAdmin,
  getSpacePageInfo,
  postAddCommentSpace,
  getSpaceComments,
  postAddFavoriteSpace,
  deleteDeleteFavoriteSpace,
  getSpaceMiniInfo
};

export default DataService;