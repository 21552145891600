import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom'
import { RouterPath, routerPathLocal } from "constants/RouterPath";

export default function HomePageRedirect() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const language = i18n.language.includes("fr") ? "fr" : 'en';

  useEffect(() => {
    const changeLangAndNavigate = async () => {
        await i18n.changeLanguage(language);
        navigate(routerPathLocal(language, RouterPath.HOME));
    };
    changeLangAndNavigate();
  }, [navigate, language, i18n]);
  
  return (
    <>
    </>
  );
}
