import { Avatar, Badge, Col, Row } from "antd";
import useStore from "hooks/useStore";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IMessage } from "states/models/Messenger/Message";
import { formatDate, truncateString } from "tools/formatters";

interface MessageItemProps {
  message: IMessage;
}

function MessageItem({ message }: MessageItemProps): JSX.Element {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: message.is_my_message ? "row" : "row-reverse",
          // gap: "5px",
        }}
      >
        <Col
          span={9}
          style={{
            display: "flex",
            flexDirection: message.is_my_message ? "row-reverse" : "row",
            alignItems: "flex-end",
          }}
        >
          <span
            style={{
              paddingLeft: "5px",
              paddingRight: "5px",
              paddingBottom: "10px",
              color: "#505050",
              fontSize: "12px",
            }}
          >
            {formatDate(message.date_creation, i18n.language)}
          </span>
        </Col>
        <Col
          span={15}
          style={{
            backgroundColor: message.is_my_message ? "#EFEFEF" : "#007AAE",
            color: message.is_my_message ? "#000" : "#fff",
            borderRadius: 10,
            padding: "7px 12px 10px",
            whiteSpace: "pre-line",
          }}
        >
          {message.message}
        </Col>
      </div>
    </>
  );
}

export default observer(MessageItem);
