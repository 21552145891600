import { flow, Instance, types } from 'mobx-state-tree'
import { User, IUser } from 'states/models/User/UserModel'
import ApiService, { ILoginData, ILoginResponse } from "services/AuthService";
import UserService from "services/UserService";
import { requestStatus } from "constants/reqestStatus"
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import MessengerService, { INumberUnreadMessagesAPIResponse } from 'services/MessengerService';

export const AuthStore = types
  .model({
    currentUser: types.maybeNull(User),
    numberUnreadMessages: types.optional(types.number, 0),
    postLoginRequestStatus: types.optional(
      types.enumeration<requestStatus>(Object.values(requestStatus)), requestStatus.INITIAL),
    postUserInfoRequestStatus: types.optional(
      types.enumeration<requestStatus>(Object.values(requestStatus)), requestStatus.INITIAL)

  })
  .actions((self) => {
    return {
      setPostLoginRequestStatus: (status: requestStatus) => {
        self.postLoginRequestStatus = status;
      },
      setPostUserInfoRequestStatus: (status: requestStatus) => {
        self.postUserInfoRequestStatus = status;
      },
      setUser: (user: IUser | null) => {
        self.currentUser = user;
      },

    }
  })
  .actions((self) => ({
    setNumberUnreadMessages: (number: number
    ) => {
      self.numberUnreadMessages = number;
    }
  }))
  .actions((self) => ({
    fetchAndSetNumberUnreadMessages: flow(function* () {
      try {
        const response = yield MessengerService.getNumberOfUnreadMessages();
        const responseData: INumberUnreadMessagesAPIResponse = response.data;
        self.setNumberUnreadMessages(responseData.number_unread_messages)
      } catch (error) {
        self.setNumberUnreadMessages(0)
      }
    })
  }))
  .actions((self) => {
    return {
      loadUserInfo: flow(function* () {
        const token = localStorage.getItem("token");
        self.setPostUserInfoRequestStatus(requestStatus.PENDING)
        try {
          if (token && !self.currentUser) {
            const response = yield ApiService.getUserMyInfo();
            const responseData: ILoginResponse = response.data;
            const user = User.create({
              email: responseData.email,
              firstName: responseData.first_name,
              lastName: responseData.last_name,
              tel: responseData.tel,
              role: responseData.role,
              urlAvatar: responseData.url_avatar,
            });
            self.setUser(user);
            self.fetchAndSetNumberUnreadMessages()
          }
          self.setPostUserInfoRequestStatus(requestStatus.SUCCESS)
        }
        catch (error) {
          localStorage.removeItem("token");
          self.setPostUserInfoRequestStatus(requestStatus.ERROR)
        }
      }),
      login: flow(function* (data: ILoginData) {
        self.setPostLoginRequestStatus(requestStatus.PENDING);
        try {
          const response = yield ApiService.postLogin(data);
          const responseData: ILoginResponse = response.data;
          const user = User.create({
            email: responseData.email,
            firstName: responseData.first_name,
            lastName: responseData.last_name,
            tel: responseData.tel,
            role: responseData.role,
            urlAvatar: responseData.url_avatar
          });
          self.setUser(user);
          localStorage.setItem("token", responseData.access_token);
          self.setPostLoginRequestStatus(requestStatus.SUCCESS);
          self.fetchAndSetNumberUnreadMessages()
        } catch (error) {
          self.setPostLoginRequestStatus(requestStatus.ERROR);
        }
      }),
      logout: (lang: string) => {
        localStorage.removeItem("token");
        self.setUser(null);
        window.location.href = routerPathLocal(lang, RouterPath.HOME)
      }
    }
  })
  .actions((self) => {
    return {
      afterCreate: () => {
        self.loadUserInfo()
          .catch(error => {
            localStorage.removeItem("token");
          });
      }
    }
  })





export type IAuth = Instance<typeof AuthStore>