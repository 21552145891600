import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import ListSpaces from "components/listSpaces/ListSpaces";
import { useTranslation } from "react-i18next";
import ProfileLayout from "components/profileLayout/ProfileLayout";
import menuManager from "../commonComponents/menuManager";
import CustomTabs from "components/customTabs/CustomTabs";
import CustomButton from "components/buttons/CustomButton";
import SpaceApiService, { IGetSpacesParams } from "services/SpaceService";
import { spaceState } from "constants/spaceState";
import { useWindowSize, Size } from "hooks/useWindowSize";
import { windowBreakpoints } from "constants/windowBreakpoints";
import { PlusOutlined } from "@ant-design/icons";

function MySpacesListManager(): JSX.Element {
  const [activeTab, setActiveTab] = useState("");
  const [isScreenMoreThanMD, setIsScreenMoreThanMD] = useState<null | boolean>(
    null
  );
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const screenSize: Size = useWindowSize();

  const fetchActiveSpaces = () =>
    SpaceApiService.getListMySpaces({
      state: spaceState.PUBLISHED,
      is_visible: true,
    }).then((response) => response.data.items);
  const fetchInactiveSpaces = () =>
    SpaceApiService.getListMySpaces({
      is_only_invisible: true,
    }).then((response) => response.data.items);
  const fetchWaitingSpaces = () =>
    SpaceApiService.getListMySpaces({
      state: spaceState.WAITING_FOR_VALIDATION,
    }).then((response) => response.data.items);

  useEffect(() => {
    if (screenSize.width && screenSize.width > windowBreakpoints.MD) {
      setIsScreenMoreThanMD(true);
    } else if (screenSize.width) {
      setIsScreenMoreThanMD(false);
    }
  }, [screenSize.width]);

  useEffect(() => {
    const currentPath = location.pathname;
    const tabs = [
      RouterPath.MANAGER_MY_SPACES_ACTIVE,
      RouterPath.MANAGER_MY_SPACES_INACTIVE,
      RouterPath.MANAGER_MY_SPACES_WAITING_FOR_VALIDATION,
    ];

    tabs.forEach((tabPath, index) => {
      if (currentPath === t(routerPathLocal(i18n.language, tabPath))) {
        setActiveTab(`tab${index + 1}`);
      }
    });
  }, [i18n.language, location.pathname, t]);

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  const handleAddSpaceButtonClick = () => {
    navigate(routerPathLocal(i18n.language, RouterPath.MANAGER_ADD_SPACE));
  };

  const tabs = [
    {
      title: t("ManagerProfile.SpaceTabs.Active"),
      path: routerPathLocal(i18n.language, RouterPath.MANAGER_MY_SPACES_ACTIVE),
      key: "tab1",
    },
    {
      title: t("ManagerProfile.SpaceTabs.Inactive"),
      path: routerPathLocal(
        i18n.language,
        RouterPath.MANAGER_MY_SPACES_INACTIVE
      ),
      key: "tab2",
    },
    {
      title: t("ManagerProfile.SpaceTabs.Waiting"),
      path: routerPathLocal(
        i18n.language,
        RouterPath.MANAGER_MY_SPACES_WAITING_FOR_VALIDATION
      ),
      key: "tab3",
    },
  ];

  return (
    <ProfileLayout menu={menuManager(i18n.language, t)}>
      <>
        {isScreenMoreThanMD !== null && !isScreenMoreThanMD ? (
          <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <CustomButton type="succes" onClick={handleAddSpaceButtonClick}>
              <PlusOutlined /> {t("Buttons.AddSpace")}
            </CustomButton>
          </div>
        ) : (
          <></>
        )}
        <CustomTabs
          tabs={tabs}
          onTabClick={handleTabClick}
          activeTab={activeTab}
        >
          {isScreenMoreThanMD !== null && isScreenMoreThanMD ? (
            <CustomButton type="succes" onClick={handleAddSpaceButtonClick}>
              <PlusOutlined /> {t("Buttons.AddSpace")}
            </CustomButton>
          ) : (
            <></>
          )}
        </CustomTabs>
        <div>
          {activeTab === "tab1" && (
            <ListSpaces
              fetchSpaces={fetchActiveSpaces}
              routerPath={RouterPath.MANAGER_MODIFY_SPACE}
            />
          )}
          {activeTab === "tab2" && (
            <ListSpaces
              fetchSpaces={fetchInactiveSpaces}
              routerPath={RouterPath.MANAGER_MODIFY_SPACE}
            />
          )}
          {activeTab === "tab3" && (
            <ListSpaces
              fetchSpaces={fetchWaitingSpaces}
              routerPath={RouterPath.MANAGER_MODIFY_SPACE}
            />
          )}
        </div>
      </>
    </ProfileLayout>
  );
}

export default observer(MySpacesListManager);

// const StyledModifyIcon = styled(ModifyIcon)`
//   stroke-width: 1.5px;
// `;
