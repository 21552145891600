import { Avatar, Button, Col, Row } from "antd";
import useStore from "hooks/useStore";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ReactComponent as Star } from "assets/images/star.svg";
import { ReactComponent as SmallStar } from "assets/images/smallStarComment.svg";

const stars = [1, 2, 3, 4, 5];

function SpaceComments(): JSX.Element {
  const { t } = useTranslation();
  const {
    spaceStore: { comments, currentSpace },
    spaceStore,
  } = useStore();
  const { spacePseudo } = useParams<{ spacePseudo: string }>();

  useEffect(() => {
    spaceStore.fetchComments(spacePseudo!);
  }, [spacePseudo, spaceStore]);

  return (
    <>
      <Col>
        <h5>{t("SpacePage.ReviewsUP")}</h5>
        <Row
          style={{
            marginBottom: "13px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "5px",
            }}
          >
            <Star style={{ fill: "#dbde45" }} />
          </Col>
          <span style={{ color: "#1F1F1F", fontSize: "15px" }}>
            {currentSpace?.comment_number ? (
              <>
                <span style={{ fontWeight: "600" }}>
                  {currentSpace?.getFormattedRating()}{" "}
                </span>
                - {currentSpace?.comment_number} {t("SpacePage.Reviews")}
              </>
            ) : (
              <>{t("SpacePage.NoReviews")}</>
            )}
          </span>
        </Row>
        <Row>
          <Col
            style={{ gap: "20px", display: "flex", flexDirection: "column" }}
          >
            {comments.map((comment) => (
              <Row key={comment.id}>
                <Col>
                  <Row style={{ marginBottom: "7px" }}>
                    <Col>
                      <Avatar
                        size={50}
                        src={comment.url_avatar}
                        style={{ marginRight: "10px" }}
                      >
                        {comment.first_name_user.charAt(0) +
                          comment.last_name_user.charAt(0)}
                      </Avatar>
                    </Col>
                    <Col style={{ display: "flex", alignItems: "center" }}>
                      <div>
                        <Row style={{ marginBottom: "5px" }}>
                          {comment.first_name_user} {comment.last_name_user}
                        </Row>
                        <Row
                          style={{
                            gap: "2px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          {stars.map((star) => (
                            <SmallStar
                              key={star}
                              className={
                                star <= comment.rating
                                  ? "filled-star"
                                  : "small-grey-star"
                              }
                            ></SmallStar>
                          ))}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <p style={{ margin: "0" }}>{comment.message}</p>
                  </Row>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      </Col>
    </>
  );
}

export default observer(SpaceComments);
