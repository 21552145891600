import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector';
import TRANSLATIONS_FR from './assets/translations/fr.json'
import TRANSLATIONS_EN from './assets/translations/en.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      fr: {
        translation: TRANSLATIONS_FR,
      },
      en: {
        translation: TRANSLATIONS_EN,
      },
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['path', 'localStorage', 'querystring', 'navigator'],
      lookupFromPathIndex: 0,
      caches: ['localStorage']
    },
  })