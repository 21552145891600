import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Space, Form, Select, DatePicker, Input } from "antd";
import ListClicableSpaces from "components/listClicableSpaces/ListClicableSpaces";
import InfiniteScroll from "react-infinite-scroll-component";
import useStore from "hooks/useStore";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import {
  GoogleMap,
  Marker,
  useLoadScript,
  InfoWindowF,
  MarkerClustererF,
} from "@react-google-maps/api";
import { Helmet } from "react-helmet";
import { mapOptionsGet } from "constants/googleMap";
import { useLocation } from "react-router-dom";
import CustomButton from "components/buttons/CustomButton";
import { ReactComponent as FiltersIcon } from "assets/images/filtersIcon.svg";
import { sortedArraySpaceType } from "constants/SpaceType";
import { useWindowSize, Size } from "hooks/useWindowSize";
import SpaceCardPreview from "components/spaceCardPreview/SpaceCardPreview";
import "./my_style.css";
import { windowBreakpoints } from "constants/windowBreakpoints";
import AppConfig from "appConfig";
// import usePreventPullToRefresh from "hooks/usePreventPullToRefresh";

/** Main search page (Catalog) with map and search filters. */
function MainSearch() {
  const { t, i18n } = useTranslation();
  const { infoStore } = useStore();
  // const divRef = usePreventPullToRefresh();
  const { spaceListStore, spaceListMapStore } = useStore();
  const { auth } = useStore();
  const location = useLocation();
  const screen: Size = useWindowSize();
  // filters
  const [filtersCount, setFiltersCount] = useState<number>(0);
  const [spaceType, setSpaceType] = useState<string | undefined>(undefined);
  const [spaceEvent, setSpaceEvent] = useState<string | undefined>(undefined);
  const [dateFilter, setDateFilter] = useState<undefined>(undefined);
  const [standingPlaces, setStandingPlaces] = useState<number | undefined>(
    undefined
  );
  const [seats, setSeats] = useState<number | undefined>(undefined);
  const [northEast, setNorthEast] = useState<google.maps.LatLng | null>(null);
  const [southWest, setSouthWest] = useState<google.maps.LatLng | null>(null);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [form] = Form.useForm();
  const [mapCenter, setMapCenter] = useState(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: AppConfig.secrets.GOOGLE_API_KEY,
  });
  const mapRef = useRef<google.maps.Map>();

  const [MapExpanded, setMapExpanded] = useState<boolean>(false);
  const [MapVisible, setMapVisible] = useState<boolean>(false);
  const [buttonMapList, setButtonMapList] = useState<boolean>(false);
  const [activeMarker, setActiveMarker] = useState<any>(null);
  const [temp, setTemp] = useState({});

  useEffect(() => {
    infoStore.fetchEvents(i18n.language);
  }, [i18n.language, infoStore]);

  // console.log(AppConfig.secrets.GOOGLE_API_KEY);

  useEffect(() => {
    if (screen.width && screen.width > windowBreakpoints.LG) {
      setMapExpanded(false);
      setMapVisible(false);
      setButtonMapList(false);
    } else {
      setMapExpanded(true);
      if (buttonMapList) {
        setMapVisible(true);
      }
    }
  }, [buttonMapList, screen.width]);

  // Execute only on mount
  useEffect(() => {
    // Access the query string parameters from the location object
    const queryParams = new URLSearchParams(location.search);
    setSpaceType(queryParams.get("spaceType") || undefined);
    setSpaceEvent(queryParams.get("spaceEvent") || undefined);
    setStandingPlaces(
      queryParams.get("standingPlaces")
        ? Number(queryParams.get("standingPlaces"))
        : undefined
    );
    setSeats(
      queryParams.get("seats") ? Number(queryParams.get("seats")) : undefined
    );

    const geo_lat = queryParams.get("lat");
    const geo_long = queryParams.get("lng");
    if (geo_lat && geo_long) {
      setMapCenter({ lat: Number(geo_lat), lng: Number(geo_long) });
    } else {
      setMapCenter({
        lat: 48.864716,
        lng: 2.349018,
      });
    }
    queryParams.delete("lat");
    queryParams.delete("lng");
    window.history.replaceState({}, "", `${location.pathname}?${queryParams}`);
  }, []);

  // useEffect(() => {
  //   // Access the query string parameters from the location object
  //   const queryParams = new URLSearchParams(location.search);
  //   setSpaceType(queryParams.get("spaceType") || undefined);

  //   const geo_lat = queryParams.get("lat");
  //   const geo_long = queryParams.get("lng");
  //   if (geo_lat && geo_long) {
  //     setMapCenter({ lat: Number(geo_lat), lng: Number(geo_long) });
  //   } else {
  //     setMapCenter({
  //       lat: 48.864716,
  //       lng: 2.349018,
  //     });
  //   }
  // }, [location]);

  // Get bounds of map, when map is ready
  const onMapLoad = React.useCallback((map: google.maps.Map) => {
    mapRef.current = map;
  }, []);
  const onMapIdle = React.useCallback(() => {
    const map = mapRef.current;
    if (map) {
      const bounds = map.getBounds();
      if (bounds) {
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();
        setNorthEast(ne);
        setSouthWest(sw);
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      // setIsFiltersOpen(false);
      spaceListStore.clearSpaceList();
      spaceListMapStore.clearSpaceListMap();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** useEffect to fetch space for list with images and map. */
  useEffect(() => {
    let timer: any;
    if (northEast && southWest) {
      timer = setTimeout(() => {
        spaceListMapStore.clearSpaceListMap();
        spaceListMapStore.setPage(1);
        spaceListMapStore.fetchSpaceListMap({
          lang: i18n.language,
          date: dateFilter,
          seat_number: seats,
          standing_number: standingPlaces,
          id_event: spaceEvent ? Number(spaceEvent) : undefined,
          space_type: spaceType,
          page: 1,
          size: 100, //bug fix (need size <=100)
          northeast_lat: northEast.lat(),
          northeast_long: northEast.lng(),
          southwest_lat: southWest.lat(),
          southwest_long: southWest.lng(),
        });

        spaceListStore.clearSpaceList();
        spaceListStore.setPage(1);
        spaceListStore.fetchSpaceList({
          lang: i18n.language,
          date: dateFilter,
          seat_number: seats,
          standing_number: standingPlaces,
          id_event: spaceEvent ? Number(spaceEvent) : undefined,
          space_type: spaceType,
          page: 1,
          size: 20,
          northeast_lat: northEast.lat(),
          northeast_long: northEast.lng(),
          southwest_lat: southWest.lat(),
          southwest_long: southWest.lng(),
        });
      }, 200); // delay of 500ms
    }

    return () => {
      clearTimeout(timer); // clear the timeout
    };
  }, [
    spaceListStore,
    northEast,
    southWest,
    spaceListMapStore,
    spaceType,
    spaceEvent,
    standingPlaces,
    seats,
    dateFilter,
    auth.currentUser,
  ]);

  useEffect(() => {
    let count = 0;
    if (spaceType) {
      count += 1;
    }
    if (spaceEvent) {
      count += 1;
    }
    if (dateFilter) {
      count += 1;
    }
    if (standingPlaces) {
      count += 1;
    }
    if (seats) {
      count += 1;
    }
    setFiltersCount(count);
  }, [dateFilter, seats, spaceEvent, spaceType, standingPlaces]);

  const handleActiveMarker = (spaceId: number) => {
    if (spaceId === activeMarker) {
      return;
    }
    setActiveMarker(spaceId);
  };

  const handleButtonFiltersClick = () => {
    setIsFiltersOpen(!isFiltersOpen);
  };

  /**Function to set state variable and handle query params.
   * Set queryParams if there is a value
   * Otherwise delete the key from the queryParams
   */
  const setFilterValue = (
    setValue: (value: any) => void,
    value: any,
    queryParams: URLSearchParams,
    queryKey: string
  ) => {
    setValue(value);
    if (value) {
      queryParams.set(queryKey, value);
    } else if (queryParams.get(queryKey)) {
      queryParams.delete(queryKey);
    }
  };

  const handleButtonApplyFiltersClick = () => {
    const queryParams = new URLSearchParams(location.search);
    const formSpaceType = form.getFieldValue("spaceType");
    const formTypeEvent = form.getFieldValue("typeEvent");
    const standingPlacesForm = form.getFieldValue("standingPlaces");
    const seatsForm = form.getFieldValue("seats");
    const formDate = form.getFieldValue("date");

    setFilterValue(setSpaceType, formSpaceType, queryParams, "spaceType");
    setFilterValue(setSpaceEvent, formTypeEvent, queryParams, "spaceEvent");
    setFilterValue(
      setStandingPlaces,
      standingPlacesForm,
      queryParams,
      "standingPlaces"
    );
    setFilterValue(setSeats, seatsForm, queryParams, "seats");
    setFilterValue(
      setDateFilter,
      formDate?.format("YYYY-MM-DD"),
      queryParams,
      "date"
    );

    queryParams.delete("lat");
    queryParams.delete("lng");
    window.history.replaceState({}, "", `${location.pathname}?${queryParams}`);
    setIsFiltersOpen(false);
  };

  const handleButtonCancelClick = () => {
    setIsFiltersOpen(false);
  };

  const hadleClearFiltersButtonClick = async () => {
    const queryParams = new URLSearchParams();
    window.history.replaceState({}, "", `${location.pathname}`);
    await setSpaceType(undefined);
    await setSpaceEvent(undefined);
    await setDateFilter(undefined);
    await setStandingPlaces(undefined);
    await setSeats(undefined);
    window.history.replaceState({}, "", `${location.pathname}?${queryParams}`);
    await form.resetFields();
  };

  const handleMapButton = () => {
    setButtonMapList((prev) => !prev);
    setMapVisible((prev) => !prev);
  };

  const renderViewMapButton = () => {
    if (MapExpanded && isLoaded) {
      return (
        <StyledViewMapButton>
          <CustomButton
            size="large"
            type="primary"
            shape="circle"
            onClick={handleMapButton}
            style={{ width: "80px", height: "80px", pointerEvents: "auto" }}
          >
            {MapVisible ? (
              <>{t("SearchSpacePage.ButtonList")}</>
            ) : (
              <>{t("SearchSpacePage.ButtonMap")}</>
            )}
          </CustomButton>
        </StyledViewMapButton>
      );
    } else {
      return <></>;
    }
  };

  const renderMap = () => {
    if (spaceListMapStore.idHoveredSpace) {
      // It's hot fix of bounce of marker, see mobx to resolve this problem properly
    }
    return (
      <>
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={{
              height: "100%",
              width: "100%",
              visibility:
                (buttonMapList && MapExpanded) || !MapExpanded
                  ? "visible"
                  : "hidden",
              pointerEvents: "auto",
            }}
            zoom={7}
            onIdle={onMapIdle}
            center={mapCenter}
            options={mapOptionsGet(!MapExpanded, true)}
            onLoad={onMapLoad}
            onClick={() => setActiveMarker(null)}
          >
            {/* UNCOMMENT TO ACTIVATE CLUSTERING !!! */}
            {/* {spaceListMapStore.getSpaceListMapAsArray().length > 0 && (
              <MarkerClustererF
                // zoomOnClick={false}
                options={{
                  // maxZoom: 7,
                  styles: [
                    {
                      url: require("assets/images/markerCluster.svg").default,
                      height: 36,
                      width: 36,
                      textColor: "#ffffff",
                      textSize: 14,
                    },
                  ],
                }}
              >
                {(clusterer) => ( */}
            <div>
              {spaceListMapStore.getSpaceListMapAsArray().map((space) => (
                <Marker
                  // clusterer={clusterer}
                  icon={{
                    url: require("assets/images/maps_marker.svg").default,
                  }}
                  key={space.id}
                  position={
                    new window.google.maps.LatLng(space.geo_lat, space.geo_long)
                  }
                  animation={
                    spaceListMapStore.idHoveredSpace === String(space.id)
                      ? window.google.maps.Animation.BOUNCE
                      : undefined
                  }
                  onClick={() => {
                    handleActiveMarker(space.id);
                    setTemp({
                      lat: space.geo_lat,
                      long: space.geo_long,
                      id: space.id,
                      pseudo: space.pseudo,
                    });
                  }}
                />
              ))}
            </div>
            {/* )}
              </MarkerClustererF>
            )} */}
            {activeMarker === temp.id ? (
              <InfoWindowF
                key={temp.id}
                options={{
                  disableAutoPan: false,
                  pixelOffset: new window.google.maps.Size(0, -48),
                }}
                position={new window.google.maps.LatLng(temp.lat, temp.long)}
              >
                <SpaceCardPreview
                  pseudo={temp.pseudo}
                  closeWindow={setActiveMarker}
                  expand={buttonMapList && MapExpanded}
                />
              </InfoWindowF>
            ) : null}
          </GoogleMap>
        ) : null}
      </>
    );
  };

  return (
    <>
      <Helmet
        title={t("SearchSpacePage.PageHeadTitle")}
        htmlAttributes={{ lang: i18n.language }}
        meta={[
          {
            name: `description`,
            content: t("SearchSpacePage.PageHeadDescription"),
          },
        ]}
      />
      <StyledMain>
        {/*Main container*/}
        <StyledRow style={{ width: MapExpanded ? "100%" : "50%" }}>
          {/* FILTERS*   */}
          <FiltersMenu>
            <Space size={20}>
              <CustomButton
                size="large"
                type="grey"
                style={{
                  height: "50px",
                  paddingLeft: "30px",
                  paddingRight: "30px",
                }}
                onClick={handleButtonFiltersClick}
              >
                <StyledFiltersIcon />
                {t("SearchSpacePage.ButtonFilters")}
                {filtersCount ? " (" + filtersCount + ")" : ""}
              </CustomButton>
              <CustomButton
                type="default"
                style={{
                  height: "50px",
                  // paddingLeft: "30px",
                  // paddingRight: "30px",
                }}
                size="large"
                onClick={hadleClearFiltersButtonClick}
              >
                {t("SearchSpacePage.ButtonClearFilters")}
              </CustomButton>
            </Space>
          </FiltersMenu>
          {/* SECTION SCROLLABLE SPACES */}
          <div style={{ width: "100%", height: "100%", position: "relative" }}>
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                visibility: buttonMapList && MapExpanded ? "hidden" : "visible",
              }}
            >
              <ScrollableArea id="scrollableDiv">
                <InfiniteScroll
                  dataLength={spaceListStore.getSpaceListAsArray().length}
                  next={() => {
                    spaceListStore.setPage(spaceListStore.page + 1);
                    spaceListStore.fetchSpaceList({
                      lang: i18n.language,
                      page: spaceListStore.page,
                      size: 20,
                      date: dateFilter,
                      seat_number: seats,
                      standing_number: standingPlaces,
                      id_event: spaceEvent ? Number(spaceEvent) : undefined,
                      space_type: spaceType,
                      northeast_lat: northEast?.lat(),
                      northeast_long: northEast?.lng(),
                      southwest_lat: southWest?.lat(),
                      southwest_long: southWest?.lng(),
                    });
                  }}
                  hasMore={
                    spaceListStore.getSpaceListAsArray().length <
                    spaceListStore.total
                  }
                  loader={<h4> </h4>}
                  scrollableTarget="scrollableDiv"
                >
                  <InfiniteScrollItems style={{ paddingTop: "10px" }}>
                    <ListClicableSpaces
                      listSpaces={spaceListStore.getSpaceListAsArray()}
                    />
                  </InfiniteScrollItems>
                </InfiniteScroll>
              </ScrollableArea>
            </div>
            {isFiltersOpen && (
              <FiltersArea style={{ position: "absolute", top: 0, left: 0 }}>
                <FiltersAreaHeader>
                  {t("SearchSpacePage.TitleFilters")}
                </FiltersAreaHeader>
                <FiltersAreaBody>
                  <Form form={form} layout="vertical">
                    <FiltersItem>
                      <Form.Item
                        name="spaceType"
                        label={t("SearchSpacePage.FilterLabelTypeSpace")}
                        initialValue={spaceType}
                        style={{ width: "100%" }}
                      >
                        <Select allowClear value={spaceType}>
                          <Select.Option value={undefined}> </Select.Option>
                          {sortedArraySpaceType(t).map((spaceType) => (
                            <Select.Option
                              key={spaceType.key}
                              value={spaceType.key}
                            >
                              {t("SpaceTypes." + spaceType.value)}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="typeEvent"
                        label={t("SearchSpacePage.FilterLabelTypeEvent")}
                        // initialValue={"BAR"} //TODO DELETE
                        style={{ width: "100%" }}
                      >
                        <Select
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            option?.children
                              ?.toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {infoStore.getEventsAsArray().map((event) => (
                            <Select.Option key={event.id} value={event.id}>
                              {event.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </FiltersItem>
                    <Form.Item
                      label={t("SearchSpacePage.FilterLabelDate")}
                      name="date"
                    >
                      <DatePicker placeholder="" style={{ width: "100%" }} />
                    </Form.Item>
                    <div
                      style={{ display: "flex", gap: "20px", width: "100%" }}
                    >
                      <Form.Item
                        name="seats"
                        label={t("SearchSpacePage.FilterLabelSeatNumber")}
                        // initialValue={4} //TODO DELETE
                        style={{ width: "100%" }}
                      >
                        <Input allowClear type="number" />
                      </Form.Item>
                      <Form.Item
                        name="standingPlaces"
                        label={t("SearchSpacePage.FilterLabelStandingNumber")}
                        // initialValue={4} //TODO DELETE
                        style={{ width: "100%" }}
                      >
                        <Input allowClear type="number" />
                      </Form.Item>
                    </div>
                  </Form>
                </FiltersAreaBody>
                <FiltersAreaFooter>
                  <FiltersItemButtons
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    <CustomButton
                      size="large"
                      type="succes"
                      onClick={handleButtonApplyFiltersClick}
                    >
                      {t("SearchSpacePage.ButtonApplyFilters")}
                    </CustomButton>
                    <CustomButton
                      size="large"
                      type="ghost"
                      onClick={handleButtonCancelClick}
                    >
                      {t("Buttons.Cancel")}
                    </CustomButton>
                  </FiltersItemButtons>
                </FiltersAreaFooter>
              </FiltersArea>
            )}
          </div>
        </StyledRow>
        {/*Map container*/}
        <div
          style={{
            pointerEvents: "none",
            paddingTop: MapExpanded ? "80px" : "0",
            position: "absolute",
            left: MapExpanded ? 0 : "50%",
            top: 0,
            width: MapExpanded ? "100%" : "50%",
            flex: "auto",
            height: "100%",
            zIndex: (buttonMapList && MapExpanded) || !MapExpanded ? 57 : -1,
          }}
        >
          {renderMap()}
        </div>
      </StyledMain>
      {renderViewMapButton()}
    </>
  );
}

export default observer(MainSearch);

const StyledMain = styled.div`
  position: relative;
  flex: auto;
  height: "100";
`;

const StyledRow = styled.div`
  position: absolute;
  flex: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`;

const ScrollableArea = styled.div`
  width: auto;
  height: 100%;
  overflow-y: auto;
`;

const InfiniteScrollItems = styled.div`
  padding-left: 5px; /* +10px cards */
  padding-right: 5px;
  padding-bottom: 10px;
`;

const FiltersMenu = styled.div`
  padding-left: 15px;
  padding-bottom: 20px;
`;

const FiltersArea = styled.div`
background: #ffffff;
border: 0.5px solid #b4b4b4;
box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
border-radius: 10px;
position: absolute;
margin-top: 10px;
z-index: 59;  
width: calc(100% - 30px);
margin-left: 15px;
max-height: calc(100% - 70px);
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;


@media (max-width: 992px) {
  margin-top: 0;
  border: 0;
  box-shadow: none
  margin-top: 0;
  margin-left: 0;
  border-radius: 0;
  width: 100%;
  height: 100%; 
  position: absolute; 
  top: 0; 
  left: 0; 
  max-height: 100% ;
}
`;

const FiltersAreaHeader = styled.div`
  font-family: "Ranade-Variable";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 113%;
  letter-spacing: -0.04em;
  color: #000000;
  padding: 24px 20px 20px;
  width: 100%;

  @media (max-width: 576px) {
    padding: 24px 28px 20px;
  }
`;
const FiltersAreaBody = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  overflow-y: auto;

  padding: 0 20px 0;

  @media (max-width: 576px) {
    padding: 0 28px 0;
  }
`;

const FiltersAreaFooter = styled.div`
  width: 100%;
  padding: 24px 20px 24px;

  @media (max-width: 576px) {
    padding: 24px 28px 10px;
  }
`;

const FiltersItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;

  @media (max-width: 576px) {
    flex-direction: column;
    gap: 0;
  }
`;

const FiltersItemButtons = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

const StyledFiltersIcon = styled(FiltersIcon)`
  fill: #000;
  margin-right: 5px;
`;

const StyledViewMapButton = styled.div`
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  z-index: 58;
  padding: 10px 30px;
  pointer-events: none;
`;
