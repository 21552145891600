import { applySnapshot, flow, Instance, types } from 'mobx-state-tree'
import { requestStatus } from "constants/reqestStatus"
import MessengerService, { IMessageItemAPIResponse } from 'services/MessengerService';
import { Message } from 'states/models/Messenger/Message';


export const MessageStore = types
  .model({
    messages: types.optional(types.map(Message), {}),
    total: types.optional(types.number, -1),
    page: types.optional(types.number, -1),
    size: types.optional(types.number, -1),
    id_active_inbox: types.optional(types.number, -1),
    first_name_other_participant: types.optional(types.string, ""),
    last_name_other_participant: types.optional(types.string, ""),
    // status: types.optional(
    //   types.enumeration<requestStatus>(Object.values(requestStatus)), requestStatus.INITIAL),

  })
  .views((self) => ({
    getMessegesAsArray: () => {
      const messagesArray = Array.from(self.messages.values()).sort((a, b) => {
          return b.date_creation.localeCompare(a.date_creation);
        });
      return messagesArray;
    }
  }))
  .actions((self) => ({
    clearMessages: (
    ) => {
      applySnapshot(self.messages, {})
    }
  }))
  .actions((self) => ({
    setPage: (page: number
    ) => {
      self.page=page
    }
  }))
  .actions((self) => ({
    setActiveInbox: (
      id_inbox: number,
      first_name_other_participant: string,
      last_name_other_participant: string,
    ) => {
      self.clearMessages();
      self.page = 1;
      self.id_active_inbox = id_inbox;
      self.first_name_other_participant = first_name_other_participant;
      self.last_name_other_participant = last_name_other_participant;
    }
  }))

  .actions((self) => ({
    updateMessages: (
      messages: Array<IMessageItemAPIResponse>
    ) => {
      messages.forEach((message) => {
        self.messages.set(
          message.date_creation,
          {
            is_my_message: message.is_my_message,
            message: message.message,
            date_creation: message.date_creation,
          }
        )
      })
    }
  }))
  .actions((self) => ({
    fetchMessages: flow(function* fetchMessages(page = 1, size = 20) {
      if (self.id_active_inbox !== -1) {
        try {
          const data = yield MessengerService.getInboxMessages({
              id_inbox: self.id_active_inbox,
              page: page,
              size: size,
          });
          self.updateMessages(data.data.items);
          self.total = data.data.total
          self.size = data.data.size
        } catch
        {
          // self.status = requestStatus.ERROR;
        }
      }
    })
  }))
  .actions((self) => ({
    sendMessageToInbox: flow(function* fetchMessages(message: string) {
      const data = yield MessengerService.postCreateMessageToInbox({
        id_inbox_to: self.id_active_inbox,
        message: message
      })
      self.updateMessages(
        [{  is_my_message: data.data.is_my_message,
            message: data.data.message,
            date_creation: data.data.date_creation,}]
      )
      // self.id_active_inbox = id_inbox;
    })
  }))


export type IMessageStore = Instance<typeof MessageStore>