import { AxiosResponse } from "axios";
import { ApiEndpointsV1 } from "assets/endpoints/v1";
import { enumUserRole } from 'constants/User'
import { axiosInstance } from 'services/axios/Axios';



export interface INumberUnreadMessagesAPIResponse{
  number_unread_messages: number;
}

/**
 * API number of unread messages for current user.
 */
function getNumberOfUnreadMessages(): Promise<AxiosResponse<INumberUnreadMessagesAPIResponse>> {
  return axiosInstance
    .get(ApiEndpointsV1.GET_NUMBER_OF_UNREAD_MESSAGES)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });;
};



export interface IInboxesParams {
  page: number;
  size: number;
}


export interface IInboxesAPIResponse{
  items: IInboxesItemAPIResponse[];
  total: number;
  page: number;
  size: number;
}


export interface IInboxesItemAPIResponse{
    id: number,
    name: string,
    date_creation: string,
    first_name: string,
    last_name: string,
    message: string,
    url_avatar: string,
    is_seen: boolean,
}

/**
 * API to get inboxes for current user.
 */
function getMyInboxes(queryParams?: IInboxesParams): Promise<AxiosResponse<IInboxesAPIResponse>> {
  return axiosInstance
    .get(ApiEndpointsV1.GET_MY_INBOXES, {
      params: queryParams,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });;
};


export interface IMessagesParams {
  id_inbox: number;
  page: number;
  size: number;
}


export interface IMessagesAPIResponse{
  items: IMessageItemAPIResponse[];
  total: number;
  page: number;
  size: number;
}


export interface IMessageItemAPIResponse{
  is_my_message: boolean;
  message: string;
  date_creation: string;
}

/**
 * API to to get messages for an inbox.
 */
function getInboxMessages(queryParams?: IMessagesParams): Promise<AxiosResponse<IMessagesAPIResponse>> {
  return axiosInstance
    .get(ApiEndpointsV1.GET_MESSAGES_FOR_INBOX, {
      params: queryParams,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });;
};



export interface ICreateMessageData {
  id_user_to: number;
  message: string;
}

export interface ICreateMessageResponse {
  msg: string;
}

/**
 * API to send a message from current user to another user.
 */
function postCreateMessage(data: ICreateMessageData): Promise<AxiosResponse<ICreateMessageResponse>> {
  return axiosInstance
    .post(ApiEndpointsV1.CREATE_MESSAGE, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });;
};



export interface ICreateMessageToInboxData {
  id_inbox_to: number;
  message: string;
}

export interface ICreateMessageToInboxResponse {
  is_my_message: boolean;
  message: string;
  date_creation: string;
}

/**
 * API to send a message from current user to another user.
 */
function postCreateMessageToInbox(
  data: ICreateMessageToInboxData): Promise<AxiosResponse<ICreateMessageToInboxResponse>> {
  return axiosInstance
    .post(ApiEndpointsV1.CREATE_MESSAGE_TO_INBOX, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });;
};


const DataService = {
  getNumberOfUnreadMessages,
  getMyInboxes,
  getInboxMessages,
  postCreateMessage,
  postCreateMessageToInbox,
};

export default DataService;

