import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
// import MyProfileComponent from "components/myProfile/MyProfileComponent";
import menuCustomer from "../commonComponents/menuCustomer";
import NotificationsComponent from "components/myProfile/NotificationsComponent";

/** Notifications Customer page. */
function CustomerNotifications(): JSX.Element {
  const { t, i18n } = useTranslation();

  return (
    <>
      <NotificationsComponent
        menu={menuCustomer(i18n.language, t)}
      ></NotificationsComponent>
    </>
  );
}

export default observer(CustomerNotifications);
