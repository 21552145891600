import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  TimePicker,
  Row,
  Col,
  Tooltip,
} from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { IOpeningHours } from "services/SpaceService";
import { useTranslation } from "react-i18next";
import { QuestionCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

type FormData = {
  day: string;
  time_from: string;
  time_to: string;
  min_price: number;
};

interface TimeFormProps {
  form: any;
  onFormDataChange: (formData: FormData[]) => void;
  initialOpeningHours: IOpeningHours[];
}

const TimeForm: React.FC<TimeFormProps> = ({
  form,
  onFormDataChange,
  initialOpeningHours,
}) => {
  const [formData, setFormData] = useState<FormData[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (initialOpeningHours && initialOpeningHours.length > 0) {
      updateFormData(initialOpeningHours);
    }
  }, [initialOpeningHours]);

  const updateFormData = (newFormData: FormData[]) => {
    setFormData(newFormData);
    onFormDataChange(newFormData);
    const fieldsValue = newFormData.reduce((obj, row, index) => {
      obj[`rows[${index}].day`] = row.day;
      if (row.time_from) {
        obj[`rows[${index}].time_from`] = dayjs(row.time_from, "HH:mm");
      }
      if (row.time_to) {
        obj[`rows[${index}].time_to`] = dayjs(row.time_to, "HH:mm");
      }
      if (row.min_price) {
        obj[`rows[${index}].min_price`] = row.min_price;
      }
      return obj;
    }, {});

    form.setFieldsValue(fieldsValue);
  };

  const addRow = () => {
    const newRow = {
      day: "",
      time_from: "",
      time_to: "",
      min_price: 0,
    };
    updateFormData([...formData, newRow]);
  };

  const onDeleteRow = (index: number) => {
    const newFormData = [...formData];
    newFormData.splice(index, 1);
    updateFormData(newFormData);
  };

  const onDayChange = (value: string, index: number) => {
    const newFormData = [...formData];
    newFormData[index].day = value;
    setFormData(newFormData);
  };

  const onTimeChange = (field: string, value: dayjs.Dayjs, index: number) => {
    const newFormData = [...formData];
    newFormData[index][field] = value.format("HH:mm");
    setFormData(newFormData);
  };

  const onMinPriceChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newFormData = [...formData];
    newFormData[index].min_price = parseFloat(e.target.value);
    setFormData(newFormData);
  };

  return (
    <Form form={form}>
      <Row style={{ marginBottom: 8 }}>
        {t("CommonAddModifySpace.OpeningHoursAndPrices")}{" "}
        <Tooltip title={t("CommonAddModifySpace.OpeningHoursAndPricesTooltip")}>
          <QuestionCircleOutlined style={{ marginLeft: "5px" }} />
        </Tooltip>
      </Row>
      {formData.map((row, index) => (
        <Row gutter={16} key={index}>
          <Col span={6}>
            <Form.Item
              name={`rows[${index}].day`}
              rules={[
                { required: true, message: t("ValidationErrors.Required") },
              ]}
              style={{ width: "100%" }}
            >
              <Select
                placeholder={t("CommonAddModifySpace.PlaceholderDay")}
                onChange={(value) => onDayChange(value, index)}
                value={row.day}
              >
                <Option value="MON">{t("Days.MON")}</Option>
                <Option value="TUE">{t("Days.TUE")}</Option>
                <Option value="WED">{t("Days.WED")}</Option>
                <Option value="THU">{t("Days.THU")}</Option>
                <Option value="FRI">{t("Days.FRI")}</Option>
                <Option value="SAT">{t("Days.SAT")}</Option>
                <Option value="SUN">{t("Days.SUN")}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name={`rows[${index}].time_from`}
              rules={[
                { required: true, message: t("ValidationErrors.Required") },
              ]}
            >
              <TimePicker
                minuteStep={5}
                format="HH:mm"
                onChange={(value) => onTimeChange("time_from", value, index)}
                value={dayjs(row.time_from, "HH:mm")}
                style={{ width: "100%" }}
                showNow={false}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name={`rows[${index}].time_to`}
              rules={[
                { required: true, message: t("ValidationErrors.Required") },
              ]}
            >
              <TimePicker
                minuteStep={5}
                format="HH:mm"
                onChange={(value) => onTimeChange("time_to", value, index)}
                value={dayjs(row.time_to, "HH:mm")}
                style={{ width: "100%" }}
                showNow={false}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name={`rows[${index}].min_price`}
              rules={[
                { required: true, message: t("ValidationErrors.Required") },
              ]}
              style={{ width: "100%" }}
            >
              <Input
                type="number"
                placeholder="Min price"
                onChange={(e) => onMinPriceChange(e, index)}
                value={row.min_price}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button type="link" onClick={() => onDeleteRow(index)}>
              <DeleteOutlined />
            </Button>
          </Col>
        </Row>
      ))}
      <Form.Item name={"open_time"}>
        <Button type="dashed" onClick={addRow} icon={<PlusOutlined />}>
          {t("CommonAddModifySpace.ButtonAddDay")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default TimeForm;
