import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Row, Col, Form, Input, Button, notification } from "antd";
import useStore from "hooks/useStore";
import { observer } from "mobx-react-lite";
import InboxItem from "components/messages/InboxItem";
import MessageItem from "components/messages/MessageItem";
import InfiniteScroll from "react-infinite-scroll-component";
import { ReactComponent as BackArrow } from "assets/images/backArrowIcon.svg";
import { Helmet } from "react-helmet";

/** Messenger page.
 * It's the same for all three User roles: Manager, Customer and Admin.
 * Adaptive for small screens. Breakpoint at 768px window width.
 */
function Messenger(): JSX.Element {
  const { t, i18n } = useTranslation();
  const { inboxStore, messageStore } = useStore();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowInboxes, setIsShowInboxes] = useState(true);
  const [isMoreThan768px, setIsMoreThan768px] = useState(
    window.innerWidth >= 768
  );

  useEffect(() => {
    const handleResize = () => setIsMoreThan768px(window.innerWidth > 768);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    inboxStore.setPage(1);
    inboxStore.fetchInboxes(1, 20);
  }, [inboxStore]);

  useEffect(() => {
    messageStore.fetchMessages(1, 20);
  }, [messageStore, messageStore.id_active_inbox]);

  const handleClickButtonSend = async () => {
    await form.validateFields();
    setIsLoading(true);
    try {
      await messageStore.sendMessageToInbox(form.getFieldValue("message"));
      form.resetFields();
    } catch {
      notification.error({
        message: t("NotificationErrors.Error.Title"),
        description: t("NotificationErrors.Error.Description"),
        placement: "bottomRight",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickShowInboxes = async () => {
    setIsShowInboxes(false);
  };

  return (
    <>
      <Helmet
        title={t("Messenger.PageHeadTitle")}
        htmlAttributes={{ lang: i18n.language }}
        meta={[
          {
            name: `description`,
            content: t("Messenger.PageHeadDescription"),
          },
        ]}
      />
      <Container>
        <h1
          style={{
            padding: "0 0 15px",
            margin: "0",
            display:
              isMoreThan768px || (!isMoreThan768px && isShowInboxes)
                ? ""
                : "none",
          }}
        >
          {t("Messenger.MessengerTitle")}
        </h1>
        <MessengerContainer>
          <Row style={{ height: "100%", display: "flex", flex: "1" }}>
            <Col
              xs={isShowInboxes ? 24 : 0}
              sm={isShowInboxes ? 24 : 0}
              md={10}
              lg={9}
              xl={8}
              style={{ height: "100%" }}
            >
              <ScrollableAreaLeft id="scrollableDivMessagesInboxes">
                <InfiniteScroll
                  dataLength={inboxStore.getInboxesAsArray().length}
                  next={() => {
                    inboxStore.setPage(inboxStore.page + 1);
                    inboxStore.fetchInboxes(inboxStore.page, 20);
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                  hasMore={
                    inboxStore.getInboxesAsArray().length < inboxStore.total
                  }
                  // loader={<h4>Loading...</h4>}
                  scrollableTarget="scrollableDivMessagesInboxes"
                >
                  {inboxStore.getInboxesAsArray().map((inbox) => {
                    return (
                      <InboxItem
                        inbox={inbox}
                        onClick={handleClickShowInboxes}
                      />
                    );
                  })}
                </InfiniteScroll>
              </ScrollableAreaLeft>
            </Col>
            <Col
              xs={isShowInboxes ? 0 : 24}
              sm={isShowInboxes ? 0 : 24}
              md={14}
              lg={15}
              xl={16}
              style={{ height: "100%" }}
            >
              <div
                style={{
                  display: isMoreThan768px ? "none" : "flex",
                  gap: "25px",
                  alignItems: "center",
                }}
                onClick={() => setIsShowInboxes(true)}
              >
                <BackArrow />
                <StyledText>
                  {messageStore.first_name_other_participant +
                    " " +
                    messageStore.last_name_other_participant}
                </StyledText>
              </div>
              <div style={{ height: "100%" }}>
                <RightColumn>
                  {messageStore.id_active_inbox !== -1 ? (
                    <>
                      <ScrollableAreaRight id="scrollableDivMessages">
                        <InfiniteScroll
                          dataLength={messageStore.getMessegesAsArray().length}
                          next={() => {
                            messageStore.setPage(messageStore.page + 1);
                            messageStore.fetchMessages(messageStore.page, 20);
                          }}
                          style={{
                            display: "flex",
                            flexDirection: "column-reverse",
                            gap: "10px",
                          }}
                          inverse={true}
                          hasMore={
                            messageStore.getMessegesAsArray().length <
                            messageStore.total
                          }
                          loader={""}
                          scrollableTarget="scrollableDivMessages"
                        >
                          {messageStore.getMessegesAsArray().map((message) => {
                            return <MessageItem message={message} />;
                          })}
                        </InfiniteScroll>
                      </ScrollableAreaRight>

                      <Form
                        form={form}
                        style={{ display: "flex", alignItems: "flex-end" }}
                      >
                        <Form.Item
                          name="message"
                          rules={[{ required: true, message: "" }]}
                          style={{ margin: 0, width: "100%" }}
                          validateTrigger="onSumbit"
                        >
                          <Input.TextArea
                            rows={4}
                            placeholder={t("Messenger.MessagePlaceholder")}
                            style={{ resize: "none" }}
                          ></Input.TextArea>
                        </Form.Item>
                        <Button
                          style={{
                            margin: "0 0 0 15px",
                          }}
                          disabled={isLoading}
                          type="primary"
                          onClick={handleClickButtonSend}
                        >
                          {t("Messenger.ButtonSubmit")}
                        </Button>
                      </Form>
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      {t("Messenger.SelectInboxText")}{" "}
                    </div>
                  )}
                </RightColumn>
              </div>
            </Col>
          </Row>
        </MessengerContainer>
      </Container>
    </>
  );
}

export default observer(Messenger);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const MessengerContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ScrollableArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: scroll;
  gap: 20px;
  display: flex;
  flex-direction: column;
`;

const ScrollableAreaLeft = styled(ScrollableArea)`
  padding-right: 18px;
  padding-top: 10px;
`;

const ScrollableAreaRight = styled(ScrollableArea)`
  padding-right: 18px;
  padding-left: 20px;
  margin: 0;
  flex-direction: column-reverse;
  margin-bottom: 145px;
  @media (max-width: 768px) {
    top: 40px;
  }
`;

const RightColumn = styled.div`
  padding-right: 18px;
  padding-left: 20px;
  padding-bottom: 25px;
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
`;

const StyledText = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */

  color: #000000;
`;
