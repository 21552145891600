import React from "react";
import styled, { css } from "styled-components";
import { Button as AntdButton } from "antd";
import { ButtonProps as AntdButtonProps } from "antd/lib/button";

interface CustomButtonProps extends AntdButtonProps {
  type?:
    | "primary"
    | "ghost"
    | "dashed"
    | "link"
    | "text"
    | "default"
    | "grey"
    | "succes"
    | "danger";
}

const StyledButton = styled(AntdButton)<CustomButtonProps>`
  ${({ type }) =>
    type === "grey" &&
    css`
      background: #efefef;
      color: #000000;

      &:hover,
      &:focus {
        background-color: #e6e6e6;
        color: #000000;
      }

      &:active {
        background-color: #e6e6e6;
        color: #000000;
      }
    `}

  ${({ type }) =>
    type === "succes" &&
    css`
      background: #1dcf64;
      color: #ffffff;

      &:hover,
      &:focus {
        background-color: #29b962;
        color: #ffffff;
      }

      &:active {
        background-color: #25aa5a;
        color: #ffffff;
      }
    `}
  
  ${({ type }) =>
    type === "danger" &&
    css`
      background: #e41861;
      color: #ffffff;

      &:hover,
      &:focus {
        background-color: #c71655;
        color: #ffffff;
      }

      &:active {
        background-color: #c71655;
        color: #ffffff;
      }
    `}
  
  white-space: pre-wrap;
`;

const CustomButton: React.FC<CustomButtonProps> = (props) => {
  return <StyledButton {...props} />;
};

export default CustomButton;
