import {
  Avatar,
  Col,
  Form,
  Row,
  Radio,
  Input,
  Button,
  notification,
  Alert,
} from "antd";
import useStore from "hooks/useStore";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Star } from "assets/images/starToVote.svg";
import CustomButton from "components/buttons/CustomButton";

import SpaceApiService from "services/SpaceService";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";

function StarRating({
  value,
  onChange,
}: {
  value: number;
  onChange: (value: number) => void;
}) {
  const handleClick = (rating: number) => {
    onChange(rating);
  };

  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <div
          onClick={() => handleClick(i)}
          style={{
            cursor: "pointer",
            display: "flex",
            width: "37px",
            height: "37px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Star key={i} className={i <= value ? "filled-star" : "empty-star"} />
        </div>
      );
    }
    return stars;
  };

  return <Row>{renderStars()}</Row>;
}

function AddComment(): JSX.Element | null {
  const { t } = useTranslation();
  const { auth } = useStore();
  const [form] = Form.useForm();
  const [rating, setRating] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { spacePseudo } = useParams<{ spacePseudo: string }>();
  const [isSend, setIsSend] = useState(false);

  const handleSubmit = async (e: any) => {
    setIsLoading(true);
    try {
      await SpaceApiService.postAddCommentSpace({
        space_pseudo: spacePseudo!,
        rating: form.getFieldValue("rating"),
        message: form.getFieldValue("review"),
      });
    } catch {
      notification.error({
        message: t("NotificationErrors.Error.Title"),
        description: t("NotificationErrors.Error.Description"),
        placement: "bottomRight",
      });
    } finally {
      setIsLoading(false);
      setIsSend(true);
    }
  };

  if (!auth.currentUser) {
    return <></>
  }
  else  if (auth.currentUser && !isSend) {
      return (
        <>
          <Col style={{ width: "100%" }}>
            <h5>{t("SpacePage.LeaveReview")}</h5>
            <Row style={{ marginBottom: "7px" }}>
              <Col>
                <Avatar
                  size={50}
                  src={auth.currentUser?.urlAvatar}
                  style={{ marginRight: "10px" }}
                >
                  {auth.currentUser
                    ? auth.currentUser.firstName.charAt(0) +
                      auth.currentUser.lastName.charAt(0)
                    : ""}
                </Avatar>
              </Col>
              <Col style={{ display: "flex", alignItems: "center" }}>
                {auth.currentUser?.firstName + " " + auth.currentUser?.lastName}
              </Col>
            </Row>
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
              <Row>
                <Form.Item
                  name="rating"
                  style={{ marginBottom: "15px" }}
                  rules={[
                    { required: true, message: t("ValidationErrors.Required") },
                  ]}
                >
                  <StarRating value={rating} onChange={setRating} />
                </Form.Item>
              </Row>
              <Row>
                <Form.Item
                  name="review"
                  style={{ width: "100%" }}
                  rules={[
                    { required: true, message: t("ValidationErrors.Required") },
                  ]}
                >
                  <Input.TextArea rows={6} style={{ width: "100%" }} />
                </Form.Item>
              </Row>
              <Row>
                <Form.Item>
                  <CustomButton
                    disabled={isLoading}
                    size="large"
                    type="succes"
                    htmlType="submit"
                  >
                    {t("Buttons.Send")}
                  </CustomButton>
                </Form.Item>
              </Row>
            </Form>
          </Col>
        </>
      );
    } else if (isSend)
      return (
        <>
          <h5>Leave a review</h5>
          <Alert
            message="Message sent!"
            description="Your message has been sent! It will be moderated and appear here soon. Thank you for your feedback!"
            type="success"
            showIcon
            style={{ width: "100%" }}
          />
        </>
      );
    else {
      return null;
    }
}

export default observer(AddComment);
