import React, { useState } from "react";
import { Upload, Button, message, Row, Col } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import { useNavigate } from "react-router-dom";
import partyImage from "assets/images/homePage/party.jpg";
import weddingImage from "assets/images/homePage/wedding.jpg";
import shootingImage from "assets/images/homePage/shooting.jpg";
import meetingImage from "assets/images/homePage/meeting.jpg";
import styled from "styled-components";

function BestSpacesSection(): JSX.Element {
  const { t, i18n } = useTranslation();
  const [fileList, setFileList] = useState([]);
  const navigate = useNavigate();

  return (
    <>
      <Row gutter={[20, 20]} style={{ paddingBottom: "20px" }}>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <StyledH2>{t("MainPage.BestSpacesTitle")}</StyledH2>
          <StyledDescription>
            {t("MainPage.BestSpacesDescription")}
          </StyledDescription>
        </Col>

        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <PartyCol>
            <StyledH3>{t("MainPage.PartySpaces")}</StyledH3>
            <GradientOverlay />
            {/* Badge here to be */}
          </PartyCol>
        </Col>
        <Col xs={24} sm={12} md={12} lg={10} xl={10}>
          <WeddingCol>
            <StyledH3>{t("MainPage.WeddingVenues")}</StyledH3>
            <GradientOverlay />
          </WeddingCol>
        </Col>
        <Col xs={24} sm={12} md={12} lg={10} xl={10}>
          <ShootingCol>
            <StyledH3>{t("MainPage.PhotoVideoStudios")}</StyledH3>
            <GradientOverlay />
          </ShootingCol>
        </Col>
        <Col xs={24} sm={12} md={12} lg={14} xl={14}>
          <MeetingCol>
            <StyledH3>{t("MainPage.ConferenceMeetingSpaces")}</StyledH3>
            <GradientOverlay />
          </MeetingCol>
        </Col>
      </Row>
      {/* <Row gutter={[20, 20]}> */}
      {/* </Row> */}
    </>
  );
}

export default BestSpacesSection;

const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.51) 0%,
    rgba(0, 0, 0, 0) 31.19%,
    rgba(0, 0, 0, 0) 65%,
    rgba(0, 0, 0, 0.47) 100%
  );
`;

const StyledImageContainer = styled.div`
  background-image: url(${partyImage});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 420px;
  position: relative;
  border-radius: 10px;
  padding: 27px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PartyCol = styled(StyledImageContainer)`
  background-image: url(${partyImage});
`;

const WeddingCol = styled(StyledImageContainer)`
  background-image: url(${weddingImage});
`;

const ShootingCol = styled(StyledImageContainer)`
  background-image: url(${shootingImage});
`;

const MeetingCol = styled(StyledImageContainer)`
  background-image: url(${meetingImage});
`;

const StyledH2 = styled.h2`
  font-family: "Ranade-Variable";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 113%;
  /* or 45px */

  letter-spacing: -0.04em;

  color: #000000;
  margin-top: 0px;
  margin-bottom: 30px;
`;

const StyledH3 = styled.h2`
  font-family: "Ranade-Variable";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 113%;
  /* or 25px */

  letter-spacing: -0.04em;

  color: #ffffff;

  z-index: 2;
  margin: 0;
`;

const StyledDescription = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  /* or 27px */

  color: #484848;
`;
