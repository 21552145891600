/** Enum for storing space states. */
export enum spaceState {
  // CREATED = "CREATED",
  WAITING_FOR_VALIDATION = "WAITING_FOR_VALIDATION",
  PUBLISHED = "PUBLISHED",
  UNPUBLISHED = "UNPUBLISHED"
}
Object.freeze(spaceState);

const enumKeys = Object.keys(spaceState);

/** Get array of objects with key and value. Used for select dropdown */
export const mappedArraySpaceState = enumKeys.map((key) => {
    const value = spaceState[key as keyof typeof spaceState];
    return { key, value };
});