import { Instance, types } from 'mobx-state-tree'
import SpaceService, { ISpacePageInfo } from "services/SpaceService";

export const SpaceItem = types
  .model({
    id: types.number,
    pseudo: types.string,
    zip_code: types.string,
    city: types.string,
    name: types.string,
    metro: types.string,
    min_price: types.number,
    geo_lat: types.number,
    geo_long: types.number,
    url_cover_image: types.string,
    is_favorite: types.boolean,
  })
  .actions((self) => ({
    setAttributeIsFavorite: (isFavorite: boolean) => {
      self.is_favorite = isFavorite
    },
  }))
  .actions((self) => ({
    changeIsFavorite: () => {
      if (!self.is_favorite) {
        SpaceService.postAddFavoriteSpace({ pseudo_space: self.pseudo }
        ).then(() => { self.setAttributeIsFavorite(true) })
      }
      else if (self.is_favorite) {
        SpaceService.deleteDeleteFavoriteSpace({ pseudo_space: self.pseudo }
        ).then(() => { self.setAttributeIsFavorite(false) })
      }
    },
  }))

export type ISpaceItem = Instance<typeof SpaceItem>