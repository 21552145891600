import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import menuAdmin from "pages/spaceAdmin/commonComponents/menuAdmin";
import ProfileLayout from "components/profileLayout/ProfileLayout";

import AddModifySpace from "components/commonPages/addModifySpace/AddModifySpace";
import { useParams } from "react-router-dom";

/** Modify Space Manager page. */
function ModifySpaceAdmin(): JSX.Element {
  const { t, i18n } = useTranslation();
  const { spacePseudo } = useParams<{ spacePseudo: string }>();

  return (
    <>
      <ProfileLayout menu={menuAdmin(i18n.language, t)}>
        <>
          <AddModifySpace isCreation={false} spacePseudo={spacePseudo} />
        </>
      </ProfileLayout>
    </>
  );
}

export default observer(ModifySpaceAdmin);
