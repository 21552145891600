import { flow, Instance, types } from 'mobx-state-tree'
import { observable } from 'mobx'
import { enumUserRole } from 'constants/User'


export const User = types
  .model({
    email: types.string,
    firstName: types.string,
    lastName: types.string,
    tel: types.string,
    role: types.enumeration<enumUserRole>(Object.values(enumUserRole)),
    urlAvatar: types.maybeNull(types.string) 
  })
  .actions((self) => ({
    changeEmail: (email: string) => {
      self.email = email
      },
    setUrlAvatar: (urlAvatar: string | null) => {
      self.urlAvatar = urlAvatar
    },
  }))

export type IUser = Instance<typeof User>