import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import menuManager from "../commonComponents/menuManager";
import NotificationsComponent from "components/myProfile/NotificationsComponent";

/** Notifications Manager page. */
function ManagerNotifications(): JSX.Element {
  const { t, i18n } = useTranslation();

  return (
    <>
      <NotificationsComponent
        menu={menuManager(i18n.language, t)}
      ></NotificationsComponent>
    </>
  );
}

export default observer(ManagerNotifications);
