import React from "react";
import { Route, Routes } from "react-router-dom";
import { RouterPath } from "constants/RouterPath";
import MainLayout from "layouts/MainLayout";
import HomePage from "pages/homePage/HomePage";
import MainSearch from "pages/mainSearch/MainSearch";
import SpacePage from "pages/space/SpacePage";
import Messanger from "pages/messenger/Messenger";
import Favorites from "pages/catalog/favorites/Favorites";
import SignupSuccess from "pages/auth/signupSuccess/SignupSuccess";
import Partnership from "pages/infoPages/partnership/Partnership";
import CustomerMyProfile from "pages/spaceCustomer/MyProfile/CustomerMyProfile";
import ConfirmEmail from "pages/auth/confirmEmail/ConfirmEmail";
import ForgotPassword from "pages/auth/forgotPassword/ForgotPassword";
import ForgotPasswordMailSent from "pages/auth/forgotPasswordMailSent/ForgotPasswordMailSent";
import ResetPassword from "pages/auth/resetPassword/ResetPassword";
import PasswordChanged from "pages/auth/passwordChanged/PasswordChanged";
import PasswordNotChanged from "pages/auth/passwordNotChanged/PasswordNotChanged";
import AdminBestSpaces from "pages/spaceAdmin/bestSpaces/BestSpaces";
import MySpacesList from "pages/spaceManager/profile/mySpacesList/MySpacesList";
import ManagerProfile from "pages/spaceManager/profile/myProfile/ManagerProfile";
import HomePageRedirect from "pages/homePage/HomePageRedirect";
import AddSpaceManager from "pages/spaceManager/profile/addSpace/AddSpace";
import ModifySpaceManager from "pages/spaceManager/profile/modifySpace/ModifySpaceManager";
import MainFlexLayout from "layouts/MainFlexLayout";
import MainPageLayout from "layouts/MainPageLayout";
import SearchLayout from "layouts/SearchLayout";
import AdminProfile from "pages/spaceAdmin/myProfile/AdminProfile";
import SpacesAdmin from "pages/spaceAdmin/spaces/SpacesAdmin";
import ModifySpaceAdmin from "pages/spaceAdmin/modifySpace/ModifySpaceAdmin";
import PrivacyPolicy from "pages/infoPages/privacyPolicy/PrivacyPolicy";
import TermsAndConditions from "pages/infoPages/termsAndConditions/TermsAndConditions";
import CustomerAccountSettings from "pages/spaceCustomer/AccountSettings/CustomerAccountSettings";
import CustomerNotifications from "pages/spaceCustomer/Notifications/CustomerNotifications";
import ManagerAccountSettings from "pages/spaceManager/profile/AccountSettings/ManagerAccountSettings";
import ManagerNotifications from "pages/spaceManager/profile/Notifications/ManagerNotifications";
import RouteGuard from "./routerTools/RouteGuard";
import { enumUserRole } from "constants/User";
import ValidateLang from "./routerTools/ValidateLang";
import LinkNotValid from "pages/technicalRoutes/linkNotValid/LinkNotValid";
import { ScrollToTop } from "./routerTools/ScrollToTop";
import UserListAdmin from "pages/spaceAdmin/users/userList/UserListAdmin";
import SignupPage from "pages/auth/signupPage/SignupPage";
import PageNotFoundRedirect from "pages/technicalRoutes/pageNotFoundRedirect/PageNotFoundRedirect";
import UserPageAdmin from "pages/spaceAdmin/users/UserPage/UserPageAdmin";
import NotAuthenticated from "pages/technicalRoutes/notAuthenticated/NotAuthenticated";

/** Application routing */
function AppRoutes() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        {/* NOT PROTECTED ROUTES */}
        {/* <Route element={<FluidLayout />}>
        </Route> */}
        <Route element={<MainPageLayout />}>
          <Route
            path={RouterPath.HOME_WITHOUT_PREFIX}
            element={<HomePageRedirect />}
          />
          <Route element={<ValidateLang />}>
            <Route path={RouterPath.HOME} element={<HomePage />} />
          </Route>
        </Route>
        {/* Routes with lang :lang validation */}
        <Route element={<ValidateLang />}>
          <Route element={<SearchLayout />}>
            <Route path={RouterPath.SPACES} element={<MainSearch />} />
          </Route>

          <Route element={<MainFlexLayout />}>
            <Route path={RouterPath.MESSENGER} element={<Messanger />} />
          </Route>
          <Route element={<MainLayout />}>
            <Route
              path={RouterPath.SPACE + ":spacePseudo"}
              element={<SpacePage />}
            />
            <Route path={RouterPath.FAVORITES} element={<Favorites />} />
            <Route
              path={RouterPath.PRIVACY_POLICY}
              element={<PrivacyPolicy />}
            />
            <Route
              path={RouterPath.TERMS_AND_CONDITIONS}
              element={<TermsAndConditions />}
            />
            <Route path={RouterPath.PARTNERSHIP} element={<Partnership />} />
            {/* TECHNICAL ROUTES */}
            <Route
              path={RouterPath.LINK_NOT_VALID}
              element={<LinkNotValid />}
            />
            <Route
              path={RouterPath.LINK_NOT_AUTHENTICATED}
              element={<NotAuthenticated />}
            />
            {/* AUTHENTIFICATION ROUTES */}
            <Route path={RouterPath.SIGNUP} element={<SignupPage />} />
            <Route
              path={RouterPath.FORGOT_PASSWORD}
              element={<ForgotPassword />}
            />
            <Route
              path={RouterPath.FORGOT_PASSWORD_MAIL_SENT}
              element={<ForgotPasswordMailSent />}
            />
            <Route
              path={RouterPath.SIGNUP_SUCCESS}
              element={<SignupSuccess />}
            />
            <Route
              path={RouterPath.RESET_PASSWORD}
              element={<ResetPassword />}
            />
            <Route
              path={RouterPath.PASSWORD_CHANGED}
              element={<PasswordChanged />}
            />
            <Route
              path={RouterPath.PASSWORD_NOT_CHANGED}
              element={<PasswordNotChanged />}
            />
            <Route
              path={RouterPath.SIGNUP_CONFIRM_EMAIL}
              element={<ConfirmEmail />}
            />
            {/* ADMIN PROTECTED ROUTES */}
            <Route element={<RouteGuard userRole={enumUserRole.ADMIN} />}>
              <Route
                path={RouterPath.ADMIN_MY_PROFILE}
                element={<AdminProfile />}
              />
              <Route
                path={RouterPath.ADMIN_SPACES_ALL}
                element={<SpacesAdmin />}
              />
              <Route
                path={RouterPath.ADMIN_SPACES_PUBLISHED}
                element={<SpacesAdmin />}
              />
              <Route
                path={RouterPath.ADMIN_SPACES_NOT_PUBLISHED}
                element={<SpacesAdmin />}
              />
              <Route
                path={RouterPath.ADMIN_SPACES_WAITING_FOR_VALIDATION}
                element={<SpacesAdmin />}
              />
              <Route
                path={RouterPath.ADMIN_SPACES_WAITING_FOR_VALIDATION}
                element={<SpacesAdmin />}
              />
              <Route
                path={RouterPath.ADMIN_MODIFY_SPACE + "/:spacePseudo"}
                element={<ModifySpaceAdmin />}
              />
              <Route
                path={RouterPath.ADMIN_BEST_SPACES}
                element={<AdminBestSpaces />}
              />
              <Route
                path={RouterPath.ADMIN_USERS_LIST}
                element={<UserListAdmin />}
              />
              <Route
                path={RouterPath.ADMIN_USER_PAGE + "/:uuidUser"}
                element={<UserPageAdmin />}
              />
            </Route>
            {/* CUSTOMER PROTECTED ROUTES */}
            <Route element={<RouteGuard userRole={enumUserRole.CLIENT} />}>
              <Route
                path={RouterPath.CUST_MY_PROFILE}
                element={<CustomerMyProfile />}
              />
              <Route
                path={RouterPath.CUST_ACCOUNT_SETTINGS}
                element={<CustomerAccountSettings />}
              />
              <Route
                path={RouterPath.CUST_NOTIFICATIONS}
                element={<CustomerNotifications />}
              />
            </Route>

            {/* MANAGER PROTECTED ROUTES */}
            <Route element={<RouteGuard userRole={enumUserRole.MANAGER} />}>
              <Route
                path={RouterPath.MANAGER_MY_PROFILE}
                element={<ManagerProfile />}
              />
              <Route
                path={RouterPath.MANAGER_ACCOUNT_SETTINGS}
                element={<ManagerAccountSettings />}
              />
              <Route
                path={RouterPath.MANAGER_NOTIFICATIONS}
                element={<ManagerNotifications />}
              />
              <Route
                path={RouterPath.MANAGER_MY_SPACES}
                element={<MySpacesList />}
              />
              <Route
                path={RouterPath.MANAGER_MY_SPACES_ACTIVE}
                element={<MySpacesList />}
              />
              <Route
                path={RouterPath.MANAGER_MY_SPACES_INACTIVE}
                element={<MySpacesList />}
              />
              <Route
                path={RouterPath.MANAGER_MY_SPACES_WAITING_FOR_VALIDATION}
                element={<MySpacesList />}
              />
              <Route
                path={RouterPath.MANAGER_ADD_SPACE}
                element={<AddSpaceManager />}
              />
              <Route
                path={RouterPath.MANAGER_MODIFY_SPACE + "/:spacePseudo"}
                element={<ModifySpaceManager />}
              />
            </Route>
          </Route>
        </Route>
        {/* Not found redirect component */}
        <Route element={<ValidateLang />}>
          {/* <Route element={<MainLayout />}> */}
          <Route path="*" element={<PageNotFoundRedirect />} />
          {/* </Route> */}
        </Route>{" "}
      </Routes>
    </>
  );
}

export default AppRoutes;
