import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Input, Button, Row, Col } from "antd";
import { useParams } from "react-router";
import DataService from "services/AuthService";
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import styled from "styled-components";
import CustomButton from "components/buttons/CustomButton";

/** Page to set a new password, user come to this page using the link sent to the mail.
 */
export default function ResetPassword(): JSX.Element {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [newPassword, setNewPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  let token = searchParams.get("token");

  useEffect(() => {
    if (!token) {
      navigate(routerPathLocal(i18n.language, RouterPath.LINK_NOT_VALID));
    }
  }, [i18n.language, navigate, token]);

  const onFinish = async (values: any) => {
    setLoading(true);

    const data = {
      token: token || "",
      new_password: values.password,
    };
    try {
      await DataService.postSetNewPasswordForgotPassword(data);
      navigate(routerPathLocal(i18n.language, RouterPath.PASSWORD_CHANGED));
    } catch (error) {
      navigate(routerPathLocal(i18n.language, RouterPath.PASSWORD_NOT_CHANGED));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row style={{ height: "100%", width: "100%", justifyContent: "center" }}>
      <Col
        xs={24}
        sm={20}
        md={16}
        lg={12}
        xl={8}
        style={{ paddingTop: "50px" }}
      >
        <StyledH2>{t("ForgotPasswordSetNewPassword.Title")}</StyledH2>
        <Form
          form={form}
          layout="vertical"
          onFinish={() => {
            form.validateFields().then((values) => onFinish(values));
          }}
        >
          <p>{t("ForgotPasswordSetNewPassword.Text")}</p>
          <Form.Item
            label={t("ForgotPasswordSetNewPassword.PasswordLabel")}
            name="password"
            rules={[
              {
                required: true,
                message: t("ValidationErrors.Required"),
                type: "string",
                validateTrigger: "onFinish",
              },
              {
                message: t("ValidationErrors.PasswordFormat"),
                type: "string",
                min: 8,
                validateTrigger: "onFinish",
              },
            ]}
          >
            <Input.Password
              // placeholder={t("password_reset.new_password_placeholder")}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <CustomButton
              style={{ width: "100%" }}
              type="primary"
              size="large"
              htmlType="submit"
              loading={loading}
            >
              {t("ForgotPasswordSetNewPassword.Button")}
            </CustomButton>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}


const StyledH2 = styled.h2`
  font-family: "Ranade-Variable";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 113%;
  /* or 45px */

  letter-spacing: -0.04em;

  color: #000000;
  margin-top: 0px;
  margin-bottom: 30px;
`;
