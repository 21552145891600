import { Instance, types } from 'mobx-state-tree'
import { OpeningHours } from './OpeningHours'
import { ImageFile } from './ImageFile'
import ApiService, { ISpacePageInfo } from "services/SpaceService";

export const SpaceModel = types
  .model({
    id_country: types.number,
    id_region: types.number,
    zip_code: types.string,
    name: types.string,
    pseudo: types.string,
    type: types.string,
    is_favorite: types.boolean,
    state: types.string,
    metro: types.string,
    address: types.string,
    additional_info_address: types.string,
    seat_number: types.number,
    standing_number: types.number,
    floor_area: types.number,
    // height: types.number,
    city: types.string,
    description: types.string,
    terms: types.string,
    opening_hours: types.array(OpeningHours),
    images: types.array(ImageFile),
    ids_facilities: types.array(types.number),
    ids_events: types.array(types.number),
    min_booking_time: types.number,
    average_rating: types.maybeNull(types.number),
    comment_number: types.number,
    id_user_manager: types.number,
    first_name_manager: types.string,
    last_name_manager: types.string,
    manager_avatar_url: types.string,
    geo_lat: types.number,
    geo_long: types.number,
  })
  .views((self) => ({
    getFormattedRating: () => String(self.average_rating).slice(0,3).replace(".", ",")
  }))
  .views((self) => ({
    getImagesAsArray: () => self.images.slice()
  }))
  .actions((self) => ({
    setAttributeIsFavorite: (isFavorite: boolean) => {
      self.is_favorite = isFavorite
    },
  }))
  .actions((self) => ({
    setIsFavorite: (isFavorite: boolean) => {
      if (isFavorite && !self.is_favorite) {
        ApiService.postAddFavoriteSpace({ pseudo_space: self.pseudo }
        ).then(() => { self.setAttributeIsFavorite(true) })
      }
      else if (!isFavorite && self.is_favorite) {
        ApiService.deleteDeleteFavoriteSpace({ pseudo_space: self.pseudo }
        ).then(() => { self.setAttributeIsFavorite(false) })
      }
    },
  }))

export type ISpaceModel = Instance<typeof SpaceModel>