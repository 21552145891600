import { applySnapshot, flow, Instance, types } from 'mobx-state-tree'
import { User, IUser } from 'states/models/User/UserModel'
import InfoService, { ICountriesAPIResponse, IEventsAPIResponse, IFacilitiesAPIResponse, IRegionCityGeoAPIResponse, IRegionsAPIResponse } from "services/InfoService";
import { requestStatus } from "constants/reqestStatus"
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import { Country, ICountry,  } from 'states/models/Info/Country';
import { Event } from 'states/models/Info/Event';
import { Facility } from 'states/models/Info/Facilities';
import { Region } from 'states/models/Info/Region';
import { RegionCityGeo } from 'states/models/Info/RegionCityGeo';


interface FacilitiesByType {
  [type: string]: { type: string; id: string; name: string }[];
}

// TODO remove it, do only one time during loading of facilities in store
const groupFacilitiesByType = (
  facilities: { type: string; id: string; name: string }[]
): FacilitiesByType => {
  return facilities.reduce((acc: FacilitiesByType, facility) => {
    if (!acc[facility.type]) {
      acc[facility.type] = [];
    }
    acc[facility.type].push(facility);
    return acc;
  }, {});
};


export const InfoStore = types
  .model({
    countries: types.optional(types.map(Country), {}),
    regions: types.optional(types.map(Region), {}),
    regionCityGeo: types.optional(types.map(RegionCityGeo), {}),
    events: types.optional(types.map(Event), {}),
    facilities: types.optional(types.map(Facility), {}),
    status: types.optional(
      types.enumeration<requestStatus>(Object.values(requestStatus)), requestStatus.INITIAL),

  })
  .views((self) => ({
    getCountriesAsArray: () => Array.from(self.countries.values())
  }))
  .views((self) => ({
    getRegionsAsArray: () => Array.from(self.regions.values())
  }))
  .views((self) => ({
    getRegionCityGeoAsArray: () => Array.from(self.regionCityGeo.values())
  }))
  .views((self) => ({
    getEventsAsArray: () => Array.from(self.events.values())
  }))
  .views((self) => ({
    getEventsIdsAsArray: () => self.getEventsAsArray().map(item => item.id)
  }))
  .views((self) => ({
    getFacilitiesAsArray: () => Array.from(self.facilities.values())
  }))
  .views((self) => ({
    getGroupedFacilitiesByType: () => groupFacilitiesByType(self.getFacilitiesAsArray())
  }))  
  .actions((self) => ({
    updateCountries: (
      countries: Array<ICountriesAPIResponse>
    ) => {
      applySnapshot(self.countries, {})
      countries.forEach((country) => {
        self.countries.set(
          country.id.toString(),
          {
            id: country.id.toString(),
            name: country.name,
          }
        )
      })
    }
  }))
  .actions((self) => ({
    updateRegions: (
      regions: Array<IRegionsAPIResponse>
    ) => {
      applySnapshot(self.regions, {})
      regions.forEach((region) => {
        self.regions.set(
          region.id.toString(),
          {
            id: region.id.toString(),
            name: region.name,
          }
        )
      })
    }
  }))
  .actions((self) => ({
    updateRegionCityGeo: (
      regionCityGeoList: Array<IRegionCityGeoAPIResponse>
    ) => {
      applySnapshot(self.regionCityGeo, {})
      regionCityGeoList.forEach((regionCityGeo) => {
        self.regionCityGeo.set(
          regionCityGeo.uuid,
          {
            uuid: regionCityGeo.uuid,
            name: regionCityGeo.name,
            geo_lat: regionCityGeo.geo_lat,
            geo_long: regionCityGeo.geo_long,
          }
        )
      })
    }
  }))
  .actions((self) => ({
    updateEvents: (
      events: Array<IEventsAPIResponse>
    ) => {
      applySnapshot(self.events, {})
      events.forEach((event) => {
        self.events.set(
          event.id.toString(),
          {
            id: event.id.toString(),
            name: event.name,
          }
        )
      })
    }
  }))
  .actions((self) => ({
    updateFacilities: (
      facilities: Array<IFacilitiesAPIResponse>
    ) => {
      applySnapshot(self.facilities, {})
      facilities.forEach((facility) => {
        self.facilities.set(
          facility.id.toString(),
          {
            id: facility.id.toString(),
            type: facility.type,
            name: facility.name,
            icon: facility.icon,
          }
        )
      })
    }
  }))
  .actions((self) => ({
    fetchCountries: flow(function* fetchCountries() {
      self.status = requestStatus.PENDING;
      try {
        const data = yield InfoService.getCountries();
        self.updateCountries(data.data);
        self.status = requestStatus.SUCCESS
      } catch
      {
        self.status = requestStatus.ERROR;
      }
    })
  }))
  .actions((self) => ({
    fetchRegions: flow(function* fetchRegions(lang: string, id_country?: number) {
      // self.status = requestStatus.PENDING;
      try {
        const data = yield InfoService.getRegions({ lang: lang, id_country: id_country});
        self.updateRegions(data.data);
      } catch
      {
        // self.status = requestStatus.ERROR;
      }
    })
  }))
  .actions((self) => ({
    fetchRegionCityGeo: flow(function* fetchRegionCityGeo(lang: string, id_country?: number) {
      // self.status = requestStatus.PENDING;
      try {
        const data = yield InfoService.getRegionCityGeo({ lang: lang, id_country: id_country});
        self.updateRegionCityGeo(data.data);
      } catch
      {
        // self.status = requestStatus.ERROR;
      }
    })
  }))
  .actions((self) => ({
    fetchEvents: flow(function* fetchEvents(lang: string) {
      // self.status = requestStatus.PENDING;
      try {
        const data = yield InfoService.getEvents({ lang: lang });
        self.updateEvents(data.data);
        // self.status = requestStatus.SUCCESS
      } catch
      {
        // self.status = requestStatus.ERROR;
      }
    })
  }))
  .actions((self) => ({
      fetchFacilities: flow(function* fetchFacilities(lang: string) {
      // self.status = requestStatus.PENDING;
      try {
        const data = yield InfoService.getFacilities({ lang: lang });
        self.updateFacilities(data.data);
        // self.status = requestStatus.SUCCESS
      } catch
      {
        // self.status = requestStatus.ERROR;
      }
    })
  }))
  .actions((self) => ({
    getRegionCityGeo: (
      lang: string, id_country?: number
    ) => {
      // if (self.regionCityGeo.size === 0) {
        self.fetchRegionCityGeo(lang, id_country);
      // }
    }
  }))
  

export type IInfoStore = Instance<typeof InfoStore>