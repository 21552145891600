import React from "react";
import { Breadcrumb, Layout, Menu, theme } from "antd";
import { Outlet } from "react-router-dom";
import CustomHeader from "components/header/CustomHeader";
import styled from "styled-components";

const { Header, Content, Footer } = Layout;

function MainPageLayout(): JSX.Element {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <div
        style={{
          background: "#F9F9F9",
        }}
      >
        <CustomHeader />
        <StyledContent
        >
          <div style={{ maxWidth: "1300px" }}>
            <Outlet />
          </div>
        </StyledContent>
      </div>
    </Layout>
  );
}

export default MainPageLayout;

const StyledContent = styled(Content)`
  padding: 20px 70px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1100px) {
    padding: 20px 30px 0px;
  }

  @media (max-width: 800px) {
    padding: 20px 12px 0px;
  }
`;
