import { Carousel, Col, Modal, Row } from "antd";
import useStore from "hooks/useStore";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";

function ImagesSpacePage() {
  const { t, i18n } = useTranslation();
  const {
    spaceStore: { currentSpace },
    spaceStore,
  } = useStore();
  const [showSlider, setShowSlider] = useState(false);
  const carouselRef = useRef(null);

  if (!spaceStore.currentSpace?.images) return null;

  const handleClick = () => {
    setShowSlider(true);
  };

  const handleClose = () => {
    setShowSlider(false);
  };

  const handleLeftClick = (event: any) => {
    event.stopPropagation();
    carouselRef.current.prev();
  };

  const handleRightClick = (event: any) => {
    event.stopPropagation();
    carouselRef.current.next();
  };
  return (
    <>
      <ImagesContainer style={{ margin: "-5px -5px 0px -5px" }}>
        <div style={{ height: "100%", width: "60%" }}>
          <NewImageWrapper
            key={currentSpace!.images[0].id_image_file}
            className=""
            onClick={handleClick}
          >
            <img src={currentSpace!.images[0].url} alt="" />
          </NewImageWrapper>
        </div>
        <div style={{ height: "60%", width: "40%" }}>
          <div>
            <NewImageWrapper
              key={currentSpace!.images[1].id_image_file}
              className=""
              onClick={handleClick}
            >
              <img src={currentSpace!.images[1].url} alt="" />
            </NewImageWrapper>
          </div>
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div style={{ height: "100%", width: "100%" }}>
              <NewImageWrapper
                key={currentSpace!.images[2].id_image_file}
                className=""
                onClick={handleClick}
              >
                <img src={currentSpace!.images[2].url} alt="" />
              </NewImageWrapper>
            </div>
            <div style={{ height: "100%", width: "100%" }}>
              <NewImageWrapper
                key={currentSpace!.images[3].id_image_file}
                className="overlay"
                onClick={handleClick}
              >
                <img src={currentSpace!.images[3].url} alt="" />
                {currentSpace!.images.length > 4 && <PlusIcon />}
              </NewImageWrapper>
            </div>
          </div>
        </div>
      </ImagesContainer>
      <Modal
        open={showSlider}
        onCancel={handleClose}
        footer={null}
        width="800px"
        bodyStyle={{ padding: 0, maxHeight: "calc(100vh - 160px)" }}
      >
        <Carousel ref={carouselRef}>
          {currentSpace!.images.map((image) => (
            <CarouselImageContainer key={image.id_image_file}>
              <CarouselClickArea className="left" onClick={handleLeftClick} />
              <CarouselImage src={image.url} alt={`Space image`} />
              <CarouselClickArea className="right" onClick={handleRightClick} />
            </CarouselImageContainer>
          ))}
        </Carousel>
      </Modal>
    </>
  );
}

export default observer(ImagesSpacePage);

const ImagesContainer = styled.div`
  display: flex;
`;

const NewImageWrapper = styled.div`
  position: relative;
  cursor: pointer;
  overflow: hidden;
  padding-bottom: 100%;
  width: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    padding: 5px;
  }

  &.overlay::before {
    content: "";
    position: absolute;
    border-radius: 10px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
  }
`;

const PlusIcon = styled(PlusOutlined)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 20px;
`;

const CarouselImageContainer = styled.div`
  position: relative;
`;

const CarouselClickArea = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  z-index: 1;
  cursor: pointer;

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }
`;

const CarouselImage = styled.img`
  max-width: 100%;
  max-height: calc(100vh - 30px - 50px - 50px);
  object-fit: contain;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  display: flex !important;
  align-content: center;
  justify-content: center;
  width: 100%;
`;
