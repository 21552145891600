import { RouterPath, routerPathLocal } from "constants/RouterPath";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

/** Page "Page not found" */
export default function PageNotFoundRedirect(): JSX.Element {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    navigate(routerPathLocal(i18n.language, RouterPath.LINK_NOT_VALID));
  }, []);

  return <></>;
}
