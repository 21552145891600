import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
// import MyProfileComponent from "components/myProfile/MyProfileComponent";
import menuCustomer from "../commonComponents/menuCustomer";
import AccountSettingsComponent from "components/myProfile/AccountSettingsComponent";

/** My profile Customer page. */
function CustomerAccountSettings(): JSX.Element {
  const { t, i18n } = useTranslation();

  return (
    <>
      <AccountSettingsComponent
        menu={menuCustomer(i18n.language, t)}
      ></AccountSettingsComponent>
    </>
  );
}

export default observer(CustomerAccountSettings);
