import { applySnapshot, flow, Instance, types } from 'mobx-state-tree'
import { requestStatus } from "constants/reqestStatus"
import MessengerService, { IInboxesItemAPIResponse } from 'services/MessengerService';
import { SpaceItem } from 'states/models/SpaceList/SpaceItem';
import { IGetSpacesItemResponse, IGetSpacesListAsAdminParams, IGetSpacesResponse } from 'services/SpaceService';
import SpaceService, { ISpacePageInfo } from "services/SpaceService";

export interface IFetchSpaceList {
  lang?: string;
  page: number;
  size: number;
  is_only_favorite?: boolean;
  is_with_image?: boolean;
  northeast_lat?: number;
  northeast_long?: number;
  southwest_lat?: number;
  southwest_long?: number;
  space_type?: string;
  id_event?: number;
  seat_number?: number;
  standing_number?: number;
  date?: string;
}


export const SpaceListStore = types
  .model({
    spaceList: types.optional(types.map(SpaceItem), {}),
    total: types.optional(types.number, -1),
    page: types.optional(types.number, -1),
    size: types.optional(types.number, -1),
    // status: types.optional(
    //   types.enumeration<requestStatus>(Object.values(requestStatus)), requestStatus.INITIAL),

  })
  .views((self) => ({
    getSpaceListAsArray: () => Array.from(self.spaceList.values())
  }))
  .actions((self) => ({
    clearSpaceList: (
    ) => {
      applySnapshot(self.spaceList, {})
    }
  }))
  .actions((self) => ({
    setPage: (page: number
    ) => {
      self.page=page
    }
  }))
  .actions((self) => ({
    setSpaceIsFavorite: (spaceId: string, isFavorite: boolean
    ) => {
      const space = self.spaceList.get(spaceId)
      space?.setAttributeIsFavorite(isFavorite)
    }
  }))
  .actions((self) => ({
    updateSpaceList: (
      spaceList: Array<IGetSpacesItemResponse>
    ) => {
      spaceList.forEach((spaceItem, index) => {
        self.spaceList.set(
          spaceItem.id.toString(),
          {
            id: spaceItem.id,
            name: spaceItem.name,
            zip_code: spaceItem.zip_code,
            city: spaceItem.city,
            pseudo: spaceItem.pseudo,
            metro: spaceItem.metro,
            min_price: spaceItem.min_price,
            geo_lat: spaceItem.geo_lat,
            geo_long: spaceItem.geo_long,
            is_favorite: spaceItem.is_favorite,
            url_cover_image: spaceItem.url_cover_image,
          }
        )
      })
    }
  }))
  .actions((self) => ({
    fetchSpaceList: flow(function* fetchSpaceList(
      { lang,
        date,
        standing_number,
        seat_number,
        id_event,
        space_type,
        page,
        size,
        northeast_lat,
        northeast_long,
        southwest_lat,
        southwest_long,
        is_with_image = true,
        is_only_favorite = false }: IFetchSpaceList
    ) {
      // self.status = requestStatus.PENDING;
      try {
        const data = yield SpaceService.getListSpaces({
          lang: lang,
          date: date,
          standing_number: standing_number,
          seat_number: seat_number,
          id_event: id_event,
          space_type: space_type,
          page: page,
          size: size,
          is_only_favorite: is_only_favorite,
          is_with_image: is_with_image,
          northeast_lat: northeast_lat,
          northeast_long: northeast_long,
          southwest_lat: southwest_lat,
          southwest_long: southwest_long,
        });
        self.updateSpaceList(data.data.items);
        self.total = data.data.total
        self.size = data.data.size
        // self.status = requestStatus.SUCCESS
      } catch
      {
        // self.status = requestStatus.ERROR;
      }
    })
  }))
  .actions((self) => ({
    fetchSpaceListAsAdmin: flow(function* fetchSpaceList(
      { 
        lang,
        page,
        size,
        name,
        state}: IGetSpacesListAsAdminParams
    ) {
      // self.status = requestStatus.PENDING;
      try {
        const data = yield SpaceService.getListSpacesAsAdmin({
          lang: lang,
          page: page,
          size: size,
          name: name,
          state: state,
        });
        self.updateSpaceList(data.data.items);
        self.total = data.data.total
        self.size = data.data.size
        // self.status = requestStatus.SUCCESS
      } catch
      {
        // self.status = requestStatus.ERROR;
      }
    })
  }))


export type ISpaceListStore = Instance<typeof SpaceListStore>