import { Avatar, Button, Col, Modal, Row, Space } from "antd";
import useStore from "hooks/useStore";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as MinBookingTimeIcon } from "assets/images/minBookingTime.svg";
import { ReactComponent as SittingIcon } from "assets/images/sittingNumber.svg";
import { ReactComponent as StandingIcon } from "assets/images/standingNumber.svg";
import { ReactComponent as FloorAreaIcon } from "assets/images/floorArea.svg";
import { ReactComponent as Heart } from "assets/images/heart.svg";
import { ReactComponent as Star } from "assets/images/star.svg";
import CustomButton from "components/buttons/CustomButton";
import ModalLogin from "components/modals/modalLogin/ModalLogin";
import ModalMakeRequest from "components/modals/modalMakeRequest/ModalMakeRequest";

function MainInfoSpace() {
  const { t, i18n } = useTranslation();
  const { spacePseudo } = useParams<{ spacePseudo: string }>();
  const [isOpenModalMakeRequest, setIsOpenModalMakeRequest] = useState(false);
  const [isOpenModalLogin, setOpenModalLogin] = useState(false);
  const {
    spaceStore: { currentSpace },
    spaceStore,
    auth,
  } = useStore();
  const { infoStore } = useStore();

  useEffect(() => {
    // infoStore.fetchCountries();
    spaceStore.getAndSetCurrentSpace(spacePseudo!, i18n.language);
  }, [i18n.language, spacePseudo, spaceStore]);

  useEffect(() => {
    infoStore.fetchFacilities(i18n.language);
  }, [i18n.language, infoStore]);

  const handleFavoriteButtonClick = () => {
    if (auth.currentUser) {
      if (currentSpace?.is_favorite === true) {
        currentSpace.setIsFavorite(false);
      } else if (currentSpace?.is_favorite === false) {
        currentSpace.setIsFavorite(true);
      }
    } else {
      setOpenModalLogin(true);
    }
  };

  const handleMakeRequestButtonClick = () => {
    if (auth.currentUser) {
      setIsOpenModalMakeRequest(true);
    } else {
      setOpenModalLogin(true);
    }
  };

  return (
    <>
      <Row
        style={{
          marginBottom: "13px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "5px",
          }}
        >
          <StyledStar />
        </Col>
        <span style={{ color: "#1F1F1F", fontSize: "15px" }}>
          {currentSpace?.comment_number ? (
            <>
              <span style={{ fontWeight: "600" }}>
                {currentSpace?.getFormattedRating()}{" "}
              </span>
              - {currentSpace?.comment_number} {t("SpacePage.Reviews")}
            </>
          ) : (
            <>{t("SpacePage.NoReviews")}</>
          )}
        </span>
      </Row>
      <Row>
        <Col>
          <div className="page-title" style={{ marginBottom: "5px" }}>
            {currentSpace?.name}
          </div>
          <StyledAdress>{t("SpaceTypes." + currentSpace?.type)}</StyledAdress>
          <StyledAdress>
            {currentSpace?.zip_code}, {currentSpace?.city}
          </StyledAdress>
        </Col>
      </Row>
      <StyledRowMinTime>
        <StyledMinBookingTimeIcon />
        {t("SpacePage.MinBookingTime")}{" "}
        <StyledBoldMinTime>{currentSpace?.min_booking_time}h</StyledBoldMinTime>
      </StyledRowMinTime>
      <StyledRowIconInfo>
        {/* <Space size={20}> */}
        <StyledIconItem>
          <StyledSittingIcon />
          <span>
            {t("SpacePage.UpTo")} <br />
            {currentSpace?.seat_number} {t("SpacePage.Seats")}
          </span>
        </StyledIconItem>
        <StyledIconItem>
          <StyledStandingIcon />
          {t("SpacePage.UpTo")} {currentSpace?.standing_number} <br />
          {t("SpacePage.Standings")}
        </StyledIconItem>
        <StyledIconItem>
          <StyledFloorAreaIcon /> {currentSpace?.floor_area} m²
        </StyledIconItem>
        {/* </Space> */}
      </StyledRowIconInfo>
      <StyledRowButtons>
        <CustomButton
          type="succes"
          size="large"
          style={{ flex: 1 }}
          onClick={handleMakeRequestButtonClick}
        >
          {t("SpacePage.ContactManager")}
        </CustomButton>
        <HeartButton
          type="grey"
          size="large"
          onClick={handleFavoriteButtonClick}
        >
          <StyledHeart isLiked={currentSpace?.is_favorite!} />
        </HeartButton>
      </StyledRowButtons>
      <Row>
        {" "}
        <Avatar
          size={64}
          src={currentSpace?.manager_avatar_url}
          style={{ marginRight: "12px" }}
        >
          {currentSpace
            ? currentSpace.first_name_manager.charAt(0) +
              currentSpace.last_name_manager.charAt(0)
            : ""}
        </Avatar>
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div style={{ fontSize: "12px", color: "#767676" }}>
            {t("SpacePage.Manager")}
          </div>
          <div>
            {currentSpace?.first_name_manager +
              " " +
              currentSpace?.last_name_manager.charAt(0)}
          </div>
        </Col>
      </Row>
      <ModalLogin
        isOpen={isOpenModalLogin}
        closeModal={() => setOpenModalLogin(false)}
      />
      <ModalMakeRequest
        isOpen={isOpenModalMakeRequest}
        closeModal={() => setIsOpenModalMakeRequest(false)}
      />
    </>
  );
}

export default observer(MainInfoSpace);

const StyledAdress = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */
  margin-bottom: 3px;
  color: #626262;
`;

const StyledRowMinTime = styled(Row)`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */
  color: #1f1f1f;
  margin-bottom: 25px;

  @media (max-width: 992px) {
    margin-bottom: 15px;
  }
`;

const StyledBoldMinTime = styled.span`
  font-weight: 600;
  margin-left: 3px;
`;

const StyledMinBookingTimeIcon = styled(MinBookingTimeIcon)`
  min-height: 17px;
  min-width: 17px;
  margin-right: 5px;
`;

const StyledSittingIcon = styled(SittingIcon)`
  min-height: 20px;
  min-width: 34px;
  margin-right: 5px;
  fill: black;
`;

const StyledStandingIcon = styled(StandingIcon)`
  min-height: 20px;
  min-width: 34px;
  margin-right: 5px;
  fill: black;
`;

const StyledFloorAreaIcon = styled(FloorAreaIcon)`
  min-height: 20px;
  min-width: 34px;
  margin-right: 5px;
  fill: black;
`;

const StyledIconItem = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;

  /* identical to box height, or 22px */
  // color: #1f1f1f;
  // margin-bottom: 25px;
`;

interface StyledHeartProps {
  isLiked: boolean;
}

const StyledHeart = styled(Heart)<StyledHeartProps>`
  stroke-width: 2;
  fill: none;
  stroke: #000;
  fill: ${(props) => (props.isLiked ? "#ff5959" : "none")};
  stroke: ${(props) => (props.isLiked ? "#ff5959" : "#000")};
`;

const StyledStar = styled(Star)`
  fill: #dbde45;
`;

const HeartButton = styled(CustomButton)`
  &.ant-btn.ant-btn-lg {
    width: 60px;
    height: 60px;
    padding: 5px 0 0 0;
  }
`;

const StyledRowButtons = styled(Row)`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;

  @media (max-width: 992px) {
    margin-bottom: 22px;
  }
`;

const StyledRowIconInfo = styled(Row)`
  display: flex;
  gap: 25px;
  flex-wrap: nowrap;
  margin-bottom: 35px;

  @media (max-width: 992px) {
    margin-bottom: 22px;
  }
`;
