import React from "react";
import styled from "styled-components";
import infoImage from "assets/images/info-pages/info.svg";
import errorImage from "assets/images/info-pages/error.svg";
import { Col, Row } from "antd";
// Define the types for the CustomResult component's props
interface CustomResultProps {
  status: "success" | "error" | "warning" | "info";
  title: string;
  subTitle?: string | null;
  extra?: React.ReactNode;
}


/** Component to inform user about events. */
const CustomResult: React.FC<CustomResultProps> = ({
  status,
  title,
  subTitle,
  extra,
}) => {
  return (
    <Row style={{ height: "100%", width: "100%", justifyContent: "center" }}>
      <Col
        xs={24}
        sm={20}
        md={16}
        lg={12}
        xl={8}
        style={{ paddingTop: "50px" }}
      >
        <IconWrapper status={status}>
          {/* Use an appropriate icon based on the status prop */}
          {status === "success" && <img src={infoImage} alt="Success" />}
          {status === "error" && <img src={errorImage} alt="Success" />}
          {status === "warning" && <span>⚠</span>}
          {status === "info" && <span>ℹ</span>}
        </IconWrapper>
        <Title>{title}</Title>
        {subTitle && <SubTitle>{subTitle}</SubTitle>}
        {extra && <ExtraWrapper>{extra}</ExtraWrapper>}
      </Col>
    </Row>
  );
};

export default CustomResult;


const IconWrapper = styled.div<{ status: CustomResultProps["status"] }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  margin-bottom: 30px;
  border-radius: 50%;
`;

const Title = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 27px;
  text-align: center;
  color: #000000;
  margin: 0px;
  margin-bottom: 17px;
`;

const SubTitle = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  text-align: center;
  color: #505050;
  margin: 0px;
  margin-bottom: 17px;
`;

const ExtraWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;