import React, { useEffect } from "react";
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate, Outlet } from "react-router-dom";

/** Component wrapper to validate URL.
 * lang in url must be "en" or "fr".
 */
function ValidateLang(): JSX.Element {
  const { lang } = useParams();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (lang && lang !== "en" && lang !== "fr") {
      i18n.changeLanguage("en").then(() => {
        navigate(routerPathLocal("en", RouterPath.LINK_NOT_VALID));
      });
    }
  }, [lang]);

  return <Outlet />;
}

export default ValidateLang;
