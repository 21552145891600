import React, { ReactNode, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import useStore from "hooks/useStore";
import { enumUserRole } from "constants/User";
import { requestStatus } from "constants/reqestStatus";
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import { useTranslation } from "react-i18next";

interface RouteGuardProps {
  userRole: enumUserRole;
}

/** Component to add protection to routes in function of user role. */
function RouteGuard(props: RouteGuardProps): JSX.Element {
  const { auth } = useStore();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (
      auth.postUserInfoRequestStatus !== requestStatus.SUCCESS &&
      auth.postUserInfoRequestStatus !== requestStatus.ERROR
    ) {
      //
    } else if (auth.currentUser?.role !== props.userRole) {
      navigate(routerPathLocal(i18n.language, RouterPath.LINK_NOT_AUTHENTICATED));
    }
  }, [
    auth.currentUser,
    auth.postLoginRequestStatus,
    auth.postUserInfoRequestStatus,
    i18n.language,
    navigate,
    props.userRole,
  ]);

  if (auth.currentUser?.role === props.userRole) {
    return <Outlet />;
  }

  return <></>;
}

export default observer(RouteGuard);
