import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import ProfileLayout from "components/profileLayout/ProfileLayout";
import menuAdmin from "pages/spaceAdmin/commonComponents/menuAdmin";
import { CustomH1 } from "components/typography/CustomH";
import useStore from "hooks/useStore";
import { useEffect, useState } from "react";
import { Space, Table } from "antd";
import { Link } from "react-router-dom";
import { RouterPath, routerPathLocal } from "constants/RouterPath";

function UserListAdmin(): JSX.Element {
  const { t, i18n } = useTranslation();
  const { userListStore } = useStore();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });

  const fetchUsers = async (page: number, pageSize: number) => {
    await userListStore.setPage(page);
    await userListStore.fetchUserListAsAdmin(page, pageSize);
    setPagination((prevState) => ({
      ...prevState,
      total: userListStore.total,
    }));
  };

  useEffect(() => {
    fetchUsers(pagination.current, pagination.pageSize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.current, pagination.pageSize]);

  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };

  const columns = [
    {
      title: t("AdminProfile.UserList.ColumnName"),
      dataIndex: "full_name",
      key: "full_name",
    },
    {
      title: t("AdminProfile.UserList.ColumnEmail"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("AdminProfile.UserList.ColumnRole"),
      dataIndex: "role",
      key: "role",
    },
    {
      title: t("AdminProfile.UserList.ColumnActions"),
      key: "action",
      render: (_, item) => (
        <Space size="middle">
          <Link
            to={
              routerPathLocal(i18n.language, RouterPath.ADMIN_USER_PAGE) +
              "/" +
              item.uuid
            }
          >
            {t("AdminProfile.UserList.ButtonUserPage")}
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <ProfileLayout menu={menuAdmin(i18n.language, t)}>
      <>
        <CustomH1>{t("AdminProfile.UserList.PageTitle")}</CustomH1>
        <Table
          columns={columns}
          dataSource={userListStore.getUserListAsArray()}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </>
    </ProfileLayout>
  );
}

export default observer(UserListAdmin);
