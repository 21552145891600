import { Button, Result } from "antd";
import CustomButton from "components/buttons/CustomButton";
import CustomResult from "components/customResult/CustomResult";
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

/** Page to inform user that he has successfully created a new account
 * and now he should confirm his mail.
 */
export default function SignupSuccess(): JSX.Element {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleGoToHome = () => {
    navigate(routerPathLocal(i18n.language, RouterPath.HOME));
  };

  return (
    <CustomResult
      status="success"
      title={t("SignupSucces.Title")}
      subTitle={t("SignupSucces.Description")}
      extra={[
        <CustomButton size="large" type="succes" key="back" onClick={handleGoToHome}>
          {t("SignupSucces.Button")}
        </CustomButton>,
      ]}
    />
  );
}
