import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "antd";
import {
  IGetSpacesItemResponse,
  IGetSpacesResponse,
} from "services/SpaceService";
import { ReactComponent as ModifyIcon } from "assets/images/penModify.svg";
import styled from "styled-components";
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import { Link, useNavigate } from "react-router-dom";
import SpaceCardClicable from "components/spaceCardClicable/SpaceCardClicable";
import useStore from "hooks/useStore";
import { ReactComponent as Heart } from "assets/images/heart.svg";
import { ISpaceItem } from "states/models/SpaceList/SpaceItem";

interface IListClicableSpacesProps {
  listSpaces: ISpaceItem[];
  itemsInRow?: number;
}

/** Component for displaying spaces list in form of Cards. */
export default function ListClicableSpaces({
  listSpaces,
  itemsInRow = 2,
}: IListClicableSpacesProps) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const spanSize = {
    xs: 24,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12,
  };

  if (itemsInRow === 4) {
    spanSize.xl = 6;
    spanSize.lg = 6;
    spanSize.md = 8;
    spanSize.sm = 12;
    spanSize.xs = 24;
  }

  return (
    <>
      <>
        <Row gutter={[20, 20]} style={{ margin: 0 }}>
          {listSpaces.map((space) => {
            return (
              <Col
                xs={spanSize.xs}
                sm={spanSize.sm}
                md={spanSize.md}
                lg={spanSize.lg}
                xl={spanSize.xl}
                key={space.id}
              >
                <SpaceCardClicable
                  key={"spaceCard" + String(space.id)}
                  space={space}
                ></SpaceCardClicable>
              </Col>
            );
          })}
        </Row>
      </>
    </>
  );
}
