import { applySnapshot, flow, Instance, types } from 'mobx-state-tree'
import { User, IUser } from 'states/models/User/UserModel'
import SpaceService, { ISpacePageInfo } from "services/SpaceService";
import { requestStatus } from "constants/reqestStatus"
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import { ISpaceModel, SpaceModel } from 'states/models/Space/SpaceModel';
import { OpeningHours } from 'states/models/Space/OpeningHours';
import { Comment } from 'states/models/Space/Comment';
import { ImageFile } from 'states/models/Space/ImageFile';
import { IUserPageModel, UserPageModel } from 'states/models/User/UserPageModel';
import UserService, { IUserInfoAsAdminResponse } from 'services/UserService';
import { enumUserRole } from 'constants/User';


export const UserPageStore = types
  .model({
    currentUserPage: types.maybeNull(UserPageModel),
    getRequestStatus: types.optional(
      types.enumeration<requestStatus>(Object.values(requestStatus)), requestStatus.INITIAL)

  })
  .actions((self) => {
    return {
      setGetRequestStatus: (status: requestStatus) => {
        self.getRequestStatus = status;
      },
      setUserPage: (currentUserPage: IUserPageModel | null) => {
        self.currentUserPage = currentUserPage;
      },
      clearUserPage: () => {
        self.currentUserPage = null;
      },
    }
  })
  .actions((self) => {
    return {
      getAndSetUserPage: flow(
        function* (uuidUser: string) {

        const response = yield UserService.getUserInfoAsAdmin({uuid_user: uuidUser});
        const responseData: IUserInfoAsAdminResponse = response.data;
        const space = UserPageModel.create({
          uuid: responseData.uuid,
          id: responseData.id,
          first_name: responseData.first_name,
          last_name: responseData.last_name,
          email: responseData.email,
          role: responseData.role as enumUserRole,
          tel: responseData.tel,
          is_mail_confirmed: responseData.is_mail_confirmed,
          url_avatar: responseData.url_avatar,
          date_creation: responseData.date_creation,
          date_last_login: responseData.date_last_login,
          date_pwd_reinit: responseData.date_pwd_reinit,
          number_of_spaces: responseData.number_of_spaces,
        });
        self.setUserPage(space);

      })
    
    }
  })
  




export type IUserPageStore = Instance<typeof UserPageStore>