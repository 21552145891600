import { PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Upload, UploadFile, notification } from "antd";
import SpaceApiService, { IImages } from "services/SpaceService";
import useUpdateEffect from "hooks/useUpdateEffect";
import styled from "styled-components";

interface UploadImagesProps {
  initialValue: IImages[];
  onImageListChange: (idImageFiles: number[]) => void;
}

const UploadImages: React.FC<UploadImagesProps> = ({
  initialValue,
  onImageListChange,
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(""); // File url
  const [fileList, setFileList] = useState<any[]>([]);
  const [idImageFiles, setIdImageFiles] = useState<number[]>([]);
  const { t } = useTranslation();
  const firstId: any = useRef(null);
  const isEmpty: any = useRef(true);

  // Initial data loading
  useEffect(() => {
    setFileList(initialValue);
    setIdImageFiles(initialValue.map((image) => image.id_image_file));
    if (initialValue.length) {
      isEmpty.current = false;
    } else {
      isEmpty.current = true;
    }
  }, [initialValue]);

  // Set id images files only on change
  useUpdateEffect(() => {
    onImageListChange(idImageFiles);
  }, [fileList]);

  const handleUpload = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await SpaceApiService.postUploadImage(formData);
      const { url, id_image_file } = response.data;
      firstId.current = id_image_file;

      const incrementFileList = () => {
        setFileList((prevValue) => {
          const updatedFileList = prevValue.map((obj) =>
            obj.uid === file.uid
              ? { url, id_image_file, uid: file.uid, name: file.name }
              : obj
          );
          incrementIdImageFiles(updatedFileList);
          return updatedFileList;
        });
      };

      // Increment Ids after updating fileList and set cover
      const incrementIdImageFiles = (updatedCountList: any) => {
        setIdImageFiles(() => {
          const updatedIdImageFiles = updatedCountList.map((item: any): any =>
            item.id_image_file ? item.id_image_file : undefined
          );
          handleAfterFileList(updatedCountList);
          return updatedIdImageFiles;
        });
      };

      // handler of actions after successful upload of image
      const handleAfterFileList = (updatedCountList: any) => {
        // if this is first uploaded file
        if (updatedCountList.length === 1 && isEmpty.current) {
          isEmpty.current = false;
        } else if (updatedCountList.length > 1 && isEmpty.current) {
          isEmpty.current = false;
          firstId.current = null;
        }
      };
      incrementFileList();
    } catch (error) {
      // error;
    } finally {
      isEmpty.current = false;
    }
  };

  const handleChange = ({ fileList: newFileList }: { fileList: any }) => {
    const check = fileList.some(
      (obj) => obj.name === newFileList[newFileList.length - 1].name
    );

    if (check) {
      notification.warning({
        message: t("CommonAddModifySpace.ImageNotificationExistsAlreadyTitle"),
        description: t(
          "CommonAddModifySpace.ImageNotificationExistsAlreadyDescription"
        ),
        placement: "bottomRight",
      });
    } else {
      setFileList(newFileList);
    }
  };

  const handleFileRemove = (file: UploadFile<any>) => {
    // Remove the id of the deleted file from the idImageFiles list
    const deletedFileIndex = fileList.findIndex(
      (item) => item.uid === file.uid
    );
    const deletedFileId = idImageFiles[deletedFileIndex];
    const newIdImageFiles = idImageFiles.filter((id) => id !== deletedFileId);
    setIdImageFiles(newIdImageFiles);
    // Update the fileList state
    setFileList(fileList.filter((item) => item.uid !== file.uid));

    return false;
  };

  // Preview image
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file: any) => {
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    // setPreviewTitle(
    //   file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    // );
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{t("Texts.Upload")}</div>
    </div>
  );

  const renderItem = (originNode: React.ReactNode) => {
    return <div style={{ height: "auto" }}>{originNode}</div>;
  };
  function beforeUpload(file: any) {
    const isPresentAlreadyInFileList = fileList.some(
      (obj) => obj.name === file.name
    );
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      notification.error({
        message: t("NotificationErrors.ImageFormat.Title"),
        description: t("NotificationErrors.ImageFormat.Description"),
        placement: "bottomRight",
      });
    }
    const isLt10M = file.size / 1024 / 1024 < 20;
    if (!isLt10M) {
      notification.error({
        message: t("NotificationErrors.FileMustBeLessThan20Mb.Title"),
        description: t("NotificationErrors.FileMustBeLessThan20Mb.Description"),
        placement: "bottomRight",
      });
    }

    return (
      (isJpgOrPng && isLt10M && !isPresentAlreadyInFileList) ||
      Upload.LIST_IGNORE
    );
  }

  return (
    <>
      <StyledUpload
        accept="image/png, image/jpg, image/jpeg"
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        onRemove={handleFileRemove}
        customRequest={({ file }: any) => handleUpload(file)}
        itemRender={renderItem}
        beforeUpload={beforeUpload}
      >
        {fileList.length >= 15 ? null : uploadButton}
      </StyledUpload>
      <Modal
        open={previewOpen}
        title={""}
        footer={null}
        onCancel={handleCancel}
        transitionName=""
      >
        <img alt="uploaded file" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

/** ant-upload-list-item-container */
const StyledUpload = styled(Upload)`
  &.ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload-list.ant-upload-list-picture-card
    .ant-upload-list-item-container {
    height: 130px;
  }
  &.ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload-list.ant-upload-list-picture-card
    .ant-upload-list-item {
    height: 102px;
  }
`;

export default UploadImages;
