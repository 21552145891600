import { Space } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as MetroIcon } from "assets/images/metro.svg";
import { Link } from "react-router-dom";
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import useStore from "hooks/useStore";
import { ReactComponent as Heart } from "assets/images/heart.svg";
import { ISpaceItem } from "states/models/SpaceList/SpaceItem";
import { observer } from "mobx-react-lite";
import ModalLogin from "components/modals/modalLogin/ModalLogin";

export interface SpaceCardClicableProps {
  space: ISpaceItem;
}

/** Space card simple */
function SpaceCardClicable(props: SpaceCardClicableProps): JSX.Element {
  const { t, i18n } = useTranslation();
  const { space } = props;
  const { spaceListMapStore } = useStore();
  const [isOpenModalLogin, setOpenModalLogin] = useState(false);
  const { auth } = useStore();

  const onHover = () => {
    spaceListMapStore.setIdHoveredSpace(String(space.id));
  };

  const onMouseLeave = () => {
    spaceListMapStore.setIdHoveredSpace("-1");
  };

  useEffect(() => {
    return () => {
      spaceListMapStore.setIdHoveredSpace("-1");
    };
  }, [spaceListMapStore]);

  const handleFavoriteClick = () => {
    if (auth.currentUser) {
      space.changeIsFavorite();
    } else {
      setOpenModalLogin(true);
    }
  };

  return (
    <>
      <CardWrapper>
        <Link
          to={routerPathLocal(i18n.language, RouterPath.SPACE + space.pseudo)}
          onMouseEnter={onHover}
          onMouseLeave={onMouseLeave}
        >
          <SpaceCardWrapper>
            {space.url_cover_image ? (
              <ImageWrapper>
                <Image
                  src={space.url_cover_image}
                  alt=""
                  style={{ borderRadius: "10px" }}
                />
              </ImageWrapper>
            ) : (
              <div>{space.url_cover_image}</div>
            )}
            <div className="card-title">{space.name}</div>
            <div className="card-metro">
              <StyledImgMetro /> {space.zip_code}, {space.city}
            </div>
            <SpacerPrice>
              <span className="card-price-small-text">
                {t("Texts.PriceFrom")}
              </span>
              <span className="card-price-big-text">{space.min_price}</span>
              <span className="card-price-small-text">
                {t("Texts.PricePerHour")}
              </span>
            </SpacerPrice>
          </SpaceCardWrapper>
        </Link>
        <HeartIconWrapper onClick={handleFavoriteClick}>
          <HeartIcon isLiked={space.is_favorite} />
        </HeartIconWrapper>
      </CardWrapper>
      <ModalLogin
        isOpen={isOpenModalLogin}
        closeModal={() => setOpenModalLogin(false)}
      />
    </>
  );
}

export default observer(SpaceCardClicable);

const CardWrapper = styled.div`
  position: relative;
`;

const SpaceCardWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 100%; // 16:9 aspect ratio
  overflow: hidden;
  position: relative;
  border-radius: 10px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  border-radius: 10px;
  user-drag: none;
  user-select: none;
`;

const SpacerPrice = styled.div`
  gap: 3px;
  display: flex;
  align-items: flex-end;
`;

const StyledImgMetro = styled(MetroIcon)`
  stroke: #767676;
  fill: none;
`;

const HeartIconWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
`;

interface StyledHeartProps {
  isLiked: boolean;
}

const HeartIcon = styled(Heart)<StyledHeartProps>`
  stroke-width: 2;
  width: 35px;
  height: 35px;
  fill: ${(props) => (props.isLiked ? "#ff5959" : "none")};
  stroke: ${(props) => (props.isLiked ? "#ff5959" : "#fff")};
`;
