import { applySnapshot, flow, Instance, types } from 'mobx-state-tree'
import { User, IUser } from 'states/models/User/UserModel'
import SpaceService, { ISpaceMiniInfoResponseAPI, ISpacePageInfo } from "services/SpaceService";
import { requestStatus } from "constants/reqestStatus"
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import { ISpaceModel, SpaceModel } from 'states/models/Space/SpaceModel';
import { OpeningHours } from 'states/models/Space/OpeningHours';
import { Comment } from 'states/models/Space/Comment';
import { ImageFile } from 'states/models/Space/ImageFile';
import { ISpaceMiniInfoModel, SpaceMiniInfoModel } from 'states/models/Space/SpaceMiniInfoModel';

export const SpaceMiniInfoStore = types
  .model({
    currentSpaceMiniInfo: types.maybeNull(SpaceMiniInfoModel),
    getRequestStatus: types.optional(
      types.enumeration<requestStatus>(Object.values(requestStatus)), requestStatus.INITIAL)

  })
  .actions((self) => {
    return {
      setGetRequestStatus: (status: requestStatus) => {
        self.getRequestStatus = status;
      },
      setSpaceMiniInfo: (spaceMiniInfoModel: ISpaceMiniInfoModel | null) => {
        self.currentSpaceMiniInfo = spaceMiniInfoModel;
      },
      clearCurrentSpaceMiniInfo: () => {
        self.currentSpaceMiniInfo = null;
      },
    }
  })
  .actions((self) => {
    return {
      getAndSetCurrentSpaceMiniInfo: flow(
        function* (spacePseudo: string, lang: string) {
        const response = yield SpaceService.getSpaceMiniInfo({space_pseudo: spacePseudo, lang: lang});
        const responseData: ISpaceMiniInfoResponseAPI = response.data;
          const spaceMiniInfo = SpaceMiniInfoModel.create({
            id: responseData.id,
            name: responseData.name,
            pseudo: responseData.pseudo,
            type: responseData.type,
            is_favorite: responseData.is_favorite,
            min_price: responseData.min_price,
            address: responseData.address,
            seat_number: responseData.seat_number,
            standing_number: responseData.standing_number,
            floor_area: responseData.floor_area,
            comment_number: responseData.comment_number,
            average_rating: responseData.average_rating,
            images: responseData.images.map(image => {
              return ImageFile.create({
                url: image.url,
                id_image_file: image.id_image_file,
              })
            }),
        });
        self.setSpaceMiniInfo(spaceMiniInfo);
      })
    }
  })



export type ISpaceStore = Instance<typeof SpaceMiniInfoStore>