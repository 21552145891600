import { Avatar, Badge, Col, Row } from "antd";
import useStore from "hooks/useStore";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IInbox } from "states/models/Messenger/Inbox";
import { formatDate, truncateString } from "tools/formatters";

interface InboxItemProps {
  inbox: IInbox;
  onClick?: () => void;
}

function InboxItem({ inbox, onClick }: InboxItemProps): JSX.Element {
  const { t, i18n } = useTranslation();
  const { messageStore, auth } = useStore();

  const handleInboxClick = async () => {
    if (onClick) {
      onClick();
    }
    if (inbox.id !== messageStore.id_active_inbox) {
      messageStore.setActiveInbox(inbox.id, inbox.first_name, inbox.last_name);
      if (inbox.is_seen === false) {
        auth.setNumberUnreadMessages(auth.numberUnreadMessages - 1);
        inbox.setIsSeen(true);
      }
    }
  };

  return (
    <>
      <Row
        onClick={handleInboxClick}
        style={{
          borderRadius: "10px",
          cursor: "pointer",
          background: inbox.isActive(messageStore.id_active_inbox)
            ? "#007AAE"
            : "",
        }}
      >
        <Col>
          {inbox.is_seen ? (
            <Avatar
              shape="square"
              size={76}
              src={inbox.url_avatar !== "" ? inbox.url_avatar : undefined}
              style={{
                marginRight: "10px",
                backgroundColor: inbox.url_avatar !== "" ? "" : "#D292F0",
                borderRadius: "10px",
                border: "none",
              }}
              draggable={false}
            >
              {inbox.first_name.charAt(0) + inbox.last_name.charAt(0)}
            </Avatar>
          ) : (
            <Badge
              dot
              style={{ background: "#007AAE", width: "12px", height: "12px" }}
              offset={[-20, 10]}
            >
              <Avatar
                shape="square"
                size={76}
                src={inbox.url_avatar !== "" ? inbox.url_avatar : undefined}
                style={{
                  marginRight: "10px",
                  backgroundColor: inbox.url_avatar !== "" ? "" : "#D292F0",
                  borderRadius: "10px",
                }}
                draggable={false}
              >
                {inbox.first_name.charAt(0) + inbox.last_name.charAt(0)}
              </Avatar>
            </Badge>
          )}
        </Col>
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flex: 1,
            color: inbox.isActive(messageStore.id_active_inbox) ? "#fff" : "",
          }}
        >
          <Row
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginRight: "10px",
            }}
          >
            <Col>{inbox.first_name + " " + inbox.last_name}</Col>
            <Col
              style={{
                color: inbox.isActive(messageStore.id_active_inbox)
                  ? "#fff"
                  : "#505050",
              }}
            >
              {formatDate(inbox.date_creation, i18n.language)}
            </Col>
          </Row>
          <Row>{truncateString(inbox.message, 27)}</Row>
        </Col>
      </Row>
    </>
  );
}

export default observer(InboxItem);
