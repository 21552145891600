import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as MetroIcon } from "assets/images/metro.svg";

export interface SpaceCardProps {
  id: number;
  name: string;
  metro: string;
  city: string;
  zip_code: string;
  min_price: number;
  url_cover_image: string | null;
  children?: React.ReactNode;
}

/** Space card simple */
export default function SpaceCard(props: SpaceCardProps): JSX.Element {
  const { t } = useTranslation();
  const { name, metro, min_price, url_cover_image, children, city, zip_code } = props;
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className="space-card-wrapper"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      // key={name}
    >
      {url_cover_image ? (
        <div className="image-wrapper">
          <img
            src={url_cover_image}
            alt={`${name} cover`}
            className="space-card-image"
          />
          {hovered && <HoverLayer />}
          {hovered && children && <ChildWrapper>{children}</ChildWrapper>}
        </div>
      ) : (
        <div>{/* TODO add icon empty */}</div>
      )}
      <div className="card-title">{name}</div>
      <div className="card-metro">
        <MetroIcon className="metro-icon-image-space-card" /> {zip_code}, {city}
      </div>
      <div className="spacer-price">
        <span className="card-price-small-text">{t("Texts.PriceFrom")}</span>
        <span className="card-price-big-text">{min_price}</span>
        <span className="card-price-small-text">{t("Texts.PricePerHour")}</span>
      </div>
    </div>
  );
}

const HoverLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  transition: background-color 0.3s ease;
`;

const ChildWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;
