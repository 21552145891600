import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import MyProfileComponent from "components/myProfile/MyProfileComponent";
import menuAdmin from "pages/spaceAdmin/commonComponents/menuAdmin";
import ProfileLayout from "components/profileLayout/ProfileLayout";
import { CustomH1 } from "components/typography/CustomH";
import useStore from "hooks/useStore";
import { useParams } from "react-router-dom";
import { formatDate } from "tools/formatters";

/** My profile Admin page. */
function UserPageAdmin(): JSX.Element {
  const { t, i18n } = useTranslation();
  const {
    userPageStore: { currentUserPage },
    userPageStore,
  } = useStore();
  const { uuidUser } = useParams<{ uuidUser: string }>();

  useEffect(() => {
    if (uuidUser) {
      userPageStore.getAndSetUserPage(uuidUser);
    }
  }, [userPageStore, uuidUser]);

  if (!currentUserPage) return <></>;

  return (
    <>
      <ProfileLayout menu={menuAdmin(i18n.language, t)}>
        <>
          <CustomH1>{t("AdminProfile.UserPage.PageTitle")}</CustomH1>
          <p>
            {t("AdminProfile.UserPage.Id")} {currentUserPage.id}
          </p>
          <p>
            {t("AdminProfile.UserPage.FirstName")} {currentUserPage.first_name}
          </p>
          <p>
            {t("AdminProfile.UserPage.LastName")} {currentUserPage.last_name}
          </p>
          <p>
            {t("AdminProfile.UserPage.Email")} {currentUserPage.email}
          </p>
          <p>
            {t("AdminProfile.UserPage.Role")} {currentUserPage.role}
          </p>
          <p>
            {t("AdminProfile.UserPage.Tel")} {currentUserPage.tel}
          </p>
          <p>
            {t("AdminProfile.UserPage.IsMailConfirmed")}{" "}
            {currentUserPage.is_mail_confirmed ? "true" : "false"}
          </p>
          <p>
            {t("AdminProfile.UserPage.DateCreation")}{" "}
            {formatDate(currentUserPage.date_creation, i18n.language)}
          </p>
          <p>
            {t("AdminProfile.UserPage.DateLastLogin")}{" "}
            {currentUserPage.date_last_login
              ? formatDate(currentUserPage.date_last_login, i18n.language)
              : ""}
          </p>
          <p>
            {t("AdminProfile.UserPage.NumberOfSpaces")}{" "}
            {currentUserPage.number_of_spaces}
          </p>
        </>
      </ProfileLayout>
    </>
  );
}

export default observer(UserPageAdmin);
