import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import menuManager from "../commonComponents/menuManager";
import ProfileLayout from "components/profileLayout/ProfileLayout";

import AddModifySpace from "components/commonPages/addModifySpace/AddModifySpace";

/** Add Space Manager page. */
function AddSpaceManager(): JSX.Element {
  const { t, i18n } = useTranslation();

  return (
    <>
      <ProfileLayout menu={menuManager(i18n.language, t)}>
        <>
          <AddModifySpace isCreation />
        </>
      </ProfileLayout>
    </>
  );
}

export default observer(AddSpaceManager);
