import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ApiAuthService from "services/AuthService";
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import { useTranslation } from "react-i18next";
import CustomResult from "components/customResult/CustomResult";
import CustomButton from "components/buttons/CustomButton";

/** Page to confirm user email during signup process.
 * Get the token from query params, send it to backend.
 * If success: show the page that the account was activated
 * Else: redirect to link not valid page.
 */
export default function ConfirmEmail() {
  const [isSuccess, setIsSuccess] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  let token = searchParams.get("token");

  const handleGoToHome = () => {
    navigate(routerPathLocal(i18n.language, RouterPath.HOME));
  };

  useEffect(() => {
    if (token === null) {
      navigate(routerPathLocal(i18n.language, RouterPath.LINK_NOT_VALID));
    } else if (token) {
      ApiAuthService.postConfirmEmail(token!)
        .then((response) => {
          if (response.status !== 200) {
            navigate(routerPathLocal(i18n.language, RouterPath.LINK_NOT_VALID));
          }
          setIsSuccess(true);
        })
        .catch((error) => {
          navigate(routerPathLocal(i18n.language, RouterPath.LINK_NOT_VALID));
        });
    }
  }, [token, navigate, i18n]);

  if (!token) {
    return <></>;
  }

  return (
    <>
      {isSuccess && (
        <CustomResult
          status="success"
          title={t("ConfirmEmail.Title")}
          subTitle={t("ConfirmEmail.Description")}
          extra={[
            <CustomButton
              size="large"
              type="succes"
              key="back"
              onClick={handleGoToHome}
            >
              {t("ConfirmEmail.Button")}
            </CustomButton>,
          ]}
        />
      )}
    </>
  );
}
