import AppConfig from "appConfig";

const createApiEndpoint = (path: string) => {

  return AppConfig.api.ENDPOINT + path
};

export const ApiEndpointsV1 = Object.freeze({
  // LOGIN
  LOGIN: createApiEndpoint("/api/v1/login/get-access-token"),
  SIGNUP: createApiEndpoint("/api/v1/users/signup"),
  CONFIRM_EMAIL: createApiEndpoint("/api/v1/users/confirm-email/"),
  PASSWORD_RECOVERY: createApiEndpoint("/api/v1/login/password-recovery"),
  RESET_PASSWORD: createApiEndpoint("/api/v1/login/reset-password/"),
  // USER
  USER_MY_INFO: createApiEndpoint("/api/v1/users/get-my-info"),
  UPDATE_USER_INFO: createApiEndpoint("/api/v1/users/update-user-info"),
  UPDATE_USER_PASSWORD: createApiEndpoint("/api/v1/users/update-password"),
  UPDATE_USER_AVATAR: createApiEndpoint("/api/v1/users/upload-avatar"),
  DELETE_USER_AVATAR: createApiEndpoint("/api/v1/users/delete-avatar"),
  DELETE_SELF_USER: createApiEndpoint("/api/v1/users/delete-self-account"),
  GET_USER_NOTIFICATION_INFO: createApiEndpoint("/api/v1/users/notification-info"),
  UPDATE_USER_NOTIFICATION_INFO: createApiEndpoint("/api/v1/users/notification-info"),
  GET_USER_LIST_AS_ADMIN: createApiEndpoint("/api/v1/users/get-list-users-as-admin"),
  GET_USER_INFO_AS_ADMIN: createApiEndpoint("/api/v1/users/get-user-info-as-admin"),
  // MESSENGER
  GET_MY_INBOXES: createApiEndpoint("/api/v1/messages/inbox"),
  GET_MESSAGES_FOR_INBOX: createApiEndpoint("/api/v1/messages/inbox-messages"),
  GET_NUMBER_OF_UNREAD_MESSAGES: createApiEndpoint("/api/v1/messages/number-unread-messages"),
  CREATE_MESSAGE: createApiEndpoint("/api/v1/messages/message"),
  CREATE_MESSAGE_TO_INBOX: createApiEndpoint("/api/v1/messages/message-to-inbox"),
  // SPACES
  GET_SPACES: createApiEndpoint("/api/v1/spaces/spaces/"),
  GET_SPACE_PAGE_INFO: createApiEndpoint("/api/v1/spaces/space-info"),
  GET_SPACE_MINI_INFO: createApiEndpoint("/api/v1/spaces/space-mini-info"),
  GET_MY_SPACES: createApiEndpoint("/api/v1/spaces/my-spaces/"),
  GET_SPACES_ADMIN: createApiEndpoint("/api/v1/spaces/spaces-admin/"),
  CREATE_SPACE: createApiEndpoint("/api/v1/spaces/create-space"),
  DELETE_SPACE: createApiEndpoint("/api/v1/spaces/delete-space"),
  MODIFY_SPACE: createApiEndpoint("/api/v1/spaces/modify-space/"),
  MODIFY_SPACE_AS_ADMIN: createApiEndpoint("/api/v1/spaces/modify-space-as-admin/"),
  MODIFY_SPACE_AS_MANAGER: createApiEndpoint("/api/v1/spaces/modify-space-as-manager/"),
  GET_SPACE_INFO: createApiEndpoint("/api/v1/spaces/modify-space"),
  ADD_SPACE_TO_FAVORITE: createApiEndpoint("/api/v1/spaces/favorite"),
  DELETE_SPACE_FROM_FAVORITE: createApiEndpoint("/api/v1/spaces/favorite"),
  ADD_COMMENT_ON_SPACE: createApiEndpoint("/api/v1/spaces/add-comment"),
  UPLOAD_IMAGE_SPACE: createApiEndpoint("/api/v1/files/upload-image"),
  ADD_COMMENT: createApiEndpoint("/api/v1/spaces/add-comment"),
  GET_SPACE_COMMENTS: createApiEndpoint("/api/v1/spaces/comments"),
  // INFO
  GET_COUNTRIES_LIST: createApiEndpoint("/api/v1/info/countries/"),
  GET_REGIONS_LIST: createApiEndpoint("/api/v1/info/regions/"),
  GET_EVENTS_LIST: createApiEndpoint("/api/v1/events/events/"),
  GET_FACILITIES_LIST: createApiEndpoint("/api/v1/facility/facilities/"),
  GET_REGION_CITY_GEO_LIST: createApiEndpoint("/api/v1/info/regions-cities-geo/"),
});