import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "antd";
import SpaceCardHover from "components/spaceCardHover/SpaceCardHover";
import {
  IGetSpacesItemResponse,
  IGetSpacesResponse,
} from "services/SpaceService";
import { ReactComponent as ModifyIcon } from "assets/images/penModify.svg";
import styled from "styled-components";
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import useStore from "hooks/useStore";
import InfiniteScroll from "react-infinite-scroll-component";
import ListClicableSpaces from "components/listClicableSpaces/ListClicableSpaces";


interface IFetchParams {
  state?: string;
  name?: string;
} 


interface IListSpacesStoreProps {
  fetchParams: IFetchParams;
  routerPath: string;
}

/** Component for displaying spaces list in form of Cards. */
function ListSpacesAdmin(props: IListSpacesStoreProps) {
  const { t, i18n } = useTranslation();
  const [spaces, setSpaces] = useState<IGetSpacesItemResponse[]>([]);
  const navigate = useNavigate();
  const { spaceListStore } = useStore();

  useEffect(() => {
    spaceListStore.clearSpaceList();
    spaceListStore.setPage(1);
    spaceListStore.fetchSpaceListAsAdmin({
      lang: i18n.language,
      page: 1,
      size: 20,
      state: props.fetchParams.state,
      name: props.fetchParams.name,
    });

    return () => {
      spaceListStore.clearSpaceList();
    };
  }, [props.fetchParams.name, props.fetchParams.state, spaceListStore]);

  const handleModifyButtonClick = (pseudo: string) => {
    navigate(
      routerPathLocal(i18n.language, RouterPath.ADMIN_MODIFY_SPACE + "/" + pseudo)
    );
  };

  return (
    <>
      <>
        <InfiniteScroll
          dataLength={spaceListStore.getSpaceListAsArray().length}
          next={() => {
            spaceListStore.setPage(spaceListStore.page + 1);
            spaceListStore.fetchSpaceListAsAdmin({
              lang: i18n.language,
              page: spaceListStore.page,
              size: 20,
              state: props.fetchParams.state,
              name: props.fetchParams.name,
            });
          }}
          style={{
            paddingTop: "25px",
            paddingBottom: "25px",
          }}
          hasMore={
            spaceListStore.getSpaceListAsArray().length < spaceListStore.total
          }
          // loader={<h4>Loading...</h4>}
          // scrollableTarget="scrollableDiv"
        >
          <Row gutter={[20, 20]} style={{ margin: 0 }}>
            {spaceListStore.getSpaceListAsArray().map((space) => {
              return (
                <Col xs={24} sm={24} md={12} lg={8} xl={8} key={space.id}>
                  <SpaceCardHover
                    id={space.id}
                    city={space.city}
                    zip_code={space.zip_code}
                    name={space.name}
                    metro={space.metro}
                    min_price={space.min_price}
                    url_cover_image={space.url_cover_image}
                  >
                    <>
                      {" "}
                      <Button
                        icon={<StyledModifyIcon />}
                        className="space-card-button"
                        onClick={() => handleModifyButtonClick(space.pseudo)}
                      />
                    </>
                  </SpaceCardHover>
                </Col>
              );
            })}
          </Row>
        </InfiniteScroll>
      </>
    </>
  );
}

export default observer(ListSpacesAdmin);

const StyledModifyIcon = styled(ModifyIcon)`
  stroke-width: 1.5px;
`;
