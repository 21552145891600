 
import { useRef, useEffect} from "react";

// Сustom useEffect hook that only runs when the specified dependency changes

export default function useUpdateEffect(effect: Function, dependencies: any[] = []) {
    const isInitialMount = useRef(true);
    useEffect(() => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        return effect();
      }
    }, dependencies);
  }