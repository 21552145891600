import { Checkbox, Form, Input, Select, notification } from "antd";
import CustomButton from "components/buttons/CustomButton";
import ProfileLayout from "components/profileLayout/ProfileLayout";
import useStore from "hooks/useStore";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UserApiService, {
  IUserMyNotificationInfoResponse,
} from "services/UserService";
import AvatarUpload from "./components/avatarUpload";
import { IMenuItem } from "components/responsiveMenu/ResponsiveMenu";
import { enumLanguages } from "constants/languages";
import { CustomH1 } from "components/typography/CustomH";

interface NotificationsComponentProps {
  menu: IMenuItem[];
}

/** Notifications component is set notification preferances for a user.
 * It's common to Customer, Manager and Admin spaces.
 */
const NotificationsComponent: React.FC<NotificationsComponentProps> = ({
  menu,
}) => {
  const { t } = useTranslation();
  const { auth } = useStore();
  const [form] = Form.useForm();
  const [isLoadingSaveModifications, setIsLoadingSaveModifications] =
    useState(false);

  useEffect(() => {
    UserApiService.getUserNotificationInfo()
      .then((response) => {
        const initialData = response.data;
        setInitialData(initialData);
      })
      .catch((error) => {
        notification.error({
          message: t("NotificationErrors.Error.Title"),
          description: t("NotificationErrors.Error.Description"),
          placement: "bottomRight",
        });
      });
  }, [t]);

  const handleButtonSaveClick = () => {
    console.log("cocu")
    setIsLoadingSaveModifications(true);
    try {
      UserApiService.putUpdateUserNotificationInfo({
        language: form.getFieldValue("language"),
        is_get_newsletter: form.getFieldValue("is_get_newsletter"),
      });
      notification.success({
        message: t("Notifications.Saved.Title"),
        description: t("Notifications.Saved.Description"),
        placement: "bottomRight",
      });
    } catch {
      notification.error({
        message: t("NotificationErrors.Error.Title"),
        description: t("NotificationErrors.Error.Description"),
        placement: "bottomRight",
      });
    } finally {
      setIsLoadingSaveModifications(false);
    }
  };

  const setInitialData = (initialData: IUserMyNotificationInfoResponse) => {
    form.setFieldsValue({
      is_get_newsletter: initialData.is_get_newsletter,
      language: initialData.language,
    });

  };

  if (!auth.currentUser) {
    return <></>;
  }

  return (
    <>
      <ProfileLayout menu={menu}>
        <>
          <CustomH1>
            {t("CommonProfile.Notifications.NotificationsTitle")}
          </CustomH1>
          <Form layout="vertical" form={form}>
            <div>{t("CommonProfile.Notifications.EmailNotifications")}</div>
            <Form.Item
              name="requiredNotifications"
              style={{ margin: 0 }}
            >
              <Checkbox checked disabled>
                {t("CommonProfile.Notifications.EnquiryNotifications")}
              </Checkbox>
            </Form.Item>
            <Form.Item
              // label={t("CommonProfile.Notifications.RequiredNotifications")}
              name="is_get_newsletter"
              valuePropName="checked"
            >
              <Checkbox>{t("CommonProfile.Notifications.Newsletter")}</Checkbox>
            </Form.Item>
            <Form.Item>
              <Form.Item
                name="language"
                label={t("CommonProfile.Notifications.Language")}
                rules={[
                  { required: true, message: t("ValidationErrors.Required") },
                ]}
              >
                <Select>
                  {Object.entries(enumLanguages).map(([key, value]) => (
                    <Select.Option key={value} value={value}>
                      {t("languages." + value)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form.Item>
            <CustomButton
              type="succes"
              htmlType="submit"
              size="large"
              onClick={handleButtonSaveClick}
              disabled={isLoadingSaveModifications}
              style={{ marginBottom: 0 }}
            >
              {t("Buttons.Save")}
            </CustomButton>
          </Form>
        </>
      </ProfileLayout>
    </>
  );
};

export default observer(NotificationsComponent);
