import { Avatar, Button, Col, Modal, Row, Space } from "antd";
import useStore from "hooks/useStore";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import CustomButton from "components/buttons/CustomButton";
import FacilityIcon from "components/icons/FacilityIcon";
import LeaveReview from "./components/AddComment";
import SpaceComments from "./components/SpaceComments";
import MainInfoSpace from "./components/MainInfoSpace";
import ImagesSpacePage from "./components/ImagesSpacePage";
import SpaceMap from "./components/SpaceMap";
import { Helmet } from "react-helmet";

/** Space page.
 * It's the main page to display the information about space.
 * it contains the sections:
 * - Images
 * - Main info (resume of space information and button to contact the manager of space)
 * - Description and terms
 * - Opening hours and price
 * - Facilities
 * - Map
 * - Comments
 */
function SpacePage() {
  const { t, i18n } = useTranslation();
  const { spacePseudo } = useParams<{ spacePseudo: string }>();
  const [isOpenModalTerms, setOpenModalTerms] = useState(false);
  const {
    spaceStore: { currentSpace },
    spaceStore,
  } = useStore();
  const { infoStore } = useStore();

  useEffect(() => {
    spaceStore.clearCurrentSpace();
    spaceStore.getAndSetCurrentSpace(spacePseudo!, i18n.language);

    return () => {
      spaceStore.clearCurrentSpace();
    };
  }, [i18n.language, spacePseudo, spaceStore]);

  useEffect(() => {
    infoStore.fetchFacilities(i18n.language);
  }, [i18n.language, infoStore]);

  if (!currentSpace) return <></>;

  return (
    <>
      <Helmet
        title={`${currentSpace.name} | ${currentSpace?.city} ${t(
          "SpacePage.PageHeadTitle"
        )}`}
        htmlAttributes={{ lang: i18n.language }}
        meta={[
          {
            name: `description`,
            content: t("SpacePage.PageHeadDescription"),
          },
        ]}
      />
      <Row gutter={[35, 25]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
          <ImagesSpacePage />
        </Col>
        {/* <Col xs={0} sm={0} md={0} lg={1} xl={1}></Col> */}
        <Col xs={24} sm={24} md={24} lg={24} xl={8}>
          <MainInfoSpace />
        </Col>
      </Row>
      <Col xs={24} sm={24} md={24} lg={24} xl={16}>
        <Row>
          <Col>
            <h5>{t("SpacePage.AboutSpace")}</h5>
            <p>{currentSpace?.description}</p>
            {currentSpace?.terms ? (
              <div
                className="clickable"
                onClick={() => setOpenModalTerms(true)}
              >
                {t("SpacePage.TermsAndConditions")}
              </div>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <h5>{t("SpacePage.OpeningHours")}</h5>
            <div>
              {" "}
              {currentSpace?.opening_hours.map((hour, index) => (
                <Row gutter={0} className="rowWithSeparator">
                  <Col span={8}>{t("Days." + hour.day)}</Col>
                  <Col
                    span={8}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {hour.getTimeFromTo()}
                  </Col>
                  <Col
                    span={8}
                    style={{
                      fontWeight: 500,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {t("SpacePage.From")} {hour.min_price}{" "}
                    {t("Texts.PricePerHour")}
                  </Col>
                </Row>
              ))}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <h5>{t("SpacePage.Facilities")}</h5>
            <div>
              {Object.entries(infoStore.getGroupedFacilitiesByType()).map(
                ([type, facilities]) => {
                  const facilitiesInCurrentSpace = facilities.filter(
                    (facility) =>
                      currentSpace?.ids_facilities.includes(Number(facility.id))
                  );

                  if (facilitiesInCurrentSpace.length > 0) {
                    return (
                      <div key={type}>
                        <h5>
                          {t("CommonAddModifySpace.FacilityType." + type)}
                        </h5>
                        <Row>
                          {facilitiesInCurrentSpace.map((facility) => (
                            <Col
                              xs={24}
                              sm={12}
                              md={12}
                              lg={8}
                              xl={8}
                              style={{ marginBottom: 8 }}
                            >
                              <Row
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexWrap: "nowrap",
                                }}
                              >
                                <FacilityIcon iconName={facility.icon} />
                                <span>{facility.name}</span>
                              </Row>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    );
                  }
                }
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>{t("SpacePage.AvailabilityCalendar")}</h5>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <h5>{t("SpacePage.Map")}</h5>
            <SpaceMap />
          </Col>
        </Row>
        <Row>
          <LeaveReview />
        </Row>
        <Row>
          <SpaceComments />
        </Row>
        {/* <Row>
          <Col>
            <h5>{t("SpacePage.OtherManagerLocation")}</h5>
            <div>locations here</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>{t("SpacePage.SimilarSpaces")}</h5>
            <div>Similar locations here</div>
          </Col>
        </Row> */}
      </Col>

      <Modal
        open={isOpenModalTerms}
        title={t("SpacePage.TermsAndConditions")}
        onCancel={() => setOpenModalTerms(false)}
        footer={null}
        transitionName=""
        width={800}
      >
        <p style={{ marginBottom: "35px" }}>{currentSpace?.terms}</p>
        <Row style={{ display: "flex", justifyContent: "end", gap: "20px" }}>
          <CustomButton
            type="default"
            size="large"
            onClick={() => setOpenModalTerms(false)}
          >
            {t("Buttons.Close")}
          </CustomButton>
        </Row>
      </Modal>
    </>
  );
}

export default observer(SpacePage);
