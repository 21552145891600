import { IMenuItem } from "components/responsiveMenu/ResponsiveMenu";
import { RouterPath, routerPathLocal } from "constants/RouterPath";

const menuCustomer = (language: string, t: (key: string) => string): IMenuItem[] => {
  return [
    {
      routerPath: routerPathLocal(
        language,
        RouterPath.CUST_MY_PROFILE
      ),
      text: t("CustomerProfile.Menu.PersonalDetails"),
    },
    {
      routerPath: routerPathLocal(
        language,
        RouterPath.CUST_ACCOUNT_SETTINGS
      ),
      text: t("CustomerProfile.Menu.AccountSettings"),
    },
    {
      routerPath: routerPathLocal(
        language,
        RouterPath.CUST_NOTIFICATIONS
      ),
      text: t("CustomerProfile.Menu.Notifications"),
    },
  ];
};

export default menuCustomer;