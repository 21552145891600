import { applySnapshot, flow, Instance, types } from 'mobx-state-tree'
import { requestStatus } from "constants/reqestStatus"
import UserService, { IGetUserListItemResponse } from 'services/UserService';
import { UserListItemModel } from 'states/models/User/UserListItemModel';


export const UserListStore = types
  .model({
    users: types.optional(types.map(UserListItemModel), {}),
    total: types.optional(types.number, -1),
    page: types.optional(types.number, -1),
    size: types.optional(types.number, -1),
    status: types.optional(
      types.enumeration<requestStatus>(Object.values(requestStatus)), requestStatus.INITIAL),

  })
  .views((self) => ({
    getUserListAsArray: () => Array.from(self.users.values())
  }))
  .actions((self) => ({
    clearUserList: (
    ) => {
      applySnapshot(self.users, {})
    }
  }))
  .actions((self) => ({
    setPage: (page: number
    ) => {
      self.page=page
    }
  }))
  .actions((self) => ({
    updateUserList: (
      users: Array<IGetUserListItemResponse>
    ) => {
      users.forEach((user) => {
        self.users.set(
          user.uuid,
          {
            uuid: user.uuid,
            full_name: user.full_name,
            is_mail_confirmed: user.is_mail_confirmed,
            email: user.email,
            role: user.role,
          }
        )
      })
    }
  }))
  .actions((self) => ({
    fetchUserListAsAdmin: flow(function* fetchUserListAsAdmin(page: number, size: number) {
      self.status = requestStatus.PENDING;
      try {
        const data = yield UserService.getUserListAsAdmin({
              page: page,
              size: size,
        });
        self.updateUserList(data.data.items);
        self.total = data.data.total
        self.size = data.data.size
        self.status = requestStatus.SUCCESS
      } catch
      {
                
        self.status = requestStatus.ERROR;
      }
    })
  }))


export type IUserListStore = Instance<typeof UserListStore>