import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Modal,
  Row,
  Space,
  Tooltip,
  notification,
} from "antd";
import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import useStore from "hooks/useStore";
import { ILoginData } from "services/AuthService";
import { requestStatus } from "constants/reqestStatus";
import { Link } from "react-router-dom";
import { RouterPath } from "constants/RouterPath";
import CustomButton from "components/buttons/CustomButton";
import { QuestionCircleOutlined } from "@ant-design/icons";
import MessengerService from "services/MessengerService";
import dayjs from "dayjs";
import { makeMessageFromRequestData } from "tools/formatters";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
}

interface FormProps {
  email: string;
  password: string;
}

const ModalMakeRequest: React.FC<Props> = ({ isOpen, closeModal }) => {
  const { auth } = useStore();
  const [loading, setLoading] = useState(false);
  const {
    spaceStore: { currentSpace },
  } = useStore();

  const [form] = Form.useForm();
  const { t } = useTranslation();

  const onReset = () => {
    form.resetFields();
  };

  const handleClose = () => {
    onReset();
    closeModal();
  };

  const handleOk = async () => {
    await form.validateFields();
    let formattedMessage = makeMessageFromRequestData({
      date: dayjs(form.getFieldValue("date")).format("YYYY-MM-DD"),
      numberParticipants: form.getFieldValue("participant_number"),
      message: form.getFieldValue("message"),
      spaceName: currentSpace!.name,
    });

    setLoading(true);
    try {
      await MessengerService.postCreateMessage({
        id_user_to: currentSpace!.id_user_manager,
        message: formattedMessage,
      });
      notification.success({
        message: t("Notifications.MessageSent.Title"),
        description: t("Notifications.MessageSent.Description"),
        placement: "bottomRight",
      });
      handleClose();
    } catch {
      notification.error({
        message: t("NotificationErrors.Error.Title"),
        description: t("NotificationErrors.Error.Description"),
        placement: "bottomRight",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        open={isOpen}
        title={t("Modals.MakeRequest.Title")}
        onCancel={handleClose}
        destroyOnClose
        footer={null}
        transitionName=""
        width={464}
      >
        <p>{t("We needs these details for booking")}</p>
        <Form layout="vertical" form={form}>
          <Form.Item
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                <span>{t("Modals.MakeRequest.Date")}</span>
                <Tooltip title={t("Modals.MakeRequest.DateTooltip")}>
                  <QuestionCircleOutlined style={{ marginLeft: "5px" }} />
                </Tooltip>
              </div>
            }
            name="date"
            rules={[
              {
                required: true,
                validateTrigger: "onFinish",
                message: t("ValidationErrors.Required"),
                validator: (_, value) => {
                  return value && value.isValid()
                    ? Promise.resolve()
                    : Promise.reject();
                },
              },
            ]}
          >
            <DatePicker placeholder="" style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label={t("Modals.MakeRequest.ParticipantNumber")}
            name="participant_number"
            rules={[
              {
                required: true,
                message: t("ValidationErrors.Required"),
                validateTrigger: "onFinish",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label={t("Modals.MakeRequest.Message")}
            name="message"
            rules={[
              {
                required: true,
                message: t("ValidationErrors.Required"),
                validateTrigger: "onFinish",
              },
            ]}
          >
            <Input.TextArea
              rows={8}
              placeholder={t("Modals.MakeRequest.MessagePlaceholder")}
            />
          </Form.Item>
          {/* <Form.Item> */}
          <Row style={{ display: "flex", justifyContent: "end", gap: "20px" }}>
            <CustomButton
              type="default"
              size="large"
              onClick={handleClose}
              // style={{ width: "100%" }}
            >
              {t("Buttons.Close")}
            </CustomButton>
            <CustomButton
              type="succes"
              size="large"
              // htmlType="submit"
              onClick={() => {
                handleOk();
              }}
              disabled={loading}
              // style={{ width: "100%" }}
            >
              {t("Buttons.Send")}
            </CustomButton>
          </Row>
          {/* </Form.Item> */}
        </Form>
      </Modal>
    </>
  );
};

export default observer(ModalMakeRequest);
