import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Select,
  Checkbox,
  notification,
  Col,
  Row,
  Switch,
  Tooltip,
} from "antd";
import SpaceApiService, {
  IImages,
  IOpeningHours,
  ISpaceCreate,
  ISpaceInfoProfile,
} from "services/SpaceService";
import useStore from "hooks/useStore";
import { sortedArraySpaceType } from "constants/SpaceType";
import UploadImages from "./components/UploadImages";
import TimeForm from "./components/TimeForm";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { useNavigate } from "react-router-dom";
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import CustomButton from "components/buttons/CustomButton";
import { mappedArraySpaceState, spaceState } from "constants/spaceState";
import { enumUserRole } from "constants/User";
import DeleteSpaceSection from "./components/DeleteSpaceSection";
import { CustomH1 } from "components/typography/CustomH";
const { Option } = Select;

interface AddModifySpaceProps {
  isCreation: boolean;
  initialData?: ISpaceInfoProfile;
  spacePseudo?: string;
}

/** Space page for modifing or adding a space.
 * It's used for:
 * 1. Creation of space by User Manager
 * 2. Modification of Space by User Manager
 * 3. Modification of Space by User Admin
 * Params:
 * - isCreation - indicate if it's creation, if False - it's modification
 * - initialData - optional dict with initial data for form
 */

function AddModifySpace({
  isCreation,
  spacePseudo,
}: AddModifySpaceProps): JSX.Element {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [formAdmin] = Form.useForm();
  const [formManager] = Form.useForm();
  const { t, i18n } = useTranslation();
  const { infoStore } = useStore();
  const { auth } = useStore();
  const [spaceName, setSpaceName] = useState<string>("");
  const [isModificationStateDisabled, setIsModificationStateDisabled] =
    useState<boolean>(true);
  const [selectedEvents, setSelectedEvents] = useState<string[]>([]);
  const [loadingCreateSpace, setLoadingCreateSpace] = useState(false);
  const [idImageFiles, setIdImageFiles] = useState<number[]>([]);
  const [initialImages, setInitialImages] = useState<IImages[]>([]);
  const [initialOpeningHours, setInitialOpeningHours] = useState<
    IOpeningHours[]
  >([]);
  // const [idImageSelectedCover, setIdImageSelectedCover] = useState<
  //   number | null
  // >(null);
  const [idInitialImageSelectedCover, setIdInitialImageSelectedCover] =
    useState<number | null>(null);
  const [parentFormData, setParentFormData] = useState([]);
  const [selectedFacilities, setSelectedFacilities] = useState<
    CheckboxValueType[]
  >([]);

  const handleFacilityChange = (checkedValues: CheckboxValueType[]) => {
    setSelectedFacilities(checkedValues);
  };

  const handleFormData = (formData) => {
    setParentFormData(formData);
  };

  useEffect(() => {
    infoStore.fetchCountries();
  }, [infoStore]);

  useEffect(() => {
    infoStore.fetchRegions(i18n.language);
  }, [i18n.language, infoStore]);

  useEffect(() => {
    infoStore.fetchFacilities(i18n.language);
  }, [i18n.language, infoStore]);

  useEffect(() => {
    infoStore.fetchEvents(i18n.language);
  }, [i18n.language, infoStore]);

  const setInitialData = (initialData: ISpaceInfoProfile) => {
    setInitialImages(initialData.images);
    setSelectedEvents(initialData.ids_events.map((id) => String(id)));
    setInitialOpeningHours(initialData.opening_hours);
    setSelectedFacilities(initialData.ids_facilities);

    // For Manager Form
    setSpaceName(initialData.name);
    setIsModificationStateDisabled(initialData.state === spaceState.WAITING_FOR_VALIDATION);

    formAdmin.setFields([
      {
        name: "is_visible",
        value: initialData.is_visible,
      },
      {
        name: "state",
        value: initialData.state,
      },
      {
        name: "geo_lat",
        value: initialData.geo_lat,
      },
      {
        name: "geo_long",
        value: initialData.geo_long,
      },
    ]);

    formManager.setFields([
      {
        name: "state",
        value: initialData.state,
      },
      {
        name: "is_visible",
        value: initialData.is_visible
          ? t("CommonAddModifySpace.ModerationStatusApprouved")
          : t("CommonAddModifySpace.ModerationStatusNotApprouved"),
      },
    ]);

    form.setFields([
      {
        name: "name",
        value: initialData.name,
      },
      {
        name: "type",
        value: initialData.type,
      },
      {
        name: "min_booking_time",
        value: initialData.min_booking_time,
      },
      {
        name: "events",
        value: initialData.ids_events.map((id) => String(id)),
      },
      {
        name: "country",
        value: String(initialData.id_country),
      },
      {
        name: "region",
        value: String(initialData.id_region),
      },
      {
        name: "city",
        value: initialData.city,
      },
      {
        name: "zip_code",
        value: initialData.zip_code,
      },
      {
        name: "metro",
        value: initialData.metro,
      },
      {
        name: "address",
        value: initialData.address,
      },
      {
        name: "additional_info_address",
        value: initialData.additional_info_address,
      },
      {
        name: "seat_number",
        value: initialData.seat_number,
      },
      {
        name: "standing_number",
        value: initialData.standing_number,
      },
      {
        name: "floor_area",
        value: initialData.floor_area,
      },
      {
        name: "min_spend",
        value: initialData.min_spend,
      },
      // timeform TODO
      {
        name: "description_en",
        value: initialData.description_translation.en,
      },
      {
        name: "description_fr",
        value: initialData.description_translation.fr,
      },
      {
        name: "terms_en",
        value: initialData.terms_translation.en,
      },
      {
        name: "terms_fr",
        value: initialData.terms_translation.fr,
      },
      {
        name: "facilities",
        value: initialData.ids_facilities.map((id) => String(id)),
      },
    ]);
  };

  // Fetch initial data if not creation
  useEffect(() => {
    if (!isCreation) {
      SpaceApiService.getSpaceInfoProfile({ space_pseudo: spacePseudo })
        .then((response) => {
          const initialData = response.data;
          setInitialData(initialData);
        })
        .catch((error) => {
          navigate(routerPathLocal(i18n.language, RouterPath.LINK_NOT_VALID));
        });
    }
  }, [i18n.language, isCreation, navigate, spacePseudo]);

  const clearErrorMessages = (fieldNames: string[]) => {
    const fieldsToClear = fieldNames.map((fieldName) => ({
      name: fieldName,
      errors: [],
    }));
    form.setFields(fieldsToClear);
  };

  const customValidate = () => {
    clearErrorMessages(["images"]);
    clearErrorMessages(["open_time"]);
    let isValid = true;

    // Check if there are images
    if (idImageFiles.length < 4) {
      form.setFields([
        {
          name: "images",
          errors: [t("CommonAddModifySpace.ValidationErrorAtLeast4Images")],
        },
      ]);
      isValid = false;
    }

    // Check that there is at least one row in open_time
    if (parentFormData.length < 1) {
      isValid = false;
      form.setFields([
        {
          name: "open_time",
          errors: ["There must be at least one row"],
        },
      ]);
    }

    // Check that time_from is less than time_to for each row
    // parentFormData.forEach((row, index) => {
    //   if (
    //     moment(row.time_to, "HH:mm").isBefore(moment(row.time_from, "HH:mm"))
    //   ) {
    //     isValid = false;
    //     form.setFields([
    //       {
    //         name: `rows[${index}].time_to`,
    //         // name: "name",
    //         errors: ["Time to should be greater than time from"],
    //       },
    //     ]);
    //   }
    // });

    // Check that each day is unique
    // const uniqueDays = new Set();
    // parentFormData.forEach((row, index) => {
    //   if (uniqueDays.has(row.day)) {
    //     isValid = false;
    //     form.setFields([
    //       {
    //         name: `rows[${index}].day`,
    //         errors: ["Each day must be unique"],
    //       },
    //     ]);
    //   } else {
    //     uniqueDays.add(row.day);
    //   }
    // });
    return isValid;
  };

  const handleClickSaveInfoAdmin = async () => {
    await formAdmin.validateFields();
    let postData = {
      state: formAdmin.getFieldValue("state"),
      is_visible: formAdmin.getFieldValue("is_visible"),
      geo_lat: Number(formAdmin.getFieldValue("geo_lat")),
      geo_long: Number(formAdmin.getFieldValue("geo_long")),
    };

    setLoadingCreateSpace(true);
    try {
      await SpaceApiService.putModifySpaceAsAdmin(postData, spacePseudo!);
      notification.success({
        message: t("Notifications.Saved.Title"),
        description: t("Notifications.Saved.Description"),
        placement: "bottomRight",
      });
    } catch {
      notification.error({
        message: t("NotificationErrors.Error.Title"),
        description: t("NotificationErrors.Error.Description"),
        placement: "bottomRight",
      });
    } finally {
      setLoadingCreateSpace(false);
    }
  };

  const handleClickSaveInfoManager = async () => {
    await formManager.validateFields();
    let postData = {
      state: formManager.getFieldValue("state"),
    };

    setLoadingCreateSpace(true);
    try {
      await SpaceApiService.putModifySpaceAsManager(postData, spacePseudo!);
      notification.success({
        message: t("Notifications.Saved.Title"),
        description: t("Notifications.Saved.Description"),
        placement: "bottomRight",
      });
    } catch {
      notification.error({
        message: t("NotificationErrors.Error.Title"),
        description: t("NotificationErrors.Error.Description"),
        placement: "bottomRight",
      });
    } finally {
      setLoadingCreateSpace(false);
    }
  };

  const onFinish = async () => {
    let isValid = customValidate();

    try { await form.validateFields(); }
    catch {
      isValid = false;
    }

    if (!isValid) {
      notification.error({
        message: t("NotificationErrors.RequiredFieldsToComplete.Title"),
        description: t(
          "NotificationErrors.RequiredFieldsToComplete.Description"
        ),
        placement: "bottomRight",
      });
      return;
    }

    setLoadingCreateSpace(true);

    let postData: ISpaceCreate = {
      name: form.getFieldValue("name"),
      type: form.getFieldValue("type"),
      min_booking_time: form.getFieldValue("min_booking_time"),
      city: form.getFieldValue("city"),
      id_country: form.getFieldValue("country"),
      id_region: form.getFieldValue("region"),
      zip_code: form.getFieldValue("zip_code"),
      metro: form.getFieldValue("metro"),
      address: form.getFieldValue("address"),
      additional_info_address: form.getFieldValue("additional_info_address"),
      seat_number: form.getFieldValue("seat_number"),
      standing_number: form.getFieldValue("standing_number"),
      floor_area: form.getFieldValue("floor_area"),
      min_spend: form.getFieldValue("min_spend"),
      description_translation: {
        en: form.getFieldValue("description_en"),
        fr: form.getFieldValue("description_fr"),
      },
      terms_translation: {
        en: form.getFieldValue("terms_en"),
        fr: form.getFieldValue("terms_fr"),
      },
      opening_hours: parentFormData,
      ids_images: idImageFiles,
      ids_facilities: selectedFacilities,
      ids_events: selectedEvents,
    };
    if (isCreation) {
      try {
        await SpaceApiService.postCreateSpace(postData);
      } catch {
        notification.error({
          message: t("NotificationErrors.Error.Title"),
          description: t("NotificationErrors.Error.Description"),
          placement: "bottomRight",
        });
      } finally {
        setLoadingCreateSpace(false);
        navigate(
          routerPathLocal(
            i18n.language,
            RouterPath.MANAGER_MY_SPACES_WAITING_FOR_VALIDATION
          )
        );
      }
    } else {
      try {
        await SpaceApiService.putModifySpace(postData, spacePseudo!);
        notification.success({
          message: t("Notifications.Saved.Title"),
          description: t("Notifications.Saved.Description"),
          placement: "bottomRight",
        });
      } catch {
        notification.error({
          message: t("NotificationErrors.Error.Title"),
          description: t("NotificationErrors.Error.Description"),
          placement: "bottomRight",
        });
      } finally {
        setLoadingCreateSpace(false);
      }
    }
  };

  const handleSelect = (value: string) => {
    if (!infoStore.getEventsIdsAsArray().includes(value)) {
      return;
    }
    setSelectedEvents([...selectedEvents, value]);
  };

  const handleDeselect = (value: string) => {
    setSelectedEvents(selectedEvents.filter((event) => event !== value));
  };

  return (
    <>
      <CustomH1>
        {isCreation
          ? t("CommonAddModifySpace.TitleAdd")
          : t("CommonAddModifySpace.TitleModify") + " - " + spaceName}
      </CustomH1>
      {auth.currentUser?.role === enumUserRole.ADMIN ? (
        <>
          <Form form={formAdmin} layout="vertical">
            <Form.Item
              name="state"
              label={t("CommonAddModifySpace.State")}
              rules={[
                { required: true, message: t("ValidationErrors.Required") },
              ]}
            >
              <Select>
                {mappedArraySpaceState.map((spaceState) => (
                  <Option key={spaceState.key} value={spaceState.key}>
                    {t("SpaceStates." + spaceState.value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="is_visible"
              label={t("CommonAddModifySpace.Visibility")}
              rules={[
                { required: true, message: t("ValidationErrors.Required") },
              ]}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              name="geo_lat"
              label={t("CommonAddModifySpace.Latitude")}
              rules={[
                { required: true, message: t("ValidationErrors.Required") },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="geo_long"
              label={t("CommonAddModifySpace.Longitude")}
              rules={[
                { required: true, message: t("ValidationErrors.Required") },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
          <CustomButton
            disabled={loadingCreateSpace}
            type="succes"
            size="large"
            htmlType="submit"
            onClick={handleClickSaveInfoAdmin}
            style={{ marginBottom: "40px" }}
          >
            {t("CommonAddModifySpace.ButtonSave")}
          </CustomButton>
        </>
      ) : (
        <></>
      )}
      {auth.currentUser?.role === enumUserRole.MANAGER && !isCreation ? (
        <>
          <Form form={formManager} layout="vertical">
            <Form.Item
              name="state"
              label={t("CommonAddModifySpace.State")}
              rules={[
                { required: true, message: t("ValidationErrors.Required") },
              ]}
            >
              <Select disabled={isModificationStateDisabled}>
                {isModificationStateDisabled ? (
                  <Option
                    key={spaceState.WAITING_FOR_VALIDATION}
                    value={spaceState.WAITING_FOR_VALIDATION}
                  >
                    {t("SpaceStates." + spaceState.WAITING_FOR_VALIDATION)}
                  </Option>
                ) : undefined}
                <Option key={spaceState.PUBLISHED} value={spaceState.PUBLISHED}>
                  {t("SpaceStates." + spaceState.PUBLISHED)}
                </Option>
                <Option
                  key={spaceState.UNPUBLISHED}
                  value={spaceState.UNPUBLISHED}
                >
                  {t("SpaceStates." + spaceState.UNPUBLISHED)}
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="is_visible"
              label={
                <span>
                  {t("CommonAddModifySpace.ModerationStatus")}
                  <Tooltip
                    title={t("CommonAddModifySpace.ModerationStatusTooltip")}
                  >
                    <QuestionCircleOutlined style={{ marginLeft: "5px" }} />
                  </Tooltip>
                </span>
              }
              // initialValue={
              //   isVisible
              //     ? t("CommonAddModifySpace.ModerationStatusApprouved")
              //     : t("CommonAddModifySpace.ModerationStatusNotApprouved")
              // }
            >
              <Input disabled />
            </Form.Item>
          </Form>
          <CustomButton
            disabled={loadingCreateSpace}
            type="succes"
            size="large"
            htmlType="submit"
            onClick={handleClickSaveInfoManager}
            style={{ marginBottom: "40px" }}
          >
            {t("CommonAddModifySpace.ButtonSave")}
          </CustomButton>
        </>
      ) : (
        <></>
      )}
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label={t("CommonAddModifySpace.Name")}
          rules={[{ required: true, message: t("ValidationErrors.Required") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="type"
          label={t("CommonAddModifySpace.Type")}
          rules={[{ required: true, message: t("ValidationErrors.Required") }]}
        >
          <Select>
            {sortedArraySpaceType(t).map((spaceType) => (
              <Option key={spaceType.key} value={spaceType.key}>
                {t("SpaceTypes." + spaceType.value)}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="images" label={t("CommonAddModifySpace.Images")}>
          <UploadImages
            initialValue={initialImages}
            onImageListChange={setIdImageFiles}
          />
        </Form.Item>
        <Form.Item
          name="events"
          label={t("CommonAddModifySpace.Events")}
          rules={[{ required: true, message: t("ValidationErrors.Required") }]}
        >
          <Select
            mode="multiple"
            // placeholder="Select events"
            onSelect={handleSelect}
            onDeselect={handleDeselect}
            value={selectedEvents}
            // defaultValue={["1"]}
          >
            {infoStore.getEventsAsArray().map((event) => (
              <Option key={event.id} value={event.id}>
                {event.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="country"
          label={t("CommonAddModifySpace.Country")}
          rules={[{ required: true, message: t("ValidationErrors.Required") }]}
          // initialValue={"Italia"} //TODO DELETE
        >
          <Select>
            {infoStore.getCountriesAsArray().map((country) => (
              <Option key={country.id} value={country.id}>
                {country.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="region"
          label={t("CommonAddModifySpace.Region")}
          rules={[{ required: true, message: t("ValidationErrors.Required") }]}
          //TODO add region
        >
          <Select>
            {infoStore.getRegionsAsArray().map((region) => (
              <Option key={region.id} value={region.id}>
                {region.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="city"
          label={t("CommonAddModifySpace.City")}
          rules={[{ required: true, message: t("ValidationErrors.Required") }]}
          // initialValue={"Paris"} //TODO DELETE
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="zip_code"
          label={t("CommonAddModifySpace.ZipCode")}
          rules={[{ required: true, message: t("ValidationErrors.Required") }]}
          // initialValue={"44444"} //TODO DELETE
        >
          <Input />
        </Form.Item>
        <Form.Item name="metro" label={t("CommonAddModifySpace.Metro")}>
          <Input />
        </Form.Item>
        <Form.Item
          name="address"
          label={t("CommonAddModifySpace.Address")}
          rules={[{ required: true, message: t("ValidationErrors.Required") }]}
          // initialValue={"4"} //TODO DELETE
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="additional_info_address"
          label={t("CommonAddModifySpace.AdditionalInfoAddress")}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="seat_number"
          label={t("CommonAddModifySpace.SeatNumber")}
          rules={[{ required: true, message: t("ValidationErrors.Required") }]}
          // initialValue={4} //TODO DELETE
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          name="standing_number"
          label={t("CommonAddModifySpace.StandingNumber")}
          rules={[{ required: true, message: t("ValidationErrors.Required") }]}
          // initialValue={4} //TODO DELETE
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          name="floor_area"
          label={t("CommonAddModifySpace.FloorArea")}
          rules={[{ required: true, message: t("ValidationErrors.Required") }]}
          // initialValue={4} //TODO DELETE
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          name="min_spend"
          label={t("CommonAddModifySpace.MinimumSpend")}
          rules={[{ required: true, message: t("ValidationErrors.Required") }]}
          // initialValue={4} //TODO DELETE
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          name="min_booking_time"
          label={t("CommonAddModifySpace.MinBookingTime")}
          rules={[{ required: true, message: t("ValidationErrors.Required") }]}
          // initialValue={4} //TODO DELETE
        >
          <Input type="number" />
        </Form.Item>
      </Form>
      <TimeForm
        form={form}
        onFormDataChange={handleFormData}
        initialOpeningHours={initialOpeningHours}
      />

      <Form form={form} layout="vertical">
        <Form.Item
          name="description_en"
          label={t("CommonAddModifySpace.DescriptionEN")}
          rules={[{ required: true, message: t("ValidationErrors.Required") }]}
          // initialValue="Default value for French terms and conditions" //TODO DELETE
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item
          name="description_fr"
          label={t("CommonAddModifySpace.DescriptionFR")}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item name="terms_en" label={t("CommonAddModifySpace.TermsEN")}>
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item name="terms_fr" label={t("CommonAddModifySpace.TermsFR")}>
          <Input.TextArea rows={4} />
        </Form.Item>
        {/* <Form.Item></Form.Item> */}
        <Form.Item
          name="facilities"
          label={t("CommonAddModifySpace.Facilities")}
        >
          <Checkbox.Group
            onChange={handleFacilityChange}
            style={{ flexDirection: "column" }}
          >
            {Object.entries(infoStore.getGroupedFacilitiesByType()).map(
              ([type, facilities]) => (
                <div key={type}>
                  <h5>{t("CommonAddModifySpace.FacilityType." + type)}</h5>
                  <Row>
                    {facilities.map((facility) => (
                      <Col span={8} style={{ marginBottom: 8 }}>
                        <Checkbox key={facility.id} value={facility.id}>
                          {facility.name}
                        </Checkbox>
                      </Col>
                    ))}
                  </Row>
                </div>
              )
            )}
          </Checkbox.Group>
        </Form.Item>
      </Form>
      {/* <Button onClick={buttonClick}>Submit222</Button> */}
      <CustomButton
        disabled={loadingCreateSpace}
        type="succes"
        size="large"
        htmlType="submit"
        onClick={onFinish}
        style={{ marginBottom: "40px" }}
      >
        {isCreation
          ? t("CommonAddModifySpace.ButtonCreate")
          : t("CommonAddModifySpace.ButtonSave")}
      </CustomButton>
      {!isCreation ? (
        <DeleteSpaceSection spacePseudo={spacePseudo!} spaceName={spaceName} />
      ) : (
        <></>
      )}
    </>
  );
}

export default observer(AddModifySpace);
