import './App.css';
import { ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import localizeAntd from './tools/localizeAntd';
import { Provider, rootStore } from 'states/Root';
import { BrowserRouter } from "react-router-dom";
import AppRoutes from 'routes/AppRoutes'
import { theme } from 'assets/theme/theme';

function App(): JSX.Element {
  const { i18n } = useTranslation();
  const locale = localizeAntd(i18n.language)
  return (
    <Provider value={rootStore}>
      <ConfigProvider locale={locale} theme={theme}>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </ConfigProvider>
    </Provider>)
}

export default App;
