import { Form, Input, notification } from "antd";
import CustomButton from "components/buttons/CustomButton";
import ProfileLayout from "components/profileLayout/ProfileLayout";
import useStore from "hooks/useStore";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import UserApiService, {
  IUpdateUserInformationAPI,
} from "services/UserService";
import AvatarUpload from "./components/avatarUpload";
import { IMenuItem } from "components/responsiveMenu/ResponsiveMenu";
import { CustomH1 } from "components/typography/CustomH";

interface MyProfileComponentProps {
  menu: IMenuItem[];
}

/** My profile component is a page to modify user information.
 * It's common to Customer, Manager and Admin spaces.
 */
const MyProfileComponent: React.FC<MyProfileComponentProps> = ({ menu }) => {
  const { t } = useTranslation();
  const { auth } = useStore();
  const [form] = Form.useForm();
  const [isLoadingChangeInfo, setIsLoadingChangeInfo] = useState(false);

  const handleChangeInformation = async (values: IUpdateUserInformationAPI) => {
    setIsLoadingChangeInfo(true);
    try {
      await UserApiService.postUpdateUserInfo({
        first_name: values.first_name,
        last_name: values.last_name,
        tel: values.tel,
      }).then(() => {
        auth!.loadUserInfo();
        notification.success({
          message: t("CommonProfile.MyProfile.NotificationSuccessTitle"),
          description: t(
            "CommonProfile.MyProfile.NotificationSuccessDescription"
          ),
          placement: "bottomRight",
        });
      });
    } catch (error) {
      notification.error({
        message: t("NotificationErrors.Error.Title"),
        description: t("NotificationErrors.Error.Description"),
        placement: "bottomRight",
      });
    }
    setIsLoadingChangeInfo(false);
  };

  if (!auth.currentUser) {
    return <></>;
  }

  return (
    <>
      <ProfileLayout menu={menu}>
        <>
          <CustomH1>{t("CommonProfile.MyProfile.PageTitle")}</CustomH1>
          <div style={{ marginBottom: "27px" }}>
            <AvatarUpload />
          </div>
          <div className="form-title">
            {t("CommonProfile.MyProfile.InfoTitle")}
          </div>
          <Form layout="vertical" form={form}>
            <Form.Item
              label={t("CommonProfile.MyProfile.FirstName")}
              name="first_name"
              initialValue={auth.currentUser!.firstName || ""}
              rules={[
                {
                  required: true,
                  type: "string",
                  min: 1,
                  validateTrigger: "onFinish",
                  message: t("ValidationErrors.Required"),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("CommonProfile.MyProfile.LastName")}
              name="last_name"
              initialValue={auth.currentUser!.lastName || ""}
              rules={[
                {
                  required: true,
                  type: "string",
                  min: 1,
                  validateTrigger: "onFinish",
                  message: t("ValidationErrors.Required"),
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t("CommonProfile.MyProfile.Email")}
              name="email"
              initialValue={auth.currentUser!.email}
              rules={[]}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              initialValue={auth.currentUser!.tel || ""}
              label={t("CommonProfile.MyProfile.Tel")}
              name="tel"
              rules={[]}
            >
              <Input />
            </Form.Item>

            <CustomButton
              type="succes"
              htmlType="submit"
              size="large"
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => handleChangeInformation(values));
              }}
              disabled={isLoadingChangeInfo}
              style={{ marginBottom: 30 }}
            >
              {t("CommonProfile.MyProfile.SaveInfoButton")}
            </CustomButton>
          </Form>
        </>
      </ProfileLayout>
    </>
  );
};

export default observer(MyProfileComponent);
