import {  Instance, types } from 'mobx-state-tree'


export const Message = types
  .model({
    is_my_message: types.boolean,
    message: types.string,
    date_creation: types.string,
  })


export type IMessage = Instance<typeof Message>
