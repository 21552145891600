import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Button, notification, Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import ApiService from "services/AuthService";
import styled from "styled-components";
import CustomButton from "components/buttons/CustomButton";

export interface IEmailData {
  email: string;
}

/** Page with form I forgot my password */
export default function ForgotPassword() {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinish = async (values: IEmailData) => {
    setLoading(true);
    try {
      ApiService.postResetPassword(values.email, i18n.language);
      navigate(
        routerPathLocal(i18n.language, RouterPath.FORGOT_PASSWORD_MAIL_SENT)
      );
    } catch (error) {
      notification.error({
        message: t("NotificationErrors.Error.Title"),
        description: t("NotificationErrors.Error.Description"),
        placement: "bottomRight",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row style={{ height: "100%", width: "100%", justifyContent: "center" }}>
      <Col xs={24} sm={20} md={16} lg={12} xl={8} style={{ paddingTop: "50px" }}>
        <StyledH2>{t("ForgotPassword.ForgotPassword")}</StyledH2>
        <Form
          layout="vertical"
          form={form}
          onFinish={() => {
            form.validateFields().then((values) => onFinish(values));
          }}
        >
          <p>{t("ForgotPassword.Text")}</p>
          <Form.Item
            label={t("ForgotPassword.Email")}
            name="email"
            rules={[
              {
                required: true,
                message: t("ValidationErrors.Required"),
              },
              {
                type: "email",
                message: t("ValidationErrors.EmailInvalid"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <CustomButton
              style={{ width: "100%" }}
              type="primary"
              size="large"
              htmlType="submit"
              loading={loading}
            >
              {t("ForgotPassword.ResetPassword")}
            </CustomButton>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

const StyledH2 = styled.h2`
  font-family: "Ranade-Variable";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 113%;
  /* or 45px */

  letter-spacing: -0.04em;

  color: #000000;
  margin-top: 0px;
  margin-bottom: 30px;
`;
