import React, { useEffect, useState } from "react";
import { Upload, Button, message, Row, Col } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import { Link, useNavigate } from "react-router-dom";
import logoImage from "assets/images/logoAstudium.svg";
import styled from "styled-components";

export default function CustomFooter(): JSX.Element {
  const { t, i18n } = useTranslation();

  return (
    <>
      <StyledRowFooter>
        <StyledRow>
          <Col xs={6} sm={6} md={5} lg={6} xl={6}>
            <StyledLogoImage src={logoImage} alt="" />
          </Col>
          <StyledMiddleCol xs={18} sm={18} md={13} lg={12} xl={12}>
            <StyledLink
              to={routerPathLocal(i18n.language, RouterPath.PRIVACY_POLICY)}
            >
              {t("Footer.PrivacyPolicy")}
            </StyledLink>
            <StyledLink
              to={routerPathLocal(
                i18n.language,
                RouterPath.TERMS_AND_CONDITIONS
              )}
            >
              {t("Footer.TermsAndConditions")}
            </StyledLink>
            <StyledLink
              to={routerPathLocal(i18n.language, RouterPath.PARTNERSHIP)}
            >
              {t("Footer.Partnership")}
            </StyledLink>
          </StyledMiddleCol>
          <StyledThirdCol xs={24} sm={24} md={6} lg={6} xl={6}>
            {t("Footer.AllRightsReserved")}
          </StyledThirdCol>
        </StyledRow>
      </StyledRowFooter>
    </>
  );
}

const StyledLink = styled(Link)`
  color: #000;
`;

const StyledRowFooter = styled(Row)`
  align-items: center;
  border-radius: 10px;
  background-color: #f9f9f9;
  z-index: 2;
  width: 100%;
  position: relative;
  padding: 15px 30px;
  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    align-items: flex-start;
  }
`;

const StyledRow = styled(Row)`
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    align-items: flex-start;
  }
`;

const StyledLogoImage = styled.img`
  width: 105px;
  height: 63px;

  @media (max-width: 768px) {
    width: 74px;
    height: 28px;
  }
`;

const StyledMiddleCol = styled(Col)`
  display: flex;
  gap: 20px;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
  }
`;

const StyledThirdCol = styled(Col)`
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    padding-top: 30px;
    flex-direction: column;
    align-items: flex-end;
  }
`;
