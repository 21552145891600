import { useState, useEffect, useRef } from "react";
import { Carousel, Col, Row, Space, Rate } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import useStore from "hooks/useStore";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import CustomButton from "components/buttons/CustomButton";

import { ReactComponent as SittingIcon } from "assets/images/sittingNumber.svg";
import { ReactComponent as StandingIcon } from "assets/images/standingNumber.svg";
import { ReactComponent as FloorAreaIcon } from "assets/images/floorArea.svg";

import { ReactComponent as Heart } from "assets/images/heart.svg";
import { ReactComponent as CloseBtnIcon } from "assets/images/closeBtn.svg";
import { ReactComponent as SliderBtnIcon } from "assets/images/sliderRowBtnRight.svg";

import ModalLogin from "components/modals/modalLogin/ModalLogin";
import ModalMakeRequest from "components/modals/modalMakeRequest/ModalMakeRequest";

export interface spaceCardPreviewProps {
  pseudo: string;
  closeWindow: (value: null | number) => void;
  expand: boolean;
}

/** Space card Preview. It's used on the map on click on marker.*/
function SpaceCardPreview(props: spaceCardPreviewProps): JSX.Element {
  const { pseudo, closeWindow, expand } = props;
  const {
    spaceMiniInfoStore: { currentSpaceMiniInfo },
    spaceMiniInfoStore,
    auth,
    spaceListStore,
  } = useStore();
  const { t, i18n } = useTranslation();
  const [showSliderButtons, setShowSliderButtons] = useState<boolean>(false);

  const [isOpenModalMakeRequest, setIsOpenModalMakeRequest] = useState(false);
  const [isOpenModalLogin, setOpenModalLogin] = useState(false);

  const carouselRef: any = useRef(null);

  useEffect(() => {
    spaceMiniInfoStore.clearCurrentSpaceMiniInfo();
    spaceMiniInfoStore.getAndSetCurrentSpaceMiniInfo(pseudo, i18n.language);
    return () => {
      spaceMiniInfoStore.clearCurrentSpaceMiniInfo();
    };
  }, [i18n.language, pseudo, spaceMiniInfoStore]);

  if (!currentSpaceMiniInfo) return <></>;

  const handleFavoriteButtonClick = () => {
    if (auth.currentUser) {
      if (currentSpaceMiniInfo.is_favorite === true) {
        currentSpaceMiniInfo.setIsFavorite(false);
        spaceListStore.setSpaceIsFavorite(
          String(currentSpaceMiniInfo.id),
          false
        );
      } else if (currentSpaceMiniInfo.is_favorite === false) {
        currentSpaceMiniInfo.setIsFavorite(true);
        spaceListStore.setSpaceIsFavorite(
          String(currentSpaceMiniInfo.id),
          true
        );
      }
    } else {
      setOpenModalLogin(true);
    }
  };

  const handleLeftClick = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    carouselRef.current?.prev();
  };

  const handleRightClick = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    carouselRef.current?.next();
  };

  return (
    <StyledMain
      onMouseOver={() => setShowSliderButtons(true)}
      onMouseOut={() => setShowSliderButtons(false)}
    >
      <StyledSlider>
        <StyledCarousel ref={carouselRef} dots={true}>
          {currentSpaceMiniInfo?.getImagesAsArray().map((image) => (
            <CarouselImageContainer key={image.id_image_file}>
              <CarouselImage src={image.url} alt={`Space image`} />
            </CarouselImageContainer>
          ))}
        </StyledCarousel>
        {(showSliderButtons &&
          currentSpaceMiniInfo?.getImagesAsArray().length &&
          currentSpaceMiniInfo?.getImagesAsArray().length > 1) ||
        expand ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <CarouselClickArea
              className="left"
              style={{ alignItems: "flex-start" }}
            >
              <SliderButton
                onClick={handleLeftClick}
                style={{ pointerEvents: "auto" }}
              >
                {" "}
                <SliderBtnIcon style={{ transform: "rotate(-180deg)" }} />
              </SliderButton>
            </CarouselClickArea>
            <CarouselClickArea
              className="right"
              style={{ alignItems: "flex-end" }}
            >
              <SliderButton
                onClick={handleRightClick}
                style={{ pointerEvents: "auto" }}
              >
                <SliderBtnIcon />
              </SliderButton>
            </CarouselClickArea>
          </div>
        ) : null}
        <div
          style={{
            width: "100%",
            height: "26%",
            position: "absolute",
            top: 0,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            paddingRight: "20px",
            zIndex: "18",
            pointerEvents: "none",
          }}
        >
          <CloseButton
            style={{ pointerEvents: "auto" }}
            onClick={() => closeWindow(null)}
          >
            {" "}
            <CloseBtnIcon />{" "}
          </CloseButton>
        </div>
      </StyledSlider>
      <StyledInfo>
        <StyledPrimary>
          <StyledHeader>
            <StyledName> {currentSpaceMiniInfo?.name}</StyledName>
            <StyledType>
              {" "}
              {t("SpaceTypes." + currentSpaceMiniInfo?.type)}{" "}
            </StyledType>
          </StyledHeader>
          <StyledRateInfo>
            <StyledRate
              disabled
              defaultValue={currentSpaceMiniInfo?.getFormattedRating()}
            />
            <StyledRateInfo2>
              {currentSpaceMiniInfo?.average_rating}
            </StyledRateInfo2>
          </StyledRateInfo>
          <StyledRowIconInfo>
            <StyledIconItem>
              <StyledSittingIcon />
              <span style={{ minWidth: "14" }}>
                {" "}
                {currentSpaceMiniInfo?.seat_number}
              </span>
            </StyledIconItem>
            <StyledIconItem>
              <StyledStandingIcon />
              <span style={{ minWidth: "14" }}>
                {" "}
                {currentSpaceMiniInfo?.standing_number}
              </span>
            </StyledIconItem>
            <StyledIconItem>
              <StyledFloorAreaIcon />
              <span style={{ minWidth: "14" }}>
                {" "}
                {currentSpaceMiniInfo?.floor_area}
              </span>
            </StyledIconItem>
          </StyledRowIconInfo>
        </StyledPrimary>
        <StyledSecondary>
          <HeartIconWrapper onClick={handleFavoriteButtonClick}>
            <HeartButton
              type="grey"
              size="large"
              onClick={handleFavoriteButtonClick}
            >
              <HeartIcon isLiked={currentSpaceMiniInfo?.is_favorite!} />
            </HeartButton>
          </HeartIconWrapper>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <SpacerPrice>
              <span
                className="card-price-small-text"
                // style={{ color: "#A0A0A0" }}
              >
                {t("Texts.PriceFrom")}
              </span>
              <span className="card-price-big-text">
                {" "}
                {currentSpaceMiniInfo?.min_price}
              </span>
              <span
                className="card-price-small-text"
                // style={{ color: "#A0A0A0" }}
              >
                {t("Texts.PricePerHour")}
              </span>
            </SpacerPrice>
          </div>
        </StyledSecondary>
      </StyledInfo>
      <Link
        to={routerPathLocal(i18n.language, RouterPath.SPACE + pseudo)}
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          zIndex: "16",
        }}
      />
      <ModalLogin
        isOpen={isOpenModalLogin}
        closeModal={() => setOpenModalLogin(false)}
      />
      <ModalMakeRequest
        isOpen={isOpenModalMakeRequest}
        closeModal={() => setIsOpenModalMakeRequest(false)}
      />
    </StyledMain>
  );
}
export default observer(SpaceCardPreview);

const CloseButton = styled.button`
  background-color: #000000;
  opacity: 74%;
  color: #ffffff;
  font-size: 12px;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  height: 22px;
  width: 22px;
  :hover {
    opacity: 100%;
  }
`;

const SliderButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  opacity: 74%;
  color: #ffffff;
  font-size: 12px;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  height: 32px;
  width: 32px;
  :hover {
    opacity: 100%;
  }
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
`;

const HeartButton = styled(CustomButton)`
  &.ant-btn.ant-btn-lg {
    width: 40px;
    height: 40px;
    padding: 5px 0 0 0;
  }
`;

const CarouselClickArea = styled.div`
  pointer-events: none;
  z-index: 17;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 25%;
  cursor: pointer;
  flex: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
  padding: 20px;

  &.left {
    left: 0;
    align-items: flex-start;
  }

  &.right {
    right: 0;
    align-items: flex-end;
  }
`;

const HeartIconWrapper = styled.div`
  z-index: 62;
  padding-top: 4px;
  cursor: pointer;
`;

interface StyledHeartProps {
  isLiked: boolean | undefined;
}

const HeartIcon = styled(Heart)<StyledHeartProps>`
  stroke-width: 2;
  width: 16px;
  height: 14px;
  fill: ${(props) => (props.isLiked ? "#ff5959" : "none")};
  stroke: ${(props) => (props.isLiked ? "#ff5959" : "#000000")};
`;

const StyledRowIconInfo = styled(Row)`
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
`;
const StyledSittingIcon = styled(SittingIcon)`
  width: 10px;
  height: 18px;
  margin-right: 5px;
  fill: #a0a0a0;
`;

const StyledStandingIcon = styled(StandingIcon)`
  width: 16px;
  height: 18px;
  margin-right: 5px;
  fill: #a0a0a0;
`;

const StyledFloorAreaIcon = styled(FloorAreaIcon)`
  width: 18px;
  height: 18px;
  margin-right: 5px;
  fill: #a0a0a0;
`;

const StyledIconItem = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;
  color: #a0a0a0;
  font-size: 12px;
`;

const StyledMain = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ffff;
  border-radius: 8px;
  overflow: hidden;
  pointer-events: auto;
`;

const StyledSlider = styled.div`
  width: 100%;
  height: 200px;
  background: #e2e2e2;
  position: relative;
`;

const CarouselImageContainer = styled.div`
  position: relative;
  height: 200px;
`;

const CarouselImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

const StyledCarousel = styled(Carousel)`
  > .slick-dots li,
  .slick-dots li.slick-active {
    width: 6px;
  }

  > .slick-dots li button {
    width: 6px;
    height: 6px;
    border-radius: 100%;
  }
  > .slick-dots li.slick-active button {
    width: 7px;
    height: 7px;
    border-radius: 100%;
  }
`;


const StyledInfo = styled.div`
  width: 100%;
  height: auto;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 20px 16px;
  min-height: 115px;
`;
const StyledPrimary = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
`;
const StyledSecondary = styled.div`
  width: 100%;
  display: flex;
  align-self: stretch;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-items: flex-end;
`;
const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledName = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  color: #000000;
  min-height: 23px;
`;
const StyledType = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #767676;
`;
const StyledRateInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  gap: 4px;
  margin-bottom: 4px;
`;

const StyledRate = styled(Rate)`
  .ant-rate-star-full,
  .ant-rate-star-half {
    color: #000000; /* active star */
  }
  .ant-rate-star-first,
  .ant-rate-star-second {
    color: #e2e2e2; /* disabled star */
  }
  font-size: 12px;
`;
const StyledRateInfo2 = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 180%;
  color: #a0a0a0;
`;

const SpacerPrice = styled.div`
  gap: 3px;
  display: flex;
  align-items: flex-end;
`;
