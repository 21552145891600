import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import MyProfileComponent from "components/myProfile/MyProfileComponent";
import menuAdmin from "pages/spaceAdmin/commonComponents/menuAdmin";

/** My profile Admin page. */
function AdminProfile(): JSX.Element {
  const { t, i18n } = useTranslation();

  return (
    <>
      <MyProfileComponent
        menu={menuAdmin(i18n.language, t)}
      ></MyProfileComponent>
    </>
  );
}

export default observer(AdminProfile);
