import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import MyProfileComponent from "components/myProfile/MyProfileComponent";
import menuCustomer from "../commonComponents/menuCustomer";

/** My profile Customer page. */
function MyProfileCustomer(): JSX.Element {
  const { t, i18n } = useTranslation();

  return (
    <>
      <MyProfileComponent
        menu={menuCustomer(i18n.language, t)}
      ></MyProfileComponent>
    </>
  );
}

export default observer(MyProfileCustomer);
