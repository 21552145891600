export enum enumUserRole {
  CLIENT = "CLIENT",
  MANAGER = "MANAGER",
  ADMIN = "ADMIN",
}

export enum enumUserRoleForQueryParams {
  CLIENT = "user",
  MANAGER = "manager",
  ADMIN = "admin",
}