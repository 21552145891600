import { IMenuItem } from "components/responsiveMenu/ResponsiveMenu";
import { RouterPath, routerPathLocal } from "constants/RouterPath";

const menuAdmin = (language: string, t: (key: string) => string): IMenuItem[] => {
  return [
    {
      routerPath: routerPathLocal(
        language,
        RouterPath.ADMIN_MY_PROFILE
      ),
      text: t("AdminProfile.Menu.MyProfile"),
    },
    {
      routerPath: routerPathLocal(
        language,
        RouterPath.ADMIN_SPACES_ALL
      ),
      text: t("AdminProfile.Menu.Spaces"),
    },
    {
      routerPath: routerPathLocal(
        language,
        RouterPath.ADMIN_USERS_LIST
      ),
      text: t("AdminProfile.Menu.Users"),
    },
  ];
};

export default menuAdmin;