export const aliasToken = {
  colorPrimary: '#007AAE',
  colorPrimaryHover: '#0B678E',
  colorPrimaryActive: '#0B678E',
  colorHighlight: '#007AAE',
  borderRadiusLG: 8,
  borderRadiusSM: 4,
  fontFamily: "Switzer-Variable"
};

Object.freeze(aliasToken);


