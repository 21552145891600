import { Instance, types } from 'mobx-state-tree'

export const RegionCityGeo = types
  .model({
    uuid: types.string,
    name: types.string,
    geo_lat: types.number,
    geo_long: types.number,
  })


export type IRegionCityGeo = Instance<typeof RegionCityGeo>