import { applySnapshot, flow, Instance, types } from 'mobx-state-tree'
import { requestStatus } from "constants/reqestStatus"
import MessengerService, { IInboxesItemAPIResponse } from 'services/MessengerService';
import { SpaceItem } from 'states/models/SpaceList/SpaceItem';
import { IGetSpacesItemResponse, IGetSpacesResponse } from 'services/SpaceService';
import SpaceService, { ISpacePageInfo } from "services/SpaceService";

export interface IFetchSpaceList {
  page: number;
  size: number;
  is_only_favorite?: boolean;
  is_with_image?: boolean;
  northeast_lat?: number;
  northeast_long?: number;
  southwest_lat?: number;
  southwest_long?: number;
  space_type?: string;
  id_event?: number;
  standing_number?: number;
  seat_number?: number;
  date?: string;
  lang?: string;
}


export const SpaceListMapStore = types
  .model({
    spaceListMap: types.optional(types.map(SpaceItem), {}),
    total: types.optional(types.number, -1),
    page: types.optional(types.number, -1),
    size: types.optional(types.number, -1),
    idHoveredSpace: types.maybeNull(types.string),
    // status: types.optional(
    //   types.enumeration<requestStatus>(Object.values(requestStatus)), requestStatus.INITIAL),

  })
  .views((self) => ({
    getSpaceListMapAsArray: () => Array.from(self.spaceListMap.values())
  }))
  .actions((self) => ({
    clearSpaceListMap: (
    ) => {
      applySnapshot(self.spaceListMap, {})
    }
  }))
  .actions((self) => ({
    setPage: (page: number
    ) => {
      self.page=page
    },
    setIdHoveredSpace: (idSpace: string
    ) => {
      self.idHoveredSpace=idSpace
    }
  }))
  .actions((self) => ({
    updateSpaceListMap: (
      spaceListMap: Array<IGetSpacesItemResponse>
    ) => {
      spaceListMap.forEach((spaceItem) => {
        self.spaceListMap.set(
          spaceItem.id.toString(),
          {
            id: spaceItem.id,
            name: spaceItem.name,
            zip_code: spaceItem.zip_code,
            city: spaceItem.city,
            pseudo: spaceItem.pseudo,
            metro: spaceItem.metro,
            min_price: spaceItem.min_price,
            geo_lat: spaceItem.geo_lat,
            geo_long: spaceItem.geo_long,
            url_cover_image: spaceItem.url_cover_image,
            is_favorite: spaceItem.is_favorite,
          }
        )
      })
    }
  }))
  .actions((self) => ({
    fetchSpaceListMap: flow(function* fetchSpaceList(
      {
        lang,
        date,
        standing_number,
        seat_number,
        id_event,
        space_type,
        page,
        size,
        northeast_lat,
        northeast_long,
        southwest_lat,
        southwest_long,
        is_with_image = false,
        is_only_favorite = false,
      }: IFetchSpaceList
    ) {
      // self.status = requestStatus.PENDING;
      try {
        const data = yield SpaceService.getListSpaces({
              lang: lang,
              date: date,
              standing_number: standing_number,
              seat_number: seat_number,
              id_event: id_event,
              space_type: space_type,
              page: page,
              size: size,
              is_only_favorite: is_only_favorite,
              is_with_image: is_with_image,
              northeast_lat: northeast_lat,
              northeast_long: northeast_long,
              southwest_lat: southwest_lat,
              southwest_long: southwest_long,
          });
        self.updateSpaceListMap(data.data.items);
        self.total = data.data.total
        self.size = data.data.size
        // self.status = requestStatus.SUCCESS
      } catch
      {
        // self.status = requestStatus.ERROR;
      }
    })
  }))


export type ISpaceListMapStore = Instance<typeof SpaceListMapStore>