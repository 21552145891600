import React from "react";
import styled from "styled-components";

export const CustomP = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 190%;
  /* or 27px */
  color: #505050;
  white-space: pre-line;
`;
