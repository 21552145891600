import { flow, Instance, types } from 'mobx-state-tree'



export const OpeningHours = types
  .model({
    day: types.string,
    time_from: types.string,
    time_to: types.string,
    min_price: types.number,
  })
  .views((self) => ({
    getTimeFromTo: () => {
      return self.time_from.substring(0, 5) + " - " + self.time_to.substring(0, 5)
      },
  }))