import CustomButton from "components/buttons/CustomButton";
import ModalLogin from "components/modals/modalLogin/ModalLogin";
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import { enumUserRole } from "constants/User";
import useStore from "hooks/useStore";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

function UserSection() {
  const { t } = useTranslation();
  const { auth } = useStore();
  const { i18n } = useTranslation();
  const [isOpenModalLogin, setOpenModalLogin] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    if (auth.currentUser?.role === enumUserRole.CLIENT) {
      navigate(routerPathLocal(i18n.language, RouterPath.CUST_MY_PROFILE));
    } else if (auth.currentUser?.role === enumUserRole.MANAGER) {
      navigate(routerPathLocal(i18n.language, RouterPath.MANAGER_MY_PROFILE));
    } else if (auth.currentUser?.role === enumUserRole.ADMIN) {
      navigate(routerPathLocal(i18n.language, RouterPath.ADMIN_MY_PROFILE));
    }
  };

  return (
    <>
      {auth.currentUser ? (
        <StyledButtonRow>
          <CustomButton type="primary" onClick={handleClick}>
            {t("Header.MyProfile")}
          </CustomButton>
          <CustomButton type="grey" onClick={() => auth.logout(i18n.language)}>
            {t("Header.LogoutButton")}
          </CustomButton>
        </StyledButtonRow>
      ) : (
        <StyledButtonRow>
          <CustomButton
            type="primary"
            onClick={() =>
              navigate(routerPathLocal(i18n.language, RouterPath.SIGNUP))
            }
          >
            {t("Header.SignupButton")}
          </CustomButton>
          <CustomButton type="grey" onClick={() => setOpenModalLogin(true)}>
            {t("Header.LoginButton")}
          </CustomButton>
          <ModalLogin
            isOpen={isOpenModalLogin}
            closeModal={() => setOpenModalLogin(false)}
          />
        </StyledButtonRow>
      )}
    </>
  );
}

export default observer(UserSection);

const StyledButtonRow = styled.div`
  display: flex;
  gap: 5px;
`;
