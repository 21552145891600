import { Instance, types } from 'mobx-state-tree'
import { createContext } from 'react'
import { AuthStore } from './stores/AuthStore/AuthStore'
import { InfoStore } from './stores/InfoStore/InfoStore';
import { SpaceStore } from './stores/SpaceStore/SpaceStore';
import { MessageStore } from './stores/MessageStore/MessageStore';
import { InboxStore } from './stores/InboxStore/InboxStore'
import { SpaceListStore } from './stores/SpaceListStore/SpaceListStore';
import { SpaceListMapStore } from './stores/SpaceListStore/SpaceListMapStore';
import { SpaceMiniInfoStore } from './stores/SpaceMiniInfoStore/SpaceMiniInfoStore';
import { UserListStore } from './stores/UserListStore/UserListStore';
import { UserPageStore } from './stores/UserPageStore/UserPageStore';


export const RootModel = types
  .model({
    auth: AuthStore,
    infoStore: InfoStore,
    spaceStore: SpaceStore,
    spaceMiniInfoStore: SpaceMiniInfoStore,
    spaceListStore: SpaceListStore,
    spaceListMapStore: SpaceListMapStore,
    inboxStore: InboxStore,
    messageStore: MessageStore,
    userListStore: UserListStore,
    userPageStore: UserPageStore,
  })
  .actions(() => ({}));

export const initialStore = {
  auth: {},
  infoStore: {},
  spaceStore: {},
  spaceMiniInfoStore: {},
  spaceListStore: {},
  spaceListMapStore: {},
  inboxStore: {},
  messageStore: {},
  userListStore: {},
  userPageStore: {},
}

export const rootStore = RootModel.create(initialStore);

export type IStore = Instance<typeof RootModel>;

export const RootStoreContext = createContext<null | IStore>(null);

export const { Provider } = RootStoreContext;