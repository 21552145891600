import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Divider, Space } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyledNavLink = styled(NavLink)`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  color: #6c6c6c;

  &.active {
    color: #007aae;
  }

  &:hover {
    color: #007aae;
  }

  &:active {
    color: #007aae;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 8px 0 20px;
  padding: 0;
`;

interface Tab {
  title: string;
  path: string;
  key: string;
}

interface CustomTabsProps {
  tabs: Tab[];
  onTabClick: (tabKey: string) => void;
  activeTab: string;
  children?: React.ReactNode;
}

const CustomTabs: React.FC<CustomTabsProps> = ({
  tabs,
  onTabClick,
  activeTab,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Space style={{ display: "flex", justifyContent: "space-between" }}>
        <Space size={20}>
          {tabs.map((tab) => (
            <StyledNavLink
              key={tab.key}
              to={t(tab.path)}
              onClick={() => onTabClick(tab.key)}
              className={activeTab === tab.key ? "active" : ""}
            >
              {tab.title}
            </StyledNavLink>
          ))}
        </Space>
        <div style={{ display: "flex" }}>{children}</div>
      </Space>
      <StyledDivider />
    </Space>
  );
};

export default CustomTabs;
