import React, { useState } from "react";
import {
  Button,
  Col,
  Divider,
  Modal,
  Radio,
  Row,
  Space,
  notification,
} from "antd";
import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import useStore from "hooks/useStore";
import { ILoginData } from "services/AuthService";
import { requestStatus } from "constants/reqestStatus";
import { Link, useNavigate } from "react-router-dom";
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import CustomButton from "components/buttons/CustomButton";
import { enumUserRole } from "constants/User";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  redirectSuccessRouterPath?: string | null
}

interface FormProps {
  email: string;
  password: string;
}

const ModalLogin: React.FC<Props> = ({ isOpen, closeModal, redirectSuccessRouterPath = null }) => {
  const { auth } = useStore();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();

  const onReset = () => {
    form.resetFields();
  };

  const handleClose = () => {
    onReset();
    closeModal();
  };

  const redirectAfterLogin = (routerPathUri: string | null) => {
    if (routerPathUri) { navigate(routerPathLocal(i18n.language, routerPathUri)) }
  }

  const handleOk = async (values: ILoginData) => {
    setLoading(true);
    try {
      await auth.login(values);
    } catch {
      notification.error({
        message: t("NotificationErrors.Error.Title"),
        description: t("NotificationErrors.Error.Description"),
        placement: "bottomRight",
      });
    } finally {
      setLoading(false);
    }
    if (auth.postLoginRequestStatus === requestStatus.SUCCESS) {
      handleClose();
      redirectAfterLogin(redirectSuccessRouterPath)
    } else {
      form.setFields([
        {
          name: "email",
          value: form.getFieldValue("email"),
          errors: [t("Modals.Login.EmailErrorNotValid")],
        },
        {
          name: "password",
          value: form.getFieldValue("password"),
          errors: [t("Modals.Login.PasswordErrorNotValid")],
        },
      ]);
    }
  };

  return (
    <>
      <Modal
        open={isOpen}
        title={t("Modals.Login.Title")}
        onCancel={handleClose}
        footer={null}
        transitionName=""
        width={464}
      >
        <p>{t("Modals.Login.TextPleaseLogin")}</p>
        <Form layout="vertical" form={form}>
          <Form.Item
            label={t("Modals.Login.Email")}
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                validateTrigger: "onFinish",
                message: t("Modals.Login.EmailErrorMessage"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("Modals.Login.Password")}
            name="password"
            rules={[
              {
                required: true,
                message: t("Modals.Login.PasswordErrorMessage"),
                type: "string",
                min: 6,
                validateTrigger: "onFinish",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <CustomButton
              type="succes"
              size="large"
              htmlType="submit"
              onClick={() => {
                form.validateFields().then((values) => handleOk(values));
              }}
              disabled={loading}
              style={{ width: "100%" }}
            >
              {t("Modals.Login.ButtonSubmit")}
            </CustomButton>
          </Form.Item>
        </Form>
        <Divider />
        <Row justify="center">
          <p style={{ margin: 0 }}>
            {t("Modals.Login.ForgotPassword")}{" "}
            <Link
              to={routerPathLocal(i18n.language, RouterPath.FORGOT_PASSWORD)}
              onClick={handleClose}
            >
              {t("Modals.Login.ResetPassword")}
            </Link>
          </p>
        </Row>
      </Modal>
    </>
  );
};

export default observer(ModalLogin);
