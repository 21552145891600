import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import menuManager from "../commonComponents/menuManager";
import AccountSettingsComponent from "components/myProfile/AccountSettingsComponent";

/** Account settings Manager page. */
function ManagerAccountSettings(): JSX.Element {
  const { t, i18n } = useTranslation();

  return (
    <>
      <AccountSettingsComponent
        menu={menuManager(i18n.language, t)}
      ></AccountSettingsComponent>
    </>
  );
}

export default observer(ManagerAccountSettings);
