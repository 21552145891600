import React, { useState } from "react";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import { useNavigate } from "react-router-dom";
import { CustomH1, CustomH2 } from "components/typography/CustomH";
import { CustomP } from "components/typography/CustomP";
import CustomButton from "components/buttons/CustomButton";
import ModalLogin from "components/modals/modalLogin/ModalLogin";
import useStore from "hooks/useStore";
import { observer } from "mobx-react-lite";
import { enumUserRole, enumUserRoleForQueryParams } from "constants/User";
import { Helmet } from "react-helmet";

/**Page partnership to inform user about advatages of Astudium */
function Partnership(): JSX.Element {
  const { t, i18n } = useTranslation();
  const { auth } = useStore();
  const navigate = useNavigate();
  const [isOpenModalLogin, setOpenModalLogin] = useState(false);

  const handleCreateSpaceButtonClick = () => {
    if (auth.currentUser && auth.currentUser.role !== enumUserRole.MANAGER) {
      notification.error({
        message: t("Partnership.NotificationNotManagerTitle"),
        description: t("Partnership.NotificationNotManagerDescription"),
        placement: "bottomRight",
      });
    } else if (auth.currentUser) {
      navigate(routerPathLocal(i18n.language, RouterPath.MANAGER_ADD_SPACE));
    } else {
      setOpenModalLogin(true);
    }
  };

  return (
    <>
      <Helmet
        title={t("Partnership.PageHeadTitle")}
        htmlAttributes={{ lang: i18n.language }}
        meta={[
          {
            name: `description`,
            content: t("Partnership.PageHeadDescription"),
          },
        ]}
      />
      <CustomH1 style={{ marginBottom: "26px" }}>
        {t("Partnership.Title")}
      </CustomH1>
      <CustomP>{t("Partnership.Text1")}</CustomP>
      <CustomP>{t("Partnership.Text2")}</CustomP>
      <CustomP>{t("Partnership.Text3")}</CustomP>
      <CustomH2>{t("Partnership.Text4")}</CustomH2>
      <ol>
        <li>
          <CustomP>{t("Partnership.TextItem1")}</CustomP>
        </li>
        <li>
          <CustomP>{t("Partnership.TextItem2")}</CustomP>
        </li>
        <li>
          <CustomP>{t("Partnership.TextItem3")}</CustomP>
        </li>
        <li>
          <CustomP>{t("Partnership.TextItem4")}</CustomP>
        </li>
      </ol>
      {!auth.currentUser ? (
        <>
          <CustomP>{t("Partnership.Text5")}</CustomP>
          <CustomP>{t("Partnership.Text6")}</CustomP>
          <CustomButton
            size="large"
            type="succes"
            style={{ width: "fit-content" }}
            onClick={() =>
              navigate(
                routerPathLocal(i18n.language, RouterPath.SIGNUP) +
                  "?user=" +
                  enumUserRoleForQueryParams.MANAGER
              )
            }
          >
            {t("Partnership.ButtonCreateAccount")}
          </CustomButton>
        </>
      ) : (
        <></>
      )}
      <CustomP>{t("Partnership.Text7")}</CustomP>
      <CustomButton
        size="large"
        type="succes"
        style={{ width: "fit-content" }}
        onClick={() => handleCreateSpaceButtonClick()}
      >
        {t("Partnership.ButtonCreateSpace")}
      </CustomButton>
      <CustomP>{t("Partnership.Text8")}</CustomP>
      <CustomP>{t("Partnership.Text9")}</CustomP>
      <CustomP>{t("Partnership.Text10")}</CustomP>

      <ModalLogin
        isOpen={isOpenModalLogin}
        closeModal={() => setOpenModalLogin(false)}
      />
    </>
  );
}

export default observer(Partnership);
