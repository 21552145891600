import axios from "axios";
import { RouterPath } from "constants/RouterPath";

/** Axios instance with two interceptors:
 * - add token to headers
 * - redirect to home if not authorized
 */
export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});


axiosInstance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response.status === 403) {
    window.location.href = RouterPath.HOME;
  }
  throw error
});