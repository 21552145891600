import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "antd";
import SpaceCardHover from "components/spaceCardHover/SpaceCardHover";
import {
  IGetSpacesItemResponse,
  IGetSpacesResponse,
} from "services/SpaceService";
import { ReactComponent as ModifyIcon } from "assets/images/penModify.svg";
import styled from "styled-components";
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import { useNavigate } from "react-router-dom";

interface IListSpacesProps {
  fetchSpaces: () => Promise<IGetSpacesItemResponse[]>;
  routerPath: string;
}

/** Component for displaying spaces list in form of Cards. */
export default function ListSpaces(props: IListSpacesProps) {
  const { t, i18n } = useTranslation();
  const [spaces, setSpaces] = useState<IGetSpacesItemResponse[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    props
      .fetchSpaces()
      .then((response) => setSpaces(response))
      .catch((error) => console.error(error));
  }, [props]);

  const handleModifyButtonClick = (pseudo: string) => {
    navigate(routerPathLocal(i18n.language, props.routerPath + "/" + pseudo));
  };

  return (
    <>
      <>
        <Row gutter={[20, 20]}>
          {spaces.map((space) => {
            return (
              <Col xs={24} sm={24} md={12} lg={8} xl={8} key={space.id}>
                <SpaceCardHover
                  id={space.id}
                  name={space.name}
                  city={space.city}
                  zip_code={space.zip_code}
                  metro={space.metro}
                  min_price={space.min_price}
                  url_cover_image={space.url_cover_image}
                >
                  <>
                    {" "}
                    <Button
                      icon={<StyledModifyIcon />}
                      className="space-card-button"
                      onClick={() => handleModifyButtonClick(space.pseudo)}
                    />
                  </>
                </SpaceCardHover>
              </Col>
            );
          })}
        </Row>
      </>
    </>
  );
}

const StyledModifyIcon = styled(ModifyIcon)`
  stroke-width: 1.5px;
`;
