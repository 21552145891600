import { flow, Instance, types } from 'mobx-state-tree'
import { observable } from 'mobx'
import { enumUserRole } from 'constants/User'


export const UserPageModel = types
  .model({
    uuid: types.string,
    id: types.number,
    email: types.string,
    first_name: types.string,
    last_name: types.string,
    is_mail_confirmed: types.boolean,
    tel: types.string,
    role: types.enumeration<enumUserRole>(Object.values(enumUserRole)),
    url_avatar: types.maybeNull(types.string),
    date_creation: types.string,
    date_last_login: types.maybeNull(types.string),
    date_pwd_reinit: types.maybeNull(types.string),
    number_of_spaces: types.number,
  })

export type IUserPageModel = Instance<typeof UserPageModel>