import { applySnapshot, flow, Instance, types } from 'mobx-state-tree'
import { requestStatus } from "constants/reqestStatus"
import { Inbox } from 'states/models/Messenger/Inbox';
import MessengerService, { IInboxesItemAPIResponse } from 'services/MessengerService';


export const InboxStore = types
  .model({
    inboxes: types.optional(types.map(Inbox), {}),
    total: types.optional(types.number, -1),
    page: types.optional(types.number, -1),
    size: types.optional(types.number, -1),
    // status: types.optional(
    //   types.enumeration<requestStatus>(Object.values(requestStatus)), requestStatus.INITIAL),

  })
  .views((self) => ({
    getInboxesAsArray: () => Array.from(self.inboxes.values())
  }))
  .actions((self) => ({
    clearInboxes: (
    ) => {
      applySnapshot(self.inboxes, {})
    }
  }))
  .actions((self) => ({
    setPage: (page: number
    ) => {
      self.page=page
    }
  }))
  .actions((self) => ({
    updateInboxes: (
      inboxes: Array<IInboxesItemAPIResponse>
    ) => {
      inboxes.forEach((inbox) => {
        self.inboxes.set(
          inbox.id.toString(),
          {
            id: inbox.id,
            name: inbox.name,
            date_creation: inbox.date_creation,
            first_name: inbox.first_name,
            last_name: inbox.last_name,
            message: inbox.message,
            url_avatar: inbox.url_avatar,
            is_seen: inbox.is_seen,
          }
        )
      })
    }
  }))
  .actions((self) => ({
    fetchInboxes: flow(function* fetchInboxes(page: number, size: number) {
      // self.status = requestStatus.PENDING;
      try {
        const data = yield MessengerService.getMyInboxes({
              page: page,
              size: size,
          });
        self.updateInboxes(data.data.items);
        self.total = data.data.total
        self.size = data.data.size
        // self.status = requestStatus.SUCCESS
      } catch
      {
        // self.status = requestStatus.ERROR;
      }
    })
  }))


export type IInboxStore = Instance<typeof InboxStore>