import React, { HTMLAttributes, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Badge, Button, Col, Dropdown, Menu, MenuProps, Row } from "antd";
import { enumLanguages } from "constants/languages";
import useStore from "hooks/useStore";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { DownOutlined } from "@ant-design/icons";
import logoImage from "assets/images/header/logo.svg";
import closeIcon from "assets/images/header/closeIcon.svg";
import menuIcon from "assets/images/header/menuIcon.svg";
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import UserSection from "./userSection/UserSection";
import FavoritesMessagesSection from "./favoritesMessagesSection/FavoritesMessagesSection";
import UserSectionResponsive from "./userSection/UserSectionResponsive";
import { enumUserRole } from "constants/User";
import { ReactComponent as MessagesIcon } from "assets/images/header/messages.svg";
import { requestStatus } from "constants/reqestStatus";

function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

function CustomHeader(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { auth } = useStore();
  const { i18n } = useTranslation();
  const [isMoreThan1050px, setIsMoreThan1050px] = useState(
    window.innerWidth >= 1050
  );
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (auth.currentUser) {
        auth.fetchAndSetNumberUnreadMessages();
      }
    }, 30000); // 30 seconds check unread messages every 60 seconds TODO replace by websockets

    return () => {
      clearInterval(intervalId);
    };
  }, [auth]);

  const handleMenuClick = () => {
    setVisible(!visible);
  };

  const closeResponsiveMenu = () => {
    setVisible(false);
  };

  useEffect(() => {
    const handleResize = () => setIsMoreThan1050px(window.innerWidth > 1050);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLanguageChange = (language: string) => {
    const currentPath = window.location.pathname;
    const currentQuery = window.location.search;
    const currentLanguage = currentPath.split("/")[1];
    const newPath =
      currentPath.replace(`/${currentLanguage}/`, `/${language}/`) +
      currentQuery;
    i18n.changeLanguage(language);
    setVisible(false);
    navigate(newPath);
  };

  const handleClickMessages = () => {
    navigate(routerPathLocal(i18n.language, RouterPath.MESSENGER));
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <div>{t("languages.en")}</div>,
      onClick: () => handleLanguageChange(enumLanguages.EN),
    },
    {
      key: "2",
      label: <div>{t("languages.fr")}</div>,
      onClick: () => handleLanguageChange(enumLanguages.FR),
    },
  ];

  if (isMoreThan1050px)
    return (
      <>
        <StyledRow gutter={10} style={{ margin: "0px" }}>
          <Col
            xs={3}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            style={{ display: "flex", height: "100%", alignItems: "center" }}
          >
            <Link to={routerPathLocal(i18n.language, RouterPath.HOME)}>
              <img
                src={logoImage}
                alt=""
                style={{ height: "38px", width: "auto", fill: "#000" }}
              />
            </Link>
          </Col>
          <Col
            xs={9}
            sm={9}
            md={9}
            lg={9}
            xl={9}
            style={{ display: "flex", justifyContent: "flex-end", gap: "42px" }}
          >
            <StyledDivLink
              to={routerPathLocal(i18n.language, RouterPath.SPACES)}
            >
              {t("Header.SpacesButton")}
            </StyledDivLink>
            <StyledDivLink
              to={routerPathLocal(i18n.language, RouterPath.PARTNERSHIP)}
            >
              {t("Header.Partnership")}
            </StyledDivLink>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{ display: "flex", justifyContent: "flex-end", gap: "45px" }}
          >
            {!auth.currentUser &&
            auth.postUserInfoRequestStatus === requestStatus.PENDING ? (
              <></>
            ) : (
              <>
                <FavoritesMessagesSection />
                <UserSection />
              </>
            )}

            {/* <div style={{ marginLeft: "45px" }}> */}
            <Dropdown menu={{ items }} trigger={["click"]} placement="topRight">
              <StyledDivLanguage>
                {capitalize(i18n.language)} <DownOutlined />
              </StyledDivLanguage>
            </Dropdown>
            {/* </div> */}
          </Col>
        </StyledRow>
      </>
    );
  else
    return (
      <>
        {" "}
        <Header>
          <RowResponsiveHeader>
            <Col
              span={18}
              style={{ height: "100%", display: "flex", alignItems: "center" }}
            >
              <Link to={routerPathLocal(i18n.language, RouterPath.HOME)}>
                <img
                  src={logoImage}
                  alt=""
                  style={{ height: "27px", width: "auto" }}
                />
              </Link>
            </Col>
            <Col
              span={4}
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                paddingRight: "60px",
              }}
            >
              {auth.currentUser ? (
                <Badge
                  count={auth.numberUnreadMessages}
                  style={{ background: "#007AAE" }}
                >
                  <StyledImgMessages onClick={() => handleClickMessages()} />
                </Badge>
              ) : (
                <></>
              )}
            </Col>
            <Col
              span={2}
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div onClick={handleMenuClick}>
                <img src={menuIcon} alt="Menu icon" />
              </div>
            </Col>
          </RowResponsiveHeader>
        </Header>
        {visible && (
          <FullScreenMenu>
            <RowResponsiveHeader style={{ justifyContent: "flex-end" }}>
              <Col
                span={2}
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div onClick={handleMenuClick}>
                  <img src={closeIcon} alt="Close icon" />
                </div>
              </Col>
            </RowResponsiveHeader>
            <div
              style={{
                paddingTop: "0px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                marginBottom: "80px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "20px",
                  marginBottom: "50px",
                }}
              >
                <StyledLangDiv
                  isSelected={i18n.language === enumLanguages.EN}
                  onClick={() => handleLanguageChange(enumLanguages.EN)}
                >
                  {t("Header.LangResponsiveEN")}
                </StyledLangDiv>
                <StyledLangDiv
                  isSelected={i18n.language === enumLanguages.FR}
                  onClick={() => handleLanguageChange(enumLanguages.FR)}
                >
                  {t("Header.LangResponsiveFR")}
                </StyledLangDiv>
              </div>
              <StyledLinkMenuItem
                to={routerPathLocal(i18n.language, RouterPath.SPACES)}
                onClick={closeResponsiveMenu}
              >
                {t("Header.SpacesButton")}
              </StyledLinkMenuItem>
              <StyledLinkMenuItem
                to={routerPathLocal(i18n.language, RouterPath.PARTNERSHIP)}
                onClick={closeResponsiveMenu}
              >
                {t("Header.Partnership")}
              </StyledLinkMenuItem>
              {auth.currentUser ? (
                <StyledLinkMenuItem
                  to={routerPathLocal(i18n.language, RouterPath.FAVORITES)}
                  onClick={closeResponsiveMenu}
                >
                  {t("Header.Favorites")}
                </StyledLinkMenuItem>
              ) : (
                <></>
              )}
              {auth.currentUser?.role === enumUserRole.MANAGER ? (
                <StyledLinkMenuItem
                  to={routerPathLocal(
                    i18n.language,
                    RouterPath.MANAGER_MY_SPACES
                  )}
                  onClick={closeResponsiveMenu}
                >
                  {t("Header.MySpaces")}
                </StyledLinkMenuItem>
              ) : (
                <></>
              )}
            </div>
            <UserSectionResponsive onMenuItemClick={closeResponsiveMenu} />
          </FullScreenMenu>
        )}
      </>
    );
}

const StyledRow = styled(Row)`
  padding: 0px 70px;
  height: 63px;
  display: flex;
  align-items: center;
  // background-color: #fff;
  @media (max-width: 1100px) {
    padding: 0px 30px 0px;
  }
`;

const StyledDivLanguage = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  padding: 6px 10px;
  &:hover {
    color: #0b678e;
  }
`;

const StyledDivLink = styled(Link)`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  padding: 6px 10px;
  color: #000000;
  &:hover {
    color: #0b678e;
  }
`;

// responsive

const RowResponsiveHeader = styled(Row)`
  height: 47px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const FullScreenMenu = styled.div`
  position: fixed;
  overflow: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  color: #000;
  z-index: 60;
  padding: 0 15px;
`;

const Header = styled.header`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  background-color: #fff;
  height: 47px;
  z-index: 30;
`;

const StyledLinkMenuItem = styled(Link)`
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 50px;
  width: 100%;
  /* identical to box height */

  color: #000000;
`;

const StyledImgMessages = styled(MessagesIcon)`
  transition: all 0.05s ease-in-out;
  stroke: #000;
  fill: none;
  width: auto;
  height: 21px;

  &:hover {
    stroke: #007aae;
  }
`;

interface StyledLangDivProps extends HTMLAttributes<HTMLDivElement> {
  isSelected: boolean;
}

const StyledLangDiv = styled.div<StyledLangDivProps>`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height */
  cursor: pointer;

  color: ${(props) => (props.isSelected ? "#000" : "#6C6C6C")};
`;

export default observer(CustomHeader);
