import React from "react";
import { Breadcrumb, Layout, Menu, theme } from "antd";
import { Outlet } from "react-router-dom";
import CustomHeader from "components/header/CustomHeader";
import styled from "styled-components";

const { Header, Content, Footer } = Layout;

function MainFlexLayout(): JSX.Element {
  return (
    <Layout>
      <CustomHeader />
      <StyledContent
        style={{
          display: "flex",
          height: "calc(100vh - 63px)",
        }}
      >
        <Outlet />
      </StyledContent>
    </Layout>
  );
}

export default MainFlexLayout;

const StyledContent = styled(Content)`
  padding: 20px 30px 0px; /* Default padding */

  @media (max-width: 1100px) {
    padding: 20px 12px 0px;
  }

  @media (max-width: 800px) {
    padding: 20px 12px 0px;
  }
`;
