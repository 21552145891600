import React, { useState } from "react";
import { Upload, Button, message, Row, Col } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import chooseImage from "assets/images/homePage/chooseImage.png";
import searchImage from "assets/images/homePage/searchImage.png";
import bookImage from "assets/images/homePage/bookImage.png";

export default function ItsSimpleSection(): JSX.Element {
  const { t, i18n } = useTranslation();
  const [fileList, setFileList] = useState([]);
  const navigate = useNavigate();

  return (
    <>
      <Row>
        <StyledH2>{t("MainPage.ItsSimpleTitle")}</StyledH2>
      </Row>
      <Row gutter={[20, 20]} style={{ position: "relative" }}>
        <StyledCol xs={24} sm={24} md={24} lg={8} xl={8}>
          <StyledCard>
            <div>
              <StyledDiv>
                <StyledImage src={chooseImage} alt="" />
                <TextDiv>{t("MainPage.ChooseTitle")}</TextDiv>
              </StyledDiv>
              <DescriptionDiv>{t("MainPage.ChooseDescription")}</DescriptionDiv>
            </div>
          </StyledCard>
        </StyledCol>
        <StyledCol xs={24} sm={24} md={24} lg={8} xl={8}>
          <StyledCard>
            <div>
              <StyledDiv>
                <img src={searchImage} alt=""></img>
                <TextDiv>{t("MainPage.SearchTitle")}</TextDiv>
              </StyledDiv>
              <DescriptionDiv>{t("MainPage.SearchDescription")}</DescriptionDiv>
            </div>
          </StyledCard>
        </StyledCol>
        <StyledCol xs={24} sm={24} md={24} lg={8} xl={8}>
          <StyledCard>
            <div>
              <StyledDiv>
                <img src={bookImage} alt=""></img>
                <TextDiv>{t("MainPage.BookTitle")}</TextDiv>
              </StyledDiv>
              <DescriptionDiv>{t("MainPage.BookDescription")}</DescriptionDiv>
            </div>
          </StyledCard>
        </StyledCol>
        <StyledEllipse1 />
        <StyledEllipse2 />
      </Row>
    </>
  );
}

const StyledH2 = styled.h2`
  font-family: "Ranade-Variable";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 113%;
  /* or 45px */
  letter-spacing: -0.04em;
  color: #000000;
  margin-top: 0px;
  margin-bottom: 30px;
`;

const StyledCard = styled.div`
  background: #fff;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  padding: 30px 15px;
`;

const StyledDiv = styled.div`
  width: 285px;
  height: 285px;
  padding: 35px;
  background: linear-gradient(180deg, #f9f9f9 0%, #f2f2f2 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const TextDiv = styled.h3`
  position: absolute;
  bottom: 0;
  left: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 113%;
  /* identical to box height, or 34px */

  color: #000000;
  margin: 0;
`;

const DescriptionDiv = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  color: #484848;
  margin-top: 5px;
`;

const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
  // background: #ffffff;
  // border-radius: 10px;
  z-index: 2;
`;

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const StyledEllipse = styled.div`
  position: absolute;

  width: 349.91px;
  height: 349.91px;
  left: 600px; // Adjusted according to your requirement
  top: 50px; // Adjusted according to your requirement
  background: #007aae;
  opacity: 0.2;
  filter: blur(100px);
  border-radius: 50%; // To make the div elliptical
  z-index: 1;
`;



const StyledEllipse1 = styled(StyledEllipse)`
  left: 200px; // Adjusted according to your requirement
  top: -50px; // Adjusted according to your requirement
`;

const StyledEllipse2 = styled(StyledEllipse)`
  left: 600px; // Adjusted according to your requirement
  top: 50px; // Adjusted according to your requirement
`;

