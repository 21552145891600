import ModalLogin from "components/modals/modalLogin/ModalLogin";
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import useStore from "hooks/useStore";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as FavoritesIcon } from "assets/images/header/favorites.svg";
import { ReactComponent as MessagesIcon } from "assets/images/header/messages.svg";
import styled from "styled-components";
import { Badge } from "antd";

function FavoritesMessagesSection() {
  const { auth } = useStore();
  const { i18n } = useTranslation();
  const [isOpenModalLogin, setOpenModalLogin] = useState(false);
  const navigate = useNavigate();

  const handleClickFavorites = () => {
    if (auth.currentUser) {
      navigate(routerPathLocal(i18n.language, RouterPath.FAVORITES));
    } else {
      setOpenModalLogin(true);
    }
  };

  const handleClickMessages = () => {
    navigate(routerPathLocal(i18n.language, RouterPath.MESSENGER));
  };

  return (
    <>
      <div style={{ display: "flex", gap: "25px", alignItems: "center" }}>
        {auth.currentUser ? (
          <Badge
            count={auth.numberUnreadMessages}
            style={{ background: "#007AAE" }}
          >
            <StyledImgMessages onClick={() => handleClickMessages()} />
          </Badge>
        ) : (
          <></>
        )}
        <StyledImgFavorites onClick={() => handleClickFavorites()} />
      </div>
      <ModalLogin
        isOpen={isOpenModalLogin}
        closeModal={() => setOpenModalLogin(false)}
      />
    </>
  );
}

const StyledImgFavorites = styled(FavoritesIcon)`
  transition: all 0.05s ease-in-out;
  stroke: #000;
  fill: none;

  &:hover {
    stroke: #ff5959;
  }
`;

const StyledImgMessages = styled(MessagesIcon)`
  transition: all 0.05s ease-in-out;
  stroke: #000;
  fill: none;
  width: 24px;
  height: 18px;

  &:hover {
    stroke: #007aae;
  }
`;

export default observer(FavoritesMessagesSection);
