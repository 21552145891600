import { Form, Input, Modal, notification } from "antd";
import CustomButton from "components/buttons/CustomButton";
import ProfileLayout from "components/profileLayout/ProfileLayout";
import useStore from "hooks/useStore";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import UserApiService, {
  IUpdateUserInformationAPI,
} from "services/UserService";
import AvatarUpload from "./components/avatarUpload";
import { IMenuItem } from "components/responsiveMenu/ResponsiveMenu";
import { CustomP } from "components/typography/CustomP";
import { windowBreakpoints } from "constants/windowBreakpoints";
import UserService from "services/UserService";
import { useNavigate } from "react-router-dom";
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import { CustomH1 } from "components/typography/CustomH";

interface AccountSettingsProps {
  menu: IMenuItem[];
}

/** Account settings component is a page to modify password and to delete user.
 * It's common to Customer, Manager and Admin spaces.
 */
const AccountSettingsComponent: React.FC<AccountSettingsProps> = ({ menu }) => {
  const { t, i18n } = useTranslation();
  const { auth } = useStore();
  const [form] = Form.useForm();
  const [formDeleteAccount] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const [isLoadingChangePassword, setIsLoadingChangePassword] = useState(false);
  const [isLoadingDeleteAccount, setIsLoadingDeleteAccount] = useState(false);
  const [isShowModalDeleteAccount, setIsShowModalDeleteAccount] =
    useState(false);
  const navigate = useNavigate();

  const handleChangePassword = async (values) => {
    if (values.newPassword1 !== values.newPassword2) {
      await form.setFields([
        {
          name: "newPassword2",
          errors: [t("ValidationErrors.NotSamePassword")],
        },
      ]);
      return;
    }

    setIsLoadingChangePassword(true);

    try {
      await UserApiService.postUpdateUserPassword({
        old_password: values.oldPassword,
        new_password: values.newPassword1,
      }).then(() => {
        passwordForm.resetFields();
        notification.success({
          message: t(
            "CommonProfile.AccountSettings.NotificationSuccessPasswordTitle"
          ),
          description: t(
            "CommonProfile.AccountSettings.NotificationSuccessPasswordDescription"
          ),
          placement: "bottomRight",
        });
      });
    } catch (error) {
      notification.error({
        message: t("NotificationErrors.Error.Title"),
        description: t("NotificationErrors.Error.Description"),
        placement: "bottomRight",
      });
    }

    setIsLoadingChangePassword(false);
  };

  const onResetModalDeleteAccount = () => {
    formDeleteAccount.resetFields();
  };

  const closeModalDeleteAccount = () => {
    setIsShowModalDeleteAccount(false);
  };

  const handleCloseModalDeleteAccount = () => {
    onResetModalDeleteAccount();
    closeModalDeleteAccount();
  };

  const handleClickButtonConfirmDelete = async () => {
    await formDeleteAccount.validateFields();
    let data = {
      password: formDeleteAccount.getFieldValue("password"),
    };
    console.log(formDeleteAccount.getFieldValue("password"));

    setIsLoadingDeleteAccount(true);
    try {
      await UserService.deleteSelfUser(data).then(() => {
        notification.success({
          message: t(
            "CommonProfile.AccountSettings.NotificationDeleteAccountSuccessTitle"
          ),
          description: t(
            "CommonProfile.AccountSettings.NotificationDeleteAccountSuccessDescription"
          ),
          placement: "bottomRight",
        });
        handleCloseModalDeleteAccount();
        auth.logout(i18n.language);
        navigate(routerPathLocal(i18n.language, RouterPath.HOME));
      });
    } catch (error) {
      // @ts-ignore: Property 'response' does not exist on type '{}'
      if (error?.response?.status === 400) {
        formDeleteAccount.setFields([
          {
            name: "password",
            errors: [t("ValidationErrors.PasswordNotValid")],
          },
        ]);
      } else {
        notification.error({
          message: t("NotificationErrors.Error.Title"),
          description: t("NotificationErrors.Error.Description"),
          placement: "bottomRight",
        });
      }
    } finally {
      setIsLoadingDeleteAccount(false);
    }
  };

  if (!auth.currentUser) {
    return <></>;
  }

  return (
    <>
      <ProfileLayout menu={menu}>
        <>
          <CustomH1>{t("CommonProfile.AccountSettings.PageTitle")}</CustomH1>
          {/* PASSWORD SECTION */}
          <div className="form-title">
            {t("CommonProfile.AccountSettings.PasswordTitle")}
          </div>
          <Form layout="vertical" form={passwordForm}>
            <Form.Item
              label={t("CommonProfile.AccountSettings.OldPassword")}
              name="oldPassword"
              rules={[
                {
                  required: true,
                  message: t("ValidationErrors.Required"),
                  type: "string",
                  validateTrigger: "onFinish",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label={t("CommonProfile.AccountSettings.NewPassword")}
              name="newPassword1"
              rules={[
                {
                  required: true,
                  message: t("ValidationErrors.Required"),
                  type: "string",
                  validateTrigger: "onFinish",
                },
                {
                  message: t("ValidationErrors.PasswordFormat"),
                  type: "string",
                  min: 8,
                  validateTrigger: "onFinish",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label={t("CommonProfile.AccountSettings.NewPassword2")}
              name="newPassword2"
              rules={[
                {
                  required: true,
                  message: t("ValidationErrors.Required"),
                  type: "string",
                  validateTrigger: "onFinish",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <CustomButton
              type="succes"
              htmlType="submit"
              size="large"
              onClick={() => {
                passwordForm
                  .validateFields()
                  .then((values) => handleChangePassword(values));
              }}
              disabled={isLoadingChangePassword}
              style={{ marginBottom: "40px" }}
            >
              {t("CommonProfile.AccountSettings.SavePasswordButton")}
            </CustomButton>
          </Form>
          <div className="form-title">
            {t("CommonProfile.AccountSettings.DeleteAccount")}
          </div>
          <CustomP>
            {t("CommonProfile.AccountSettings.DeleteAccountText")}
          </CustomP>
          <CustomButton
            type="grey"
            size="large"
            onClick={() => {
              setIsShowModalDeleteAccount(true);
            }}
            style={{ marginBottom: "40px" }}
          >
            {t("CommonProfile.AccountSettings.DeleteAccountButton")}
          </CustomButton>
          <Modal
            open={isShowModalDeleteAccount}
            title={t("CommonProfile.AccountSettings.ModalDeleteAccount.Title")}
            onCancel={handleCloseModalDeleteAccount}
            footer={null}
            transitionName=""
            width={windowBreakpoints.MD}
          >
            <CustomP>
              {t("CommonProfile.AccountSettings.ModalDeleteAccount.Text")}
            </CustomP>
            <Form layout="vertical" form={formDeleteAccount}>
              <Form.Item
                label={t(
                  "CommonProfile.AccountSettings.ModalDeleteAccount.Password"
                )}
                name="password"
                rules={[
                  {
                    required: true,
                    message: t("ValidationErrors.Required"),
                    validateTrigger: "onFinish",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Form>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                gap: "20px",
              }}
            >
              <CustomButton
                type="default"
                size="large"
                onClick={handleCloseModalDeleteAccount}
              >
                {t("Buttons.Close")}
              </CustomButton>
              <CustomButton
                type="danger"
                size="large"
                // htmlType="submit"
                onClick={handleClickButtonConfirmDelete}
                disabled={isLoadingDeleteAccount}
              >
                {t(
                  "CommonProfile.AccountSettings.ModalDeleteAccount.ButtonDelete"
                )}
              </CustomButton>
            </div>
          </Modal>
        </>
      </ProfileLayout>
    </>
  );
};

export default observer(AccountSettingsComponent);
