import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import ListSpaces from "components/listSpaces/ListSpaces";
import { useTranslation } from "react-i18next";
import ProfileLayout from "components/profileLayout/ProfileLayout";
import menuAdmin from "pages/spaceAdmin/commonComponents/menuAdmin";
import CustomTabs from "components/customTabs/CustomTabs";
import CustomButton from "components/buttons/CustomButton";
import SpaceApiService, { IGetSpacesParams } from "services/SpaceService";
import { spaceState } from "constants/spaceState";
import useStore from "hooks/useStore";
import ListSpacesAdmin from "components/listSpaces/ListSpacesAdmin";

function SpacesAdmin(): JSX.Element {
  const [activeTab, setActiveTab] = useState("");
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = location.pathname;
    const tabs = [
      RouterPath.ADMIN_SPACES_ALL,
      RouterPath.ADMIN_SPACES_PUBLISHED,
      RouterPath.ADMIN_SPACES_NOT_PUBLISHED,
      RouterPath.ADMIN_SPACES_WAITING_FOR_VALIDATION,
    ];

    tabs.forEach((tabPath, index) => {
      if (currentPath === t(routerPathLocal(i18n.language, tabPath))) {
        setActiveTab(`tab${index + 1}`);
      }
    });
  }, [i18n.language, location.pathname, t]);

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  const tabs = [
    {
      title: t("AdminProfile.SpaceTabs.All"),
      path: routerPathLocal(i18n.language, RouterPath.ADMIN_SPACES_ALL),
      key: "tab1",
    },
    {
      title: t("AdminProfile.SpaceTabs.Published"),
      path: routerPathLocal(i18n.language, RouterPath.ADMIN_SPACES_PUBLISHED),
      key: "tab2",
    },
    {
      title: t("AdminProfile.SpaceTabs.NotPublished"),
      path: routerPathLocal(
        i18n.language,
        RouterPath.ADMIN_SPACES_NOT_PUBLISHED
      ),
      key: "tab3",
    },
    {
      title: t("AdminProfile.SpaceTabs.WaitingForModeration"),
      path: routerPathLocal(
        i18n.language,
        RouterPath.ADMIN_SPACES_WAITING_FOR_VALIDATION
      ),
      key: "tab4",
    },
  ];

  return (
    <ProfileLayout menu={menuAdmin(i18n.language, t)}>
      <>
        <CustomTabs
          tabs={tabs}
          onTabClick={handleTabClick}
          activeTab={activeTab}
        ></CustomTabs>
        <div>
          {activeTab === "tab1" && (
            <ListSpacesAdmin
              fetchParams={{}}
              routerPath={RouterPath.ADMIN_SPACES_ALL}
            />
          )}
          {activeTab === "tab2" && (
            <ListSpacesAdmin
              fetchParams={{ state: spaceState.PUBLISHED }}
              routerPath={RouterPath.ADMIN_SPACES_PUBLISHED}
            />
          )}
          {activeTab === "tab3" && (
            <ListSpacesAdmin
              fetchParams={{ state: spaceState.UNPUBLISHED }}
              routerPath={RouterPath.ADMIN_SPACES_NOT_PUBLISHED}
            />
          )}
          {activeTab === "tab4" && (
            <ListSpacesAdmin
              fetchParams={{ state: spaceState.WAITING_FOR_VALIDATION }}
              routerPath={RouterPath.ADMIN_SPACES_WAITING_FOR_VALIDATION}
            />
          )}
        </div>
      </>
    </ProfileLayout>
  );
}

export default observer(SpacesAdmin);

// const StyledModifyIcon = styled(ModifyIcon)`
//   stroke-width: 1.5px;
// `;
