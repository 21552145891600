import { Instance, types } from 'mobx-state-tree'
import { OpeningHours } from './OpeningHours'
import { ImageFile } from './ImageFile'
import ApiService, { ISpacePageInfo } from "services/SpaceService";

export const SpaceMiniInfoModel = types
  .model({
    id: types.number,
    name: types.string,
    pseudo: types.string,
    type: types.string,
    is_favorite: types.boolean,
    min_price: types.number,
    address: types.string,
    seat_number: types.number,
    standing_number: types.number,
    floor_area: types.number,
    comment_number: types.number,
    average_rating: types.number,
    images: types.array(ImageFile),
    // id_user_manager: types.number,
    // first_name_manager: types.string,
    // last_name_manager: types.string,
    // manager_avatar_url: types.string,
  })
  .views((self) => ({
    getFormattedRating: () => String(self.average_rating).slice(0,3).replace(".", ",")
  }))
  .views((self) => ({
    getImagesAsArray: () => self.images.slice()
  }))
  .actions((self) => ({
    setAttributeIsFavorite: (isFavorite: boolean) => {
      self.is_favorite = isFavorite
    },
  }))
  .actions((self) => ({
    setIsFavorite: (isFavorite: boolean) => {
      if (isFavorite && !self.is_favorite) {
        ApiService.postAddFavoriteSpace({ pseudo_space: self.pseudo }
        ).then(() => { self.setAttributeIsFavorite(true) })
      }
      else if (!isFavorite && self.is_favorite) {
        ApiService.deleteDeleteFavoriteSpace({ pseudo_space: self.pseudo }
        ).then(() => { self.setAttributeIsFavorite(false) })
      }
    },
  }))

export type ISpaceMiniInfoModel = Instance<typeof SpaceMiniInfoModel>