import { Instance, types } from 'mobx-state-tree'


export const UserListItemModel = types
  .model({
    email: types.string,
    full_name: types.string,
    uuid: types.string,
    is_mail_confirmed: types.boolean,
    role: types.string,
  })

export type IUserListItemModel = Instance<typeof UserListItemModel>