import { ReactComponent as WiFiIcon } from "assets/images/iconFacility/WiFi.svg";
import { ReactComponent as ComputerIcon } from "assets/images/iconFacility/Computer.svg";
import { ReactComponent as AirConditioningHeatingIcon } from "assets/images/iconFacility/AirConditioningHeating.svg";
import { ReactComponent as WheelchairAccessibilityIcon } from "assets/images/iconFacility/WheelchairAccessibility.svg";
import { ReactComponent as RestRoomIcon } from "assets/images/iconFacility/Restrooms.svg";
import { ReactComponent as OutdoorSeatingPatioIcon } from "assets/images/iconFacility/OutdoorSeatingPatio.svg";
import { ReactComponent as HighChairsBoosterSeatsIcon } from "assets/images/iconFacility/HighChairsBoosterSeats.svg";
import { ReactComponent as DressingRoomIcon } from "assets/images/iconFacility/DressingRoom.svg";
import { ReactComponent as ContinuousLightIcon } from "assets/images/iconFacility/ContinuousLight.svg";
import { ReactComponent as FlashLightIcon } from "assets/images/iconFacility/FlashLight.svg";
import { ReactComponent as SoftboxesIcon } from "assets/images/iconFacility/Softboxes.svg";
import { ReactComponent as UmbrellasIcon } from "assets/images/iconFacility/Umbrellas.svg";
import { ReactComponent as PaperBackgroundIcon } from "assets/images/iconFacility/PaperBackground.svg";
import { ReactComponent as CameraLensesIcon } from "assets/images/iconFacility/CameraLenses.svg";
import { ReactComponent as TripodsIcon } from "assets/images/iconFacility/Tripods.svg";
import { ReactComponent as CameraSupportEquipmentIcon } from "assets/images/iconFacility/CameraSupportEquipment.svg";
import { ReactComponent as StudioStrobesIcon } from "assets/images/iconFacility/StudioStrobes.svg";
import { ReactComponent as PropsAccessoriesIcon } from "assets/images/iconFacility/PropsAccessories.svg";
import { ReactComponent as MakeupStationIcon } from "assets/images/iconFacility/MakeupStation.svg";
import { ReactComponent as ChangingRoomsIcon } from "assets/images/iconFacility/ChangingRooms.svg";
import { ReactComponent as KitchenFacilitiesIcon } from "assets/images/iconFacility/KitchenFacilities.svg";
import { ReactComponent as DiningAreaIcon } from "assets/images/iconFacility/DiningArea.svg";
import { ReactComponent as BarAreaIcon } from "assets/images/iconFacility/BarArea.svg";
import { ReactComponent as BarServiceIcon } from "assets/images/iconFacility/BarService.svg";
import { ReactComponent as BeverageServiceIcon } from "assets/images/iconFacility/BeverageService.svg";
import { ReactComponent as OnSiteCateringIcon } from "assets/images/iconFacility/OnSiteCatering.svg";
import { ReactComponent as PaidParkingIcon } from "assets/images/iconFacility/PaidParking.svg";
import { ReactComponent as FreeParkingIcon } from "assets/images/iconFacility/FreeParking.svg";
import { ReactComponent as LightingSystemsIcon } from "assets/images/iconFacility/LightingSystems.svg";
import { ReactComponent as SoundSystemIcon } from "assets/images/iconFacility/SoundSystem.svg";
import { ReactComponent as ProjectorsIcon } from "assets/images/iconFacility/Projectors.svg";
import { ReactComponent as ScreensIcon } from "assets/images/iconFacility/Screens.svg";
import { ReactComponent as MicrophonesIcon } from "assets/images/iconFacility/Microphones.svg";
import { ReactComponent as AudioVisualEquipmentIcon } from "assets/images/iconFacility/AudioVisualEquipment.svg";
import { ReactComponent as VideoConferencingIcon } from "assets/images/iconFacility/VideoConferencing.svg";
import { ReactComponent as DeliveryServicesIcon } from "assets/images/iconFacility/DeliveryServices.svg";
import { ReactComponent as PrivateRoomsIcon } from "assets/images/iconFacility/PrivateRooms.svg";
import { ReactComponent as PartySpacesIcon } from "assets/images/iconFacility/PartySpaces.svg";
import { ReactComponent as ExhibitionSpaceIcon } from "assets/images/iconFacility/ExhibitionSpace.svg";
import { ReactComponent as TerraceIcon } from "assets/images/iconFacility/Terrace.svg";
import { ReactComponent as BallroomIcon } from "assets/images/iconFacility/Ballroom.svg";
import { ReactComponent as OutdoorCeremonySpaceIcon } from "assets/images/iconFacility/OutdoorCeremonySpace.svg";
import { ReactComponent as IndoorCeremonySpaceIcon } from "assets/images/iconFacility/IndoorCeremonySpace.svg";
import { ReactComponent as DanceFloorIcon } from "assets/images/iconFacility/DanceFloor.svg";
import { ReactComponent as LightingSoundSystemIcon } from "assets/images/iconFacility/LightingSoundSystem.svg";
import { ReactComponent as EventPlanningIcon } from "assets/images/iconFacility/EventPlanning.svg";
import { ReactComponent as CoordinationServicesIcon } from "assets/images/iconFacility/CoordinationServices.svg";
import { ReactComponent as MeetingRoomIcon } from "assets/images/iconFacility/MeetingRoom.svg";
import { ReactComponent as ConsultationRoomIcon } from "assets/images/iconFacility/ConsultationRoom.svg";
import { ReactComponent as PrintingEquipmentIcon } from "assets/images/iconFacility/PrintingEquipment.svg";
import { ReactComponent as MeetingRoomConferenceHallIcon } from "assets/images/iconFacility/MeetingRoomConferenceHall.svg";
import { ReactComponent as BridalSuiteIcon } from "assets/images/iconFacility/BridalSuite.svg";
import { ReactComponent as GroomsRoomIcon } from "assets/images/iconFacility/GroomsRoom.svg";
import { ReactComponent as OutdoorPhotoSpacesIcon } from "assets/images/iconFacility/OutdoorPhotoSpaces.svg";
import { ReactComponent as EventManagementIcon } from "assets/images/iconFacility/EventManagement.svg";
import { ReactComponent as LodgingAccommodationsIcon } from "assets/images/iconFacility/LodgingAccommodations.svg";
import { ReactComponent as OvernightAccommodationsIcon } from "assets/images/iconFacility/OvernightAccommodations.svg";
import { ReactComponent as ShuttleServicesIcon } from "assets/images/iconFacility/ShuttleServices.svg";
import { ReactComponent as TransportationServicesIcon } from "assets/images/iconFacility/TransportationServices.svg";

interface IFacilityIconProps {
  iconName: string;
}
/** Facility Icon from its name. */
function FacilityIconByName({
  iconName,
}: IFacilityIconProps): JSX.Element | null {
  switch (iconName) {
    case "WiFi":
      return <WiFiIcon />;
    case "Computer":
      return <ComputerIcon />;
    case "AirConditioningHeating":
      return <AirConditioningHeatingIcon />;
    case "WheelchairAccessibility":
      return <WheelchairAccessibilityIcon />;
    case "Restrooms":
      return <RestRoomIcon />;
    case "OutdoorSeatingPatio":
      return <OutdoorSeatingPatioIcon />;
    case "HighChairsBoosterSeats":
      return <HighChairsBoosterSeatsIcon />;
    case "DressingRoom":
      return <DressingRoomIcon />;
    case "ContinuousLight":
      return <ContinuousLightIcon />;
    case "FlashLight":
      return <FlashLightIcon />;
    case "Softboxes":
      return <SoftboxesIcon />;
    case "Umbrellas":
      return <UmbrellasIcon />;
    case "PaperBackground":
      return <PaperBackgroundIcon />;
    case "CameraLenses":
      return <CameraLensesIcon />;
    case "Tripods":
      return <TripodsIcon />;
    case "CameraSupportEquipment":
      return <CameraSupportEquipmentIcon />;
    case "StudioStrobes":
      return <StudioStrobesIcon />;
    case "PropsAccessories":
      return <PropsAccessoriesIcon />;
    case "MakeupStation":
      return <MakeupStationIcon />;
    case "ChangingRooms":
      return <ChangingRoomsIcon />;
    case "KitchenFacilities":
      return <KitchenFacilitiesIcon />;
    case "DiningArea":
      return <DiningAreaIcon />;
    case "BarArea":
      return <BarAreaIcon />;
    case "BarService":
      return <BarServiceIcon />;
    case "BeverageService":
      return <BeverageServiceIcon />;
    case "OnSiteCatering":
      return <OnSiteCateringIcon />;
    case "PaidParking":
      return <PaidParkingIcon />;
    case "FreeParking":
      return <FreeParkingIcon />;
    case "LightingSystems":
      return <LightingSystemsIcon />;
    case "SoundSystem":
      return <SoundSystemIcon />;
    case "Projectors":
      return <ProjectorsIcon />;
    case "Screens":
      return <ScreensIcon />;
    case "Microphones":
      return <MicrophonesIcon />;
    case "AudioVisualEquipment":
      return <AudioVisualEquipmentIcon />;
    case "VideoConferencing":
      return <VideoConferencingIcon />;
    case "DeliveryServices":
      return <DeliveryServicesIcon />;
    case "PrivateRooms":
      return <PrivateRoomsIcon />;
    case "PartySpaces":
      return <PartySpacesIcon />;
    case "ExhibitionSpace":
      return <ExhibitionSpaceIcon />;
    case "Terrace":
      return <TerraceIcon />;
    case "Ballroom":
      return <BallroomIcon />;
    case "OutdoorCeremonySpace":
      return <OutdoorCeremonySpaceIcon />;
    case "IndoorCeremonySpace":
      return <IndoorCeremonySpaceIcon />;
    case "DanceFloor":
      return <DanceFloorIcon />;
    case "LightingSoundSystem":
      return <LightingSoundSystemIcon />;
    case "EventPlanning":
      return <EventPlanningIcon />;
    case "CoordinationServices":
      return <CoordinationServicesIcon />;
    case "MeetingRoom":
      return <MeetingRoomIcon />;
    case "ConsultationRoom":
      return <ConsultationRoomIcon />;
    case "PrintingEquipment":
      return <PrintingEquipmentIcon />;
    case "MeetingRoomConferenceHall":
      return <MeetingRoomConferenceHallIcon />;
    case "BridalSuite":
      return <BridalSuiteIcon />;
    case "GroomsRoom":
      return <GroomsRoomIcon />;
    case "OutdoorPhotoSpaces":
      return <OutdoorPhotoSpacesIcon />;
    case "EventManagement":
      return <EventManagementIcon />;
    case "LodgingAccommodations":
      return <LodgingAccommodationsIcon />;
    case "OvernightAccommodations":
      return <OvernightAccommodationsIcon />;
    case "ShuttleServices":
      return <ShuttleServicesIcon />;
    case "TransportationServices":
      return <TransportationServicesIcon />;
    default:
      return null;
  }
}

/** Facility Icon from its name. */
function FacilityIcon({ iconName }: IFacilityIconProps): JSX.Element | null {
  return (
    <div
      style={{
        minWidth: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "10px",
      }}
    >
      <FacilityIconByName iconName={iconName} />
    </div>
  );
}

export default FacilityIcon;
