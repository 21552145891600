import { Avatar, Button, Col, Divider, Row, Space } from "antd";
import ResponsiveMenu, {
  IMenuItem,
} from "components/responsiveMenu/ResponsiveMenu";
import { enumUserRole } from "constants/User";
import useStore from "hooks/useStore";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as DownArrow } from "assets/images/downArrow.svg";

interface ProfileLayoutProps {
  menu: IMenuItem[];
  children: JSX.Element;
}

/** Layout for profiles Client, Manager and Admin
 * with navigation menu on the left and on the top for small devices.
 */
function ProfileLayout({ menu, children }: ProfileLayoutProps): JSX.Element {
  // const [selectedValue, setSelectedValue] = useState<IMenuItem | null>(null);
  const { auth } = useStore();
  const { t } = useTranslation();
  const [userTypeTranslation, setUserTypeTranslation] = useState<string>("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { pathname } = useLocation();
  const [textActiveMinuItem, setTextActiveMinuItem] = useState<string>("");

  useEffect(() => {
    menu.map((menu) => {
      if (menu.routerPath === pathname) {
        setTextActiveMinuItem(menu.text);
      }
    });
  }, [menu, pathname]);

  useEffect(() => {
    if (auth.currentUser?.role === enumUserRole.MANAGER) {
      setUserTypeTranslation(t("UserRoles.Manager"));
    } else if (auth.currentUser?.role === enumUserRole.ADMIN) {
      setUserTypeTranslation(t("UserRoles.Admin"));
    } else {
      setUserTypeTranslation(t("UserRoles.Customer"));
    }
  }, [auth.currentUser?.role, t]);

  return (
    <>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={0}
          lg={0}
          xl={0}
          style={{ marginBottom: "20px", position: "relative" }}
        >
          <StyledHideResponsiveMenuDiv />
          {/* <Row> */}
          <StyledDivSmallMenu onClick={() => setIsMenuOpen((prev) => !prev)}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                height: "100%",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Avatar
                  size={45}
                  src={auth.currentUser?.urlAvatar}
                  style={{ marginRight: "12px" }}
                >
                  {auth.currentUser
                    ? auth.currentUser.firstName.charAt(0) +
                      auth.currentUser.lastName.charAt(0)
                    : ""}
                </Avatar>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ fontSize: "12px", color: "#767676" }}>
                    {userTypeTranslation}
                  </div>
                  <div>
                    {auth.currentUser?.firstName +
                      " " +
                      auth.currentUser?.lastName}
                  </div>
                </Col>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                {textActiveMinuItem}
                <div>
                  <StyledDownArrow isUp={isMenuOpen} />
                </div>
              </div>
            </div>
          </StyledDivSmallMenu>
          {/* </Row> */}
          {/* <Row> */}
          <StyledDropDownMenu isVisible={isMenuOpen}>
            <Space direction="vertical" size={0} style={{ display: "flex" }}>
              {menu.map((item, index) => {
                const menuLength = menu.length;
                return (
                  <>
                    <ClickableDiv routerPath={item.routerPath}>
                      {item.text}
                    </ClickableDiv>
                    {index !== menuLength - 1 && <StyledDivider />}
                  </>
                );
              })}
            </Space>
          </StyledDropDownMenu>
          {/* </Row> */}
        </Col>
      </Row>
      <Row>
        <Col xs={0} sm={0} md={5} lg={5} xl={5}>
          <StyledMenuWrapper>
            <div style={{ marginBottom: "35px" }}>
              <Row>
                <Avatar
                  size={64}
                  src={auth.currentUser?.urlAvatar}
                  style={{ marginRight: "12px" }}
                >
                  {auth.currentUser
                    ? auth.currentUser.firstName.charAt(0) +
                      auth.currentUser.lastName.charAt(0)
                    : ""}
                </Avatar>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ fontSize: "12px", color: "#767676" }}>
                    {userTypeTranslation}
                  </div>
                  <div>
                    {auth.currentUser?.firstName +
                      " " +
                      auth.currentUser?.lastName}
                  </div>
                </Col>
              </Row>
            </div>
            <Space direction="vertical" size={0} style={{ display: "flex" }}>
              {menu.map((item, index) => {
                const menuLength = menu.length;
                return (
                  <>
                    <ClickableDiv routerPath={item.routerPath}>
                      {item.text}
                    </ClickableDiv>
                    {index !== menuLength - 1 && <StyledDivider />}
                  </>
                );
              })}
            </Space>
          </StyledMenuWrapper>
        </Col>
        <Col xs={24} sm={24} md={19} lg={19} xl={19}>
          <StyledContentWrapper>{children}</StyledContentWrapper>
        </Col>
      </Row>
    </>
  );
}

function ClickableDiv({
  routerPath,
  children,
}: {
  routerPath: string;
  children: JSX.Element | string;
}): JSX.Element {
  const { pathname } = useLocation();
  const isActive = routerPath === pathname;

  return (
    <StyledLink to={routerPath} className={isActive ? "isActive" : ""}>
      {children}
    </StyledLink>
  );
}

export default observer(ProfileLayout);

const StyledLink = styled(Link)`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-decoration: none;
  color: #6c6c6c;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 5px 15px 5px 5px;
  &:hover {
    background-color: #cfcfcf;
    color: #6c6c6c;
  }
  &:active {
    background-color: #b9b9b9;
    color: #000000;
  }
  &.isActive {
    color: #000000;
  }
`;

const StyledMenuWrapper = styled.div`
  width: 100%;
  background-color: #f9f9f9;
  padding: 26px 21px;
`;

const StyledContentWrapper = styled.div`
  margin-left: 35px;

  @media (max-width: 768px) {
    margin-left: 0px;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 11px 0px;
`;

// Responsive small menu

const StyledDivSmallMenu = styled.div`
  min-height: 75px;
  background: #f9f9f9;
  border-radius: 10px;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 5;
`;

const StyledHideResponsiveMenuDiv = styled.div`
  height: 200px;
  top: -125px;
  border-radius: 10px;
  width: 100%;
  background: #fff;
  position: absolute;
  z-index: 3;
`;

interface IStyledDropDownMenuProps {
  isVisible: boolean;
}

const StyledDropDownMenu = styled.div<IStyledDropDownMenuProps>`
  padding: ${(props) =>
    props.isVisible ? "15px 10px 15px 15px" : "0 10px 0px 15px"};
  margin-top: ${(props) => (props.isVisible ? "5px" : "-5px")};
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  position: relative;
  z-index: 2;
  max-height: ${(props) => (props.isVisible ? "1000px" : "0")};
  overflow: hidden;
  transform: ${(props) =>
    props.isVisible ? "translateY(0)" : "translateY(-100%)"};
  transition: padding 0.3s ease-in-out, margin-top 0.3s ease-in-out,
    max-height 0.3s ease-in-out, transform 0.3s ease-in-out;
`;

interface IStyledDownArrowProps {
  isUp: boolean;
}

const StyledDownArrow = styled(DownArrow)<IStyledDownArrowProps>`
  fill: #000;
  height: 12px;
  margin-left: 15px;
  margin-right: 20px;
  transform: ${({ isUp }) => (isUp ? "rotate(180deg)" : "rotate(0)")};
  transition: transform 0.3s ease-in-out;
`;
