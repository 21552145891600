import { Modal, Space, notification } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as MetroIcon } from "assets/images/metro.svg";
import { Link, useNavigate } from "react-router-dom";
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import useStore from "hooks/useStore";
import { ReactComponent as Heart } from "assets/images/heart.svg";
import { ISpaceItem } from "states/models/SpaceList/SpaceItem";
import { observer } from "mobx-react-lite";
import { windowBreakpoints } from "constants/windowBreakpoints";
import { CustomP } from "components/typography/CustomP";
import CustomButton from "components/buttons/CustomButton";
import SpaceService from "services/SpaceService";

export interface DeleteSpaceSectionProps {
  spacePseudo: string;
  spaceName: string;
}

/** Delete space section */
function DeleteSpaceSection(props: DeleteSpaceSectionProps): JSX.Element {
  const { t, i18n } = useTranslation();
  const [isOpenModalDelete, setOpenModalDelete] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const navigate = useNavigate();

  const handleCloseModalClick = () => {
    setOpenModalDelete(false);
  };

  const handleOpenDeleteModalClick = () => {
    setOpenModalDelete(true);
  };

  const handleConfirmDeleteButtonClick = async () => {
    setIsLoadingDelete(true);
    try {
      await SpaceService.deleteDeleteSpace({
        space_pseudo: props.spacePseudo,
      }).then(() => {
        notification.success({
          message: t(
            "CommonAddModifySpace.NotificationDeleteSpaceSuccessTitle"
          ),
          description: t(
            "CommonAddModifySpace.NotificationDeleteSpaceSuccessDescription"
          ),
          placement: "bottomRight",
        });
        handleCloseModalClick();
        navigate(
          routerPathLocal(i18n.language, RouterPath.MANAGER_MY_SPACES_ACTIVE)
        );
      });
    } catch (error) {
      notification.error({
        message: t("NotificationErrors.Error.Title"),
        description: t("NotificationErrors.Error.Description"),
        placement: "bottomRight",
      });
    } finally {
      setIsLoadingDelete(false);
    }
  };

  return (
    <>
      <div className="form-title">{t("CommonAddModifySpace.DeleteSpace")}</div>
      <CustomP>{t("CommonAddModifySpace.DeleteSpaceText")}</CustomP>
      <CustomButton
        type="grey"
        size="large"
        onClick={() => {
          handleOpenDeleteModalClick();
        }}
        style={{ marginBottom: "40px" }}
      >
        {t("CommonAddModifySpace.ButtonDeleteSpace")}
      </CustomButton>
      <Modal
        open={isOpenModalDelete}
        title={
          t("CommonAddModifySpace.ModalDeleteSpaceTitle") +
          " - " +
          props.spaceName
        }
        onCancel={handleOpenDeleteModalClick}
        footer={null}
        transitionName=""
        width={windowBreakpoints.SM}
      >
        <CustomP>{t("CommonAddModifySpace.ModalDeleteSpaceText")}</CustomP>

        <div
          style={{
            display: "flex",
            justifyContent: "end",
            gap: "20px",
          }}
        >
          <CustomButton
            type="default"
            size="large"
            onClick={handleCloseModalClick}
          >
            {t("Buttons.Close")}
          </CustomButton>
          <CustomButton
            type="danger"
            size="large"
            // htmlType="submit"
            onClick={handleConfirmDeleteButtonClick}
            disabled={isLoadingDelete}
          >
            {t("Buttons.Delete")}
          </CustomButton>
        </div>
      </Modal>
    </>
  );
}

export default observer(DeleteSpaceSection);
