import frFR from 'antd/locale/fr_FR'
import enEN from 'antd/locale/en_GB'

const localizeAntd = (language: string) => {
  switch (language) {
    case 'fr':
      return frFR;
    case 'en':
      return enEN;
    case 'en-US':
      return enEN;
    case 'fr-FR':
      return frFR;
    default:
      return enEN;
  }
};

export default localizeAntd