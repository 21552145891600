import React from "react";
import styled from "styled-components";

export const CustomH1 = styled.h1`
  font-family: "Ranade-Variable";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 113%;
  /* or 27px */

  letter-spacing: -0.04em;

  color: #000000;
`;

export const CustomH2 = styled.h2`
  font-family: "Ranade-Variable";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 113%;
  `