import ProfileLayout from "components/profileLayout/ProfileLayout";
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import React from "react";
import { useTranslation } from "react-i18next";
import menuAdmin from "../commonComponents/menuAdmin";

function BestSpacesAdmin(): JSX.Element {
  const { t, i18n } = useTranslation();

  return (
    <>
      <ProfileLayout menu={menuAdmin(i18n.language, t)}>
        <>sdddssdfsdsfdsdfsdfsfd </>
      </ProfileLayout>
    </>
  );
}

export default BestSpacesAdmin;
