import React from "react";
import { useTranslation } from "react-i18next";
import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";
import CustomResult from "components/customResult/CustomResult";
import CustomButton from "components/buttons/CustomButton";
import { RouterPath, routerPathLocal } from "constants/RouterPath";

/** Page "Password was changed" for Forgot password process. */
export default function PasswordChanged(): JSX.Element {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleGoToHome = () => {
    navigate(routerPathLocal(i18n.language, RouterPath.HOME));
  };

  return (
    <CustomResult
      status="success"
      title={t("ForgotPasswordPasswordChanged.Title")}
      subTitle={t("ForgotPasswordPasswordChanged.Text")}
      extra={[
        <CustomButton
          size="large"
          type="succes"
          key="back"
          onClick={handleGoToHome}
        >
          {t("ForgotPasswordPasswordChanged.Button")}
        </CustomButton>,
      ]}
    />
  );
}
