/** Enum for storing space types. Should be the same in backend.
 * Don't forget to update translations if you add a space type.
 */

export enum SpaceType {
    PHOTO_VIDEO_STUDIO = "PHOTO_VIDEO_STUDIO",
    CONFERENCE_VENUE = "CONFERENCE_VENUE",
    MEETING_SPACE = "MEETING_SPACE",
    WEEDDING_VENUE = "WEEDDING_VENUE",
    BAR = "BAR",
    RESTAURANT = "RESTAURANT",
    CHATEAU = "CHATEAU",
}
Object.freeze(SpaceType);

const enumKeys = Object.keys(SpaceType);

export const mappedArraySpaceType = enumKeys.map((key) => {
    const value = SpaceType[key as keyof typeof SpaceType];
    // Perform your desired operation with key and value
    return { key, value };
});

/* Return sorted by spacetype translation */
export const sortedArraySpaceType = (t: any) => mappedArraySpaceType.sort((a, b) =>
    t("SpaceTypes." + a.value).localeCompare(t("SpaceTypes." + b.value))
  );