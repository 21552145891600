import React from "react";
import { useTranslation } from "react-i18next";
import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";
import CustomResult from "components/customResult/CustomResult";
import CustomButton from "components/buttons/CustomButton";
import { RouterPath, routerPathLocal } from "constants/RouterPath";

/** Page displayed when the mail to reset password was sent. */
export default function MailSent() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleGoToHome = () => {
    navigate(routerPathLocal(i18n.language, RouterPath.HOME));
  };

  return (
    <CustomResult
      status="success"
      title={t("ForgotPasswordMailSent.Title")}
      subTitle={t("ForgotPasswordMailSent.Description")}
      extra={[
        <CustomButton
          size="large"
          type="succes"
          key="back"
          onClick={handleGoToHome}
        >
          {t("ForgotPasswordMailSent.Button")}
        </CustomButton>,
      ]}
    />
  );
}
