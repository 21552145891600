import React, { useState } from "react";
import { Button, Checkbox, Col, Form, Input, Modal, Radio, Row } from "antd";
import { useTranslation } from "react-i18next";
import ApiService from "services/AuthService";
import { enumUserRole } from "constants/User";
import { responseCodes } from "constants/responseCodes";
import { notification } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import CustomButton from "components/buttons/CustomButton";
import styled from "styled-components";
import { CustomH1 } from "components/typography/CustomH";

interface Props {
  userRole: string;
}

interface FormProps {
  first_name: string;
  last_name: string;
  email: string;
  tel: string;
  password: string;
  password2: string;
  role: string;
}

const FormSignup: React.FC<Props> = ({ userRole }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleOk = async (values: FormProps) => {
    setIsLoading(true);
    if (values.password !== values.password2) {
      await form.setFields([
        {
          name: "password2",
          errors: [t("ValidationErrors.NotSamePassword")],
        },
      ]);
      return;
    }
    try {
      await ApiService.postSignup({
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        tel: values.tel || "",
        password: values.password,
        role: userRole,
        lang: i18n.language,
      }).then(() => {
        navigate(routerPathLocal(i18n.language, RouterPath.SIGNUP_SUCCESS));
      });
    } catch (error) {
      // @ts-ignore: Property 'response' does not exist on type '{}'
      if (error?.response?.data?.detail === responseCodes.BR4001) {
        form.setFields([
          {
            name: "email",
            errors: [t("SignupPage.ErrorUserExistsAlready")],
          },
        ]);
      } else {
        notification.error({
          message: t("SignupPage.ErrorTitle"),
          description: t("SignupPage.ErrorMessage"),
          placement: "bottomRight",
        });
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <CustomH1
          style={{
            fontSize: "40px",
            width: "100%",
            // marginTop: "0",
            // marginBottom: "50px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {userRole === enumUserRole.MANAGER
            ? t("SignupPage.ManagerSignupTitle")
            : t("SignupPage.UserSignupTitle")}
        </CustomH1>
        <Col xs={24} sm={24} md={12} lg={8} xl={6}>
          <Form layout="vertical" form={form}>
            <Form.Item
              label={t("SignupPage.FirstName")}
              name="first_name"
              rules={[
                {
                  required: true,
                  type: "string",
                  min: 1,
                  validateTrigger: "onFinish",
                  message: t("ValidationErrors.Required"),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("SignupPage.LastName")}
              name="last_name"
              rules={[
                {
                  required: true,
                  type: "string",
                  min: 1,
                  validateTrigger: "onFinish",
                  message: t("ValidationErrors.Required"),
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t("SignupPage.Email")}
              name="email"
              rules={[
                {
                  required: true,
                  validateTrigger: "onFinish",
                  message: t("ValidationErrors.Required"),
                },
                {
                  type: "email",
                  validateTrigger: "onFinish",
                  message: t("SignupPage.ErrorValidEmail"),
                },
              ]}
            >
              <Input />
            </Form.Item>

            {userRole === enumUserRole.MANAGER ? (
              <Form.Item label={t("SignupPage.Tel")} name="tel" rules={[]}>
                <Input />
              </Form.Item>
            ) : (
              <></>
            )}

            <Form.Item
              label={t("SignupPage.Password")}
              name="password"
              rules={[
                {
                  required: true,
                  message: t("ValidationErrors.Required"),
                  type: "string",
                  validateTrigger: "onFinish",
                },
                {
                  message: t("ValidationErrors.PasswordFormat"),
                  type: "string",
                  min: 8,
                  validateTrigger: "onFinish",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label={t("SignupPage.Password2")}
              name="password2"
              rules={[
                {
                  required: true,
                  message: t("ValidationErrors.Required"),
                  type: "string",
                  validateTrigger: "onFinish",
                },
                {
                  message: t("ValidationErrors.PasswordFormat"),
                  type: "string",
                  min: 8,
                  validateTrigger: "onFinish",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              // label={t("SignupPage.IAcceptCGU")}
              name="is_accept_cgu"
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  message: t("ValidationErrors.Required"),
                },
              ]}
              labelCol={{ span: 24 }} // Set label column to full width
              wrapperCol={{ span: 24 }} // Set wrapper column to full width
            >
              <Checkbox
                onChange={(e) =>
                  form.setFieldsValue({ is_accept_cgu: e.target.checked })
                }
              />
              <span style={{ marginLeft: "5px" }}>
                {t("SignupPage.IAcceptCGU1")}
                <Link
                  to={routerPathLocal(
                    i18n.language,
                    RouterPath.TERMS_AND_CONDITIONS
                  )}
                >
                  {t("SignupPage.IAcceptCGU2")}
                </Link>
                {t("SignupPage.IAcceptCGU3")}
                <Link
                  to={routerPathLocal(i18n.language, RouterPath.PRIVACY_POLICY)}
                >
                  {t("SignupPage.IAcceptCGU4")}
                </Link>
              </span>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <CustomButton
                type="succes"
                htmlType="submit"
                size="large"
                onClick={() => {
                  form.validateFields().then((values) => handleOk(values));
                }}
                disabled={isLoading}
                style={{ width: "100%", whiteSpace: "pre-wrap" }}
              >
                {t("SignupPage.ButtonSignup")}
              </CustomButton>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default FormSignup;

const StyledTitle = styled.h1`
  color: #000;
  font-size: 24px;
  font-family: Ranade;
  font-style: normal;
  font-weight: 500;
  line-height: 113%;
  letter-spacing: -0.96px;
  padding-top: 10px;
  padding-bottom: 10px;
`;
