import { Col } from "antd";
import ListClicableSpaces from "components/listClicableSpaces/ListClicableSpaces";
import useStore from "hooks/useStore";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";

/**Page Favorites, accessible by all users. */
function Favorites(): JSX.Element {
  const { t, i18n } = useTranslation();
  const { spaceListStore } = useStore();

  // useEffect(() => {
  //   spaceStore.getAndSetCurrentSpace(spacePseudo!, i18n.language);
  // }, [i18n.language, spacePseudo, spaceStore]);

  useEffect(() => {
    spaceListStore.clearSpaceList();
    spaceListStore.setPage(1);
    spaceListStore.fetchSpaceList({
      lang: i18n.language,
      page: 1,
      size: 20,
      is_only_favorite: true,
    });

    return () => {
      spaceListStore.clearSpaceList();
    };
    // setSpaces(fetchSpaces());
  }, [i18n.language, spaceListStore]);

  return (
    <>
      <h1 style={{ margin: 0 }}>{t("Favorites.PageTitle")}</h1>
      <Col span={24}>
        {/* <div id="scrollableDiv"> */}
        <InfiniteScroll
          dataLength={spaceListStore.getSpaceListAsArray().length}
          next={() => {
            spaceListStore.setPage(spaceListStore.page + 1);
            spaceListStore.fetchSpaceList({
              lang: i18n.language,
              page: spaceListStore.page,
              size: 20,
              is_only_favorite: true,
            });
          }}
          style={{
            paddingTop: "25px",
            paddingBottom: "25px",
          }}
          hasMore={
            spaceListStore.getSpaceListAsArray().length < spaceListStore.total
          }
          loader={""}
          // scrollableTarget="scrollableDiv"
        >
          <ListClicableSpaces
            listSpaces={spaceListStore.getSpaceListAsArray()}
            itemsInRow={4}
          />
        </InfiniteScroll>
        {spaceListStore.getSpaceListAsArray().length === 0
          ? t("Favorites.MessageNoFavoritesYet")
          : ""}
        {/* </div> */}
      </Col>
    </>
  );
}

export default observer(Favorites);
