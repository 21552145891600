import {  Instance, types } from 'mobx-state-tree'


export const Inbox = types
  .model({
    id: types.number,
    name: types.string,
    date_creation: types.string,
    first_name: types.string,
    last_name: types.string,
    message: types.string,
    url_avatar: types.string,
    is_seen: types.boolean,
  })
  .views((self) => ({
    isActive: (id_active_inbox: number) => { 
      if (id_active_inbox === self.id) {
        return true;
      }
      return false;
     }
  }))
  .actions((self) => ({
    setIsSeen: (
      is_seen: boolean
    ) => {
      self.is_seen = is_seen;
    }
  }))

export type IInbox = Instance<typeof Inbox>
