import { IMenuItem } from "components/responsiveMenu/ResponsiveMenu";
import { RouterPath, routerPathLocal } from "constants/RouterPath";

const menuManager = (language: string, t: (key: string) => string): IMenuItem[] => {
  return [
    {
      routerPath: routerPathLocal(
        language,
        RouterPath.MANAGER_MY_SPACES_ACTIVE
      ),
      text: t("ManagerProfile.Menu.MySpaces"),
    },
    {
      routerPath: routerPathLocal(
        language,
        RouterPath.MANAGER_MY_PROFILE
      ),
      text: t("ManagerProfile.Menu.PersonalDetails"),
    },
    {
      routerPath: routerPathLocal(
        language,
        RouterPath.MANAGER_ACCOUNT_SETTINGS
      ),
      text: t("ManagerProfile.Menu.AccountSettings"),
    },
    {
      routerPath: routerPathLocal(
        language,
        RouterPath.MANAGER_NOTIFICATIONS
      ),
      text: t("ManagerProfile.Menu.Notifications"),
    },

  ];
};

export default menuManager;