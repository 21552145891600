import React from "react";
import { useTranslation } from "react-i18next";
import { CustomH1, CustomH2 } from "components/typography/CustomH";
import { CustomP } from "components/typography/CustomP";
import { Helmet } from "react-helmet";


export default function TermsAndConditions(): JSX.Element {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Helmet
        title={t("Terms.PageHeadTitle")}
        htmlAttributes={{ lang: i18n.language }}
        meta={[
          {
            name: `description`,
            content: t("Terms.PageHeadDescription"),
          },
        ]}
      />
      <div>
        <CustomH1>{t("Terms.Title")}</CustomH1>
        <CustomH2>{t("Terms.IntroductionTitle")}</CustomH2>
        <CustomP>{t("Terms.Introduction1")}</CustomP>
        <CustomH2>{t("Terms.DefinitionsTitle")}</CustomH2>
        <CustomP>{t("Terms.Definitions1")}</CustomP>
        <CustomP>{t("Terms.Definitions2")}</CustomP>
        <CustomP>{t("Terms.Definitions3")}</CustomP>
        <CustomP>{t("Terms.Definitions4")}</CustomP>
        <CustomP>{t("Terms.Definitions5")}</CustomP>
        <CustomH2>{t("Terms.ScopeTitle")}</CustomH2>
        <CustomP>{t("Terms.Scope1")}</CustomP>
        <CustomH2>{t("Terms.ContentRulesTitle")}</CustomH2>
        <CustomP>{t("Terms.ContentRules1")}</CustomP>
        <CustomP>{t("Terms.ContentRules2")}</CustomP>
        <CustomP>{t("Terms.ContentRules3")}</CustomP>
        <CustomP>{t("Terms.ContentRules4")}</CustomP>
        <CustomP>{t("Terms.ContentRules5")}</CustomP>
        <CustomH2>{t("Terms.CommunicationTitle")}</CustomH2>
        <CustomP>{t("Terms.Communication1")}</CustomP>
        <CustomP>{t("Terms.Communication2")}</CustomP>
        <CustomP>{t("Terms.Communication3")}</CustomP>
        <CustomP>{t("Terms.Communication4")}</CustomP>
        <CustomH2>{t("Terms.WarrantyTitle")}</CustomH2>
        <CustomP>{t("Terms.Warranty1")}</CustomP>
        <CustomP>{t("Terms.Warranty2")}</CustomP>
        <CustomP>{t("Terms.Warranty3")}</CustomP>
        <CustomH2>{t("Terms.LiabilityTitle")}</CustomH2>
        <CustomP>{t("Terms.Liability1")}</CustomP>
        <CustomP>{t("Terms.Liability2")}</CustomP>
        <CustomP>{t("Terms.Liability3")}</CustomP>
        <CustomP>{t("Terms.Liability4")}</CustomP>
        <CustomH2>{t("Terms.TerminationTitle")}</CustomH2>
        <CustomP>{t("Terms.Termination1")}</CustomP>
        <CustomP>{t("Terms.Termination2")}</CustomP>
        <CustomH2>{t("Terms.ChangeTitle")}</CustomH2>
        <CustomP>{t("Terms.Change1")}</CustomP>
        <CustomH2>{t("Terms.DisputeResolutionTitle")}</CustomH2>
        <CustomP>{t("Terms.DisputeResolution1")}</CustomP>
        <CustomH2>{t("Terms.IntellectualPropertyTitle")}</CustomH2>
        <CustomP>{t("Terms.IntellectualProperty1")}</CustomP>
        <CustomH2>{t("Terms.PrivacyTitle")}</CustomH2>
        <CustomP>{t("Terms.Privacy1")}</CustomP>
        <CustomH2>{t("Terms.SeverabilityTitle")}</CustomH2>
        <CustomP>{t("Terms.Severability1")}</CustomP>
        <CustomH2>{t("Terms.WaiverTitle")}</CustomH2>
        <CustomP>{t("Terms.Waiver1")}</CustomP>
        <CustomH2>{t("Terms.ContactUsTitle")}</CustomH2>
        <CustomP>{t("Terms.ContactUs1")}</CustomP>
        <CustomH2>{t("Terms.LastUpdatedTitle")}</CustomH2>
        <CustomP>{t("Terms.LastUpdated1")}</CustomP>
      </div>
    </>
  );
}
