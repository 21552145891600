
import CustomButton from "components/buttons/CustomButton";
import CustomResult from "components/customResult/CustomResult";
import ModalLogin from "components/modals/modalLogin/ModalLogin";
import { RouterPath } from "constants/RouterPath";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

/** Page "Not authenticated" to display when user is not authenticated. */
export default function NotAuthenticated(): JSX.Element {
  const { t, i18n } = useTranslation();
  const [isOpenModalLogin, setOpenModalLogin] = useState(false);

  const handleOpenModalLogin = () => {
    setOpenModalLogin(true);
  };

  return (
    <>
      <CustomResult
        status="error"
        title={t("NotAuthenticated.Title")}
        subTitle={t("NotAuthenticated.Text")}
        extra={[
          <CustomButton
            size="large"
            type="succes"
            key="back"
            onClick={handleOpenModalLogin}
          >
            {t("NotAuthenticated.Button")}
          </CustomButton>,
        ]}
      />
      <ModalLogin
        isOpen={isOpenModalLogin}
        closeModal={() => setOpenModalLogin(false)}
        redirectSuccessRouterPath={RouterPath.HOME}
      />
    </>
  );
}
