import { ApiEndpointsV1 } from "assets/endpoints/v1";
import { axiosInstance } from "./axios/Axios";
import { AxiosResponse } from "axios";



export interface ICountriesAPIResponse{
  id: number;
  name: string;
}

/**
 * API to get list of all available countries.
 */
const getCountries = () => {
  return axiosInstance
    .get(ApiEndpointsV1.GET_COUNTRIES_LIST)
    .then((response) => {
      return response;
    });
};


export interface IGetRegionsParams {
  lang?: string;
  id_country?: number;
}


export interface IRegionsAPIResponse{
  id: number;
  name: string;
}


/**
 * API to get list of regions in selected lang for selected region.
 */
const getRegions = (queryParams?: IGetRegionsParams): Promise<AxiosResponse<IRegionsAPIResponse[]>> => {
  return axiosInstance
    .get(ApiEndpointsV1.GET_REGIONS_LIST, {
      params: queryParams,
    })
    .then((response) => {
      return response;
    });
};


export interface IGetCityRegionGeoParams {
  lang?: string;
  id_country?: number;
}


export interface IRegionCityGeoAPIResponse{
  uuid: string;
  name: string;
  geo_lat: number;
  geo_long: number;
}


/**
 * API to get list of region and cities in selected lang for selected region with lat and long center.
 */
const getRegionCityGeo = (queryParams?: IGetCityRegionGeoParams): Promise<AxiosResponse<IRegionCityGeoAPIResponse[]>> => {
  console.log(ApiEndpointsV1.GET_REGION_CITY_GEO_LIST)
  return axiosInstance
    .get(ApiEndpointsV1.GET_REGION_CITY_GEO_LIST, {
      params: queryParams,
    })
    .then((response) => {
      return response;
    });
};


export interface IGetEventsParams {
  lang?: string;
  space_type?: number;
}


export interface IEventsAPIResponse{
  id: number;
  name: string;
}


/**
 * API to get list of events.
 */
const getEvents = (queryParams?: IGetEventsParams): Promise<AxiosResponse<IEventsAPIResponse[]>> => {
  return axiosInstance
    .get(ApiEndpointsV1.GET_EVENTS_LIST, {
      params: queryParams,
    })
    .then((response) => {
      return response;
    });
};


export interface IGetFacilitiesParams {
  lang?: string;
}


export interface IFacilitiesAPIResponse{
  id: number;
  type: string;
  name: string;
  icon: string;
}


/**
 * API to get list of facilities.
 */
const getFacilities = (queryParams?: IGetFacilitiesParams): Promise<AxiosResponse<IFacilitiesAPIResponse[]>> => {
  return axiosInstance
    .get(ApiEndpointsV1.GET_FACILITIES_LIST, {
      params: queryParams,
    })
    .then((response) => {
      return response;
    });
};



const DataService = {
  getCountries,
  getEvents,
  getFacilities,
  getRegions,
  getRegionCityGeo,
};

export default DataService;