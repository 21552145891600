import React from "react";
import { Breadcrumb, Layout, Menu, theme } from "antd";
import { Outlet } from "react-router-dom";
import CustomHeader from "components/header/CustomHeader";

const { Header, Content, Footer } = Layout;

function SearchLayout(): JSX.Element {
  return (
  <Layout style={{ minHeight: '100vh'}}>
        <CustomHeader />
        <Content
          style={{
            flex: 'auto',
            display: "flex",
            flexDirection: 'column'
          }}
        >
          <Outlet />
        </Content>
 
    </Layout>
  );
}

export default SearchLayout;
