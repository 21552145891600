import React from "react";
import { Breadcrumb, Layout, Menu, theme } from "antd";
import { Outlet } from "react-router-dom";
import CustomHeader from "components/header/CustomHeader";
import styled from "styled-components";
import CustomFooter from "components/footer/CustomFooter";

const { Header, Content, Footer } = Layout;

function MainLayout(): JSX.Element {
  // const {
  //   token: { colorBgContainer },
  // } = theme.useToken();

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <CustomHeader />
      <StyledContentAndFooter>
        <div
          style={{
            // maxWidth: "1400px",
            height: "100%",
            width: "100%",
            display: "flex",
            flex: "auto",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <StyledContent>
            <Outlet />
          </StyledContent>
          <CustomFooter />
        </div>
      </StyledContentAndFooter>
    </Layout>
  );
}

export default MainLayout;

const StyledContent = styled.div`
  max-width: 1400px;
  flex: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledContentAndFooter = styled.div`
  flex: auto;
  display: flex;
  flex-direction: column;
  padding: 20px 70px 0px; /* Default padding */
  align-items: center;
  width: 100%;
  /* max-width: 1300px; */
  // min-height: calc(100vh - 63px - 67px);

  @media (max-width: 992px) {
    padding: 20px 30px 0px;
    // min-height: calc(100vh - 47px - 67px);
  }

  @media (max-width: 768px) {
    padding: 20px 12px 0px;
    // min-height: calc(100vh - 47px - 67px);
  }
`;
