import React, { useState } from "react";
import { Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import { useNavigate } from "react-router-dom";
import { CustomH1, CustomH2 } from "components/typography/CustomH";
import { CustomP } from "components/typography/CustomP";
import { Helmet } from "react-helmet";

export default function PrivacyPolicy(): JSX.Element {
  const { t, i18n } = useTranslation();
  const [fileList, setFileList] = useState([]);
  const navigate = useNavigate();

  return (
    <>
      <Helmet
        title={t("PrivacyPolicyPage.PageHeadTitle")}
        htmlAttributes={{ lang: i18n.language }}
        meta={[
          {
            name: `description`,
            content: t("PrivacyPolicyPage.PageHeadDescription"),
          },
        ]}
      />
      <div>
        <CustomH1>{t("PrivacyPolicyPage.Title")}</CustomH1>
        <CustomH2>{t("PrivacyPolicyPage.FirstSubtitle")}</CustomH2>
        <CustomP>{t("PrivacyPolicyPage.FirstParagraph1")}</CustomP>
        <CustomP>{t("PrivacyPolicyPage.FirstParagraph2")}</CustomP>
        <CustomP>{t("PrivacyPolicyPage.FirstParagraph3")}</CustomP>
        <CustomH2>{t("PrivacyPolicyPage.SecondSubtitle")}</CustomH2>
        <CustomP>{t("PrivacyPolicyPage.SecondParagraph1")}</CustomP>
        <CustomP>{t("PrivacyPolicyPage.SecondParagraph2")}</CustomP>
        <CustomP>{t("PrivacyPolicyPage.SecondParagraph3")}</CustomP>
        <CustomP>{t("PrivacyPolicyPage.SecondParagraph4")}</CustomP>
        <CustomP>{t("PrivacyPolicyPage.SecondParagraph5")}</CustomP>
      </div>
    </>
  );
}
