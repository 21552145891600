import React from "react";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { mapOptionsGet } from "constants/googleMap";
import { observer } from "mobx-react-lite";
import useStore from "hooks/useStore";
import AppConfig from "appConfig";

const SpaceMap = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: AppConfig.secrets.GOOGLE_API_KEY,
  });

  const {
    spaceStore: { currentSpace },
  } = useStore();

  const mapStyles = {
    height: "440px",
    width: "100%",
  };

  if (!currentSpace || !isLoaded) {
    return null;
  }

  const defaultCenter = {
    lat: currentSpace.geo_lat,
    lng: currentSpace.geo_long,
  };

  return (
    <GoogleMap
      mapContainerStyle={mapStyles}
      zoom={13}
      center={defaultCenter}
      // options={mapOptions}
      options={mapOptionsGet(true, false)}
    >
      <MarkerF visible position={defaultCenter} />
    </GoogleMap>
  );
};

export default observer(SpaceMap);
