import { AxiosResponse } from "axios";
import { ApiEndpointsV1 } from "assets/endpoints/v1";
import { enumUserRole } from 'constants/User'
import { axiosInstance } from 'services/axios/Axios';

export interface ILoginData {
  login: string;
  password: string;
}

export interface ILoginResponse {
  access_token: string;
  first_name: string;
  last_name: string;
  email: string;
  tel: string;
  role: enumUserRole;
  url_avatar: string;
}

/**
 * API to log in and to get token.
 */
function postLogin(data: ILoginData): Promise<AxiosResponse<ILoginResponse>> {
  return axiosInstance
    .post(ApiEndpointsV1.LOGIN, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });;
};


export interface ISignupData {
  first_name: string;
  last_name: string;
  email: string;
  tel: string;
  password: string;
  role: string;
  lang: string;
}

export interface ISignupResponse {
  email: string;
  first_name: string;
  last_name: string;
  uuid: string;
  tel: string;
}

/**
 * API to create an account.
 */
function postSignup(data: ISignupData): Promise<AxiosResponse<ISignupResponse>> {
  return axiosInstance
    .post(ApiEndpointsV1.SIGNUP, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });;
};


export interface IUserMyInfoResponse {
  email: string;
  first_name: string;
  last_name: string;
  uuid: string;
  tel: string;
  url_avatar: string;
}

/**
 * API to get user informations.
 */
function getUserMyInfo(): Promise<AxiosResponse<IUserMyInfoResponse>> {
  return axiosInstance
    .get(ApiEndpointsV1.USER_MY_INFO)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });;
};


/**
 * API to check the user creation token to confirm the mail of user.
 * Used during the signup process.
 */
const postConfirmEmail = (token: string) => {
  return axiosInstance
    .post(ApiEndpointsV1.CONFIRM_EMAIL + token)
    .then((response) => {
      return response;
    });
};


/**
 * API to reset user password, to send an email with link to set a new password.
 * Used during the forgot email process.
 */
const postResetPassword = (email: string, lang: string) => {
  return axiosInstance
    .post(ApiEndpointsV1.PASSWORD_RECOVERY, { email: email, lang: lang})
    .then((response) => {
      return response;
    });
};



export interface ISetNewPasswordForgotPassword {
  new_password: string;
  token: string;
}


/**
 * API to set a new password for user using token,
 * obtained from the link sent to his email.
 */
const postSetNewPasswordForgotPassword = (data: ISetNewPasswordForgotPassword) => {
  return axiosInstance
    .post(ApiEndpointsV1.RESET_PASSWORD, data)
    .then((response) => {
      return response;
    });
};


const DataService = {
  postLogin,
  postSignup,
  getUserMyInfo,
  postConfirmEmail,
  postResetPassword,
  postSetNewPasswordForgotPassword,
};

export default DataService;

