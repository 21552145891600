import { ApiEndpointsV1 } from "assets/endpoints/v1";
import { axiosInstance } from "./axios/Axios";
import { AxiosResponse } from "axios";



export interface IUpdateUserInformationAPI{
  first_name: string;
  last_name: string;
  tel: string;
}

/**
 * API to update User information.
 */
const postUpdateUserInfo = (data: IUpdateUserInformationAPI) => {
  return axiosInstance
    .post(ApiEndpointsV1.UPDATE_USER_INFO, data)
    .then((response) => {
      return response;
    });
};


export interface IUpdateUserPasswordAPI{
  old_password: string;
  new_password: string;
}

/**
 * API to update User password.
 */
const postUpdateUserPassword = (data: IUpdateUserPasswordAPI) => {
  return axiosInstance
    .post(ApiEndpointsV1.UPDATE_USER_PASSWORD, data)
    .then((response) => {
      return response;
    });
};



/**
 * API to update User avatar.
 */
const postUpdateUserAvatar = (formData: FormData) => {
    return axiosInstance.post(
        ApiEndpointsV1.UPDATE_USER_AVATAR,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ).then((response) => {
      return response;
    });
};


/**
 * API to delete User avatar.
 */
const deleteUserAvatar = () => {
    return axiosInstance.delete(
        ApiEndpointsV1.DELETE_USER_AVATAR,
      ).then((response) => {
      return response;
    });
};


export interface IDeleteSelfUserAPI{
  password: string;
}

/**
 * API to delete self-user.
 */
const deleteSelfUser = (data: IDeleteSelfUserAPI) => {
  console.log(data)
  return axiosInstance
    .post(ApiEndpointsV1.DELETE_SELF_USER, data)
    .then((response) => {
      return response;
    });
};



export interface IUserMyNotificationInfoResponse {
  language: string;
  is_get_newsletter: boolean;
}

/**
 * API to get user informations.
 */
function getUserNotificationInfo(): Promise<AxiosResponse<IUserMyNotificationInfoResponse>> {
  return axiosInstance
    .get(ApiEndpointsV1.GET_USER_NOTIFICATION_INFO)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });;
};



export interface IUpdateUserNotificationInfoAPI{
  language: string;
  is_get_newsletter: boolean;
}

/**
 * API to update User Notification information.
 */
const putUpdateUserNotificationInfo = (data: IUpdateUserNotificationInfoAPI) => {
  return axiosInstance
    .put(ApiEndpointsV1.UPDATE_USER_NOTIFICATION_INFO, data)
    .then((response) => {
      return response;
    });
};


export interface IGetUserListParams {
  page?: number;
  size?: number;
}

export interface IGetUserListItemResponse {
  uuid: string;
  full_name: string;
  email: string;
  is_mail_confirmed: boolean;
  role: string;
}

export interface IGetUserListResponse{
  items: IGetUserListItemResponse[];
  total: number;
  page: number;
  size: number;
}


/**
 * API to get list of users for admin dashboard.
 * @param {Object} queryParams - The query parameters for the API request.
 */
const getUserListAsAdmin = (queryParams?: IGetUserListParams): Promise<AxiosResponse<IGetUserListResponse>> => {
  return axiosInstance
    .get(ApiEndpointsV1.GET_USER_LIST_AS_ADMIN, {
      params: queryParams,
    })
    .then((response) => {
      return response;
    });
};


export interface IUserInfoAsAdminParams {
  uuid_user: string;
}

export interface IUserInfoAsAdminResponse{
  uuid: string;
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  role: string;
  tel: string;
  url_avatar: string;
  is_mail_confirmed: boolean;
  date_creation: string;
  date_last_login: string | null;
  date_pwd_reinit: string | null;
  number_of_spaces: number;
}


/**
 * API to get list of users for admin dashboard.
 * @param {Object} queryParams - The query parameters for the API request.
 */
const getUserInfoAsAdmin = (queryParams?: IUserInfoAsAdminParams): Promise<AxiosResponse<IUserInfoAsAdminResponse>> => {
  return axiosInstance
    .get(ApiEndpointsV1.GET_USER_INFO_AS_ADMIN, {
      params: queryParams,
    })
    .then((response) => {
      return response;
    });
};


const DataService = {
  postUpdateUserInfo,
  postUpdateUserPassword,
  postUpdateUserAvatar,
  deleteUserAvatar,
  deleteSelfUser,
  getUserNotificationInfo,
  putUpdateUserNotificationInfo,
  getUserListAsAdmin,
  getUserInfoAsAdmin,
};

export default DataService;