import React, { useState } from "react";
import { Upload, Button, message, Row, Col } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { RouterPath, routerPathLocal } from "constants/RouterPath";
import { useNavigate } from "react-router-dom";
import CustomButton from "components/buttons/CustomButton";
import styled from "styled-components";
import getMoreImage from "assets/images/homePage/moreImage.png";

export default function GetMoreSection(): JSX.Element {
  const { t, i18n } = useTranslation();
  const [fileList, setFileList] = useState([]);
  const navigate = useNavigate();

  return (
    <>
      <Row style={{ position: "relative" }}>
        <StyledCol style={{ zIndex: 2 }}>
          <StyledH2 style={{ marginBottom: "5px" }}>
            {t("MainPage.GetTitle")}
          </StyledH2>
          <StyledImage src={getMoreImage} alt="" />
          <StyledH2 style={{ marginBottom: "35px" }}>
            {t("MainPage.BookingsTitle")}
          </StyledH2>
          <div style={{ marginBottom: "37px" }}>
            {t("MainPage.GetMoreDescription")}
          </div>
          <CustomButton
            size="large"
            type="succes"
            onClick={() =>
              navigate(routerPathLocal(i18n.language, RouterPath.PARTNERSHIP))
            }
          >
            {t("MainPage.GetMoreButton")}
          </CustomButton>
        </StyledCol>
        <StyledEllipse />
      </Row>
    </>
  );
}

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
`;

const StyledH2 = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 113%;
  /* or 45px */

  letter-spacing: -0.04em;

  color: #000000;
  margin: 0;
`;

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const StyledEllipse = styled.div`
  position: absolute;
  left: 50%; // Move the element halfway across the container
  transform: translateX(
    -50%
  ); // This will shift the element back by half of its own width

  top: 200px;

  width: 350px;
  height: 350px;
  background: #007aae;
  opacity: 0.2;
  filter: blur(100px);
  border-radius: 50%; // To make the div elliptical
  z-index: 1;
`;
