/**Format date to text. Used in the messenger. */
export function formatDate(dateString: string, language: string): string {
  const date = new Date(dateString);
  const monthNamesEn = ["January", "February", "March", "April", "May", "June",
                      "July", "August", "September", "October", "November", "December"];
  const monthNamesFr = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
                      "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
  const monthNames = language === "fr" ? monthNamesFr : monthNamesEn;
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  const formattedDate = language === "fr" ? `${day} ${month} ${year}` : `${month} ${day}, ${year}`;
  return formattedDate;
}

/**Truncate a string to a specified length.*/
export function truncateString(str: string, num: number) {
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}


interface MessageRequestData {
  date: string;
  numberParticipants: number;
  message: string;
  spaceName: string;
}

/**Format data from request form to message to send to user manager of space. */
export function makeMessageFromRequestData(
  data: MessageRequestData) {
  
  return `Space: ${data.spaceName}
Date: ${data.date}
Number of participants: ${data.numberParticipants}

${data.message}`
}

