import { aliasToken } from "assets/theme/token";



/** Antd theme, more information on the page https://ant.design/docs/react/customize-theme#customize-component-token */
export const theme = {
  token: aliasToken,
  components: {
    Button: {
    },
    Input: {
      controlHeight: 38,
    },
    Select: {
      controlHeight: 38,
    },
    DatePicker: {
      controlHeight: 38,
    },
    Modal: {
    }
      },
};