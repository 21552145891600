import { Instance, types } from 'mobx-state-tree'

export const Facility = types
  .model({
    id: types.string,
    type: types.string,
    name: types.string,
    icon: types.string,
  })


export type IFacility = Instance<typeof Facility>